<template>
  <div v-if="!disabled" class="knowledge-vault-button-wrapper" @click="onClickButton">
    <div class="title-wrapper">
      <p>{{ $t('misc.knowledgeVault') }}</p>
    </div>
    <div class="image-wrapper"></div>
    <div class="hinge top"/>
    <div class="hinge bottom"/>
  </div>
</template>
<script>

export default {
  name: 'KnowledgeVaultButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
$colorTitle: #474747;
$colorText: #757575;

  .knowledge-vault-button-wrapper {
    width: 176px;
    height: 110px;
    cursor: pointer;
    background-color: $colorText;
    border-radius: 12px;
    box-shadow: 0 6px 0 0 #292a2e, 4px 10px 10px 0px rgb(0, 0, 22, 70%);

    &:hover {
      background-color: lighten($colorText, 4%);
      .title-wrapper {
        background-color: lighten($colorTitle, 4%);
      }
    }

    &:active {
      background-color: darken($colorText, 7%);
      .title-wrapper {
        background-color: darken($colorTitle, 7%);
        color: #E0E9EB;
      }
    }

    .title-wrapper {
      width: 100%;
      background-color: $colorTitle;
      padding: 5px;
      text-align: center;
      border-radius: 12px 12px 0 0;
      font-weight: 700;
      p {
        margin: 0;
      }
    }

    .image-wrapper {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: 60px 60px;
      background-position: center 5px;
      background-image: url("./../../assets/img/ui/icon_knowldgevault_alt.png");
    }

    .hinge {
      background-image: url("./../../assets/img/ui/hinge.png");
      position: absolute;
      background-repeat: no-repeat;
      height: 12px;
      width: 19px;
      left: -10px;

      &.top {
        bottom: 49px;
      }

      &.bottom {
        bottom: 7px;
      }
    }
  }

</style>
