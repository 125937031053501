<template>
  <div class="document-newsFeed">
    <div class="news-item-wrapper d-flex" v-for="(newsItem, i) in newsfeedData" :key="i">
      <NoDragImage class="news-item-image" :src="getImageSrc(newsItem.image)"/>
      <div class="news-item-text">
        <p class="news-item-text-title">{{ $t(newsItem.title) }}</p>
        <p class="news-item-text-newspaper">{{ $t(newsItem.newspaper) }}</p>
      </div>
    </div>
  </div>

</template>

<script>

import { CONVERSATIONS } from '@/enum/Conversations';
import NoDragImage from '@/components/common/NoDragImage';

export default {
  name: 'DocumentNewsfeed',
  components: { NoDragImage },
  data() {
    return {
      newsfeedData: [],
    };
  },
  mounted() {
    this.newsfeedData = CONVERSATIONS.newsfeedData;
  },
  methods: {
    getImageSrc(pictureId) {
      const images = require.context('../../../assets/img/newsImages');
      const imgSrc = images('./' + pictureId + '.jpg');
      return imgSrc;
    },
  },
};
</script>
<style lang="scss" scoped>
.document-newsFeed {
  width: 30vw;
  min-width: 600px;

  .news-item-wrapper {
    background-color: #F0F0F0;
    margin: 7px 0;

    .news-item-image {
      width: 22%;
      object-fit: cover;
    }

    .news-item-text {
      position: relative;
      margin-left: 10px;
      padding: 10px 10px 25px 0;

      .news-item-text-title {
        font-size: 22px;
        font-weight: 500;
      }

      .news-item-text-newspaper {
        position: absolute;
        bottom: 3px;
        margin-bottom: 0;
        color: #585858;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

</style>
