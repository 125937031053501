import {
  createDilemmaStakeholderProgressionStatusArray,
  createDilemmaUnlockedStatusObject
} from '@/utils/dataUtil';
import {
  CHEAT_FINISH_ALL_DILEMMAS,
  CHEAT_FINISH_INFORMAL_PD,
  CHEAT_SKIP_INTRO, CHEAT_SKIP_TO_BOSS_FIGHT,
  CHEAT_SKIP_TO_INFORMAL_PD,
  CHEAT_SKIP_TUTORIAL,
  ENABLE_CHEATS,
} from '../mutation-types';
import { createTutorialAssistantMessagesShownObject } from '../../utils/dataUtil';

export default {

  [ENABLE_CHEATS]: (state) => {
    state.cheatsWindowActivated = true;
  },
  [CHEAT_SKIP_INTRO]: (state) => {
    state.currentDilemmaId = 'INTRO2';
    state.currentStakeholderId = null;
    state.currentIntroPageIndex = 15;
    state.currentIntroUnlockPageIndex = 14;
    state.playerName = 'John';
    state.playerSurname = 'Doe';
    state.playerPosition = 'userPosition.other';
    state.playerLocation = 'userOrigin.america';
    state.currentInformalPDPageIndex = 0;
    state.tutorialStepIndex = 0;
    state.vca4dDocumentsOrdered = false;
    state.tutorialCompleted = false;
    state.tutorialAssistantMessagesShown = createTutorialAssistantMessagesShownObject();
    state.scenario1Seen = false;
    state.scenario2Seen = false;
    state.scenario3Seen = false;
    localStorage.setItem('currentDilemmaId', state.currentDilemmaId);
    localStorage.setItem('currentStakeholderId', null);
    localStorage.setItem('currentIntroPageIndex', state.currentIntroPageIndex);
    localStorage.setItem('currentIntroUnlockPageIndex', state.currentIntroUnlockPageIndex);
    localStorage.setItem('playerName', state.playerName);
    localStorage.setItem('playerSurname', state.playerSurname);
    localStorage.setItem('playerPosition', state.playerPosition);
    localStorage.setItem('playerLocation', state.playerLocation);
    localStorage.setItem('tutorialStepIndex', '0');
    localStorage.setItem('currentInformalPDPageIndex', '0');
    localStorage.setItem('vca4dDocumentsOrdered', JSON.stringify(state.vca4dDocumentsOrdered));
    localStorage.setItem('tutorialCompleted', JSON.stringify(state.tutorialCompleted));
    localStorage.setItem('tutorialAssistantMessagesShown', JSON.stringify(state.tutorialAssistantMessagesShown));

    localStorage.setItem('scenario1Seen', state.scenario1Seen);
    localStorage.setItem('scenario2Seen', state.scenario2Seen);
    localStorage.setItem('scenario3Seen', state.scenario3Seen);

    const dilemmaUnlockedStatus = createDilemmaUnlockedStatusObject();
    localStorage.setItem('dilemmaUnlockedStatus', JSON.stringify(dilemmaUnlockedStatus));
    state.dilemmaUnlockedStatus = dilemmaUnlockedStatus;

    const dilemmaStakeholderProgressionStatus = createDilemmaStakeholderProgressionStatusArray();
    localStorage.setItem('dilemmaStakeholderProgressionStatus', JSON.stringify(dilemmaStakeholderProgressionStatus));
    state.dilemmaStakeholderProgressionStatus = dilemmaStakeholderProgressionStatus;

    // const stakeholderOnboardStatus = createStakeholderOnboardStatusObject();
    state.stakeholderOnboardStatus = { 5: 1 };
    localStorage.setItem('stakeholderOnboardStatus', JSON.stringify(state.stakeholderOnboardStatus));
  },
  [CHEAT_SKIP_TUTORIAL]: (state) => {
    // console.log('Skipping to turorial');
    state.currentDilemmaId = 'INTRO2';
    state.currentStakeholderId = null;
    state.currentIntroPageIndex = 17;
    state.currentIntroUnlockPageIndex = 14;
    state.playerName = 'John';
    state.playerSurname = 'Doe';
    state.playerPosition = 'userPosition.other';
    state.playerLocation = 'userOrigin.america';
    state.currentInformalPDPageIndex = 0;
    state.tutorialStepIndex = 2;
    state.vca4dDocumentsOrdered = true;
    state.tutorialAssistantMessagesShown = createTutorialAssistantMessagesShownObject(['onBoardingExplanation', 'dilemmaViewExplanation', 'assistantIntroduction']);
    localStorage.setItem('currentDilemmaId', state.currentDilemmaId);
    localStorage.setItem('currentStakeholderId', null);
    localStorage.setItem('currentIntroPageIndex', state.currentIntroPageIndex);
    localStorage.setItem('currentIntroUnlockPageIndex', state.currentIntroUnlockPageIndex);
    localStorage.setItem('playerName', state.playerName);
    localStorage.setItem('currentInformalPDPageIndex', '0');
    localStorage.setItem('playerSurname', state.playerSurname);
    localStorage.setItem('playerPosition', state.playerPosition);
    localStorage.setItem('playerLocation', state.playerLocation);
    localStorage.setItem('tutorialStepIndex', '2');
    localStorage.setItem('vca4dDocumentsOrdered', JSON.stringify(state.vca4dDocumentsOrdered));
    localStorage.setItem('tutorialAssistantMessagesShown', JSON.stringify(state.tutorialAssistantMessagesShown));

    const dilemmaUnlockedStatus = createDilemmaUnlockedStatusObject();
    localStorage.setItem('dilemmaUnlockedStatus', JSON.stringify(dilemmaUnlockedStatus));
    state.dilemmaUnlockedStatus = dilemmaUnlockedStatus;

    const dilemmaStakeholderProgressionStatus = createDilemmaStakeholderProgressionStatusArray();
    localStorage.setItem('dilemmaStakeholderProgressionStatus', JSON.stringify(dilemmaStakeholderProgressionStatus));
    state.dilemmaStakeholderProgressionStatus = dilemmaStakeholderProgressionStatus;

    // const stakeholderOnboardStatus = createStakeholderOnboardStatusObject();
    localStorage.setItem('stakeholderOnboardStatus', JSON.stringify({ 5: 1, 2: 2 }));
    state.stakeholderOnboardStatus = { 5: 1, 2: 2 };
  },
  [CHEAT_SKIP_TO_INFORMAL_PD]: (state) => {
    state.dilemmaUnlockedStatus = {
      0: { status: 3, policies: {} }, 1: { status: 1, policies: { 1: 5, 2: 6, 3: 6 } }, 2: { status: 1, policies: { 1: 6, 2: 6, 3: 5 } }, 3: { status: 3, policies: { 1: 4, 2: 4, 3: 4 } }, 4: { status: 3, policies: { 1: 4, 2: 4, 3: 4 } },
    };
    state.currentDilemmaId = '2';
    state.stakeholderOnboardStatus = {
      2: 2, 5: 1, 8: 2, 9: 2, 10: 2,
    };

    state.currentInformalPDPageIndex = 0;
    state.idpOpportunityAccepted = false;
    state.hasPlayedInformalPD = true;
    localStorage.setItem('currentInformalPDPageIndex', JSON.stringify(state.currentInformalPDPageIndex));
    localStorage.setItem('idpOpportunityAccepted', JSON.stringify(state.hasPlayedInformalPD));
    localStorage.setItem('dilemmaUnlockedStatus', JSON.stringify(state.dilemmaUnlockedStatus));
    localStorage.setItem('currentDilemmaId', JSON.stringify(state.currentDilemmaId));
    localStorage.setItem('stakeholderOnboardStatus', JSON.stringify(state.stakeholderOnboardStatus));

  },
  [CHEAT_FINISH_INFORMAL_PD]: (state) => {

    state.idpOpportunityAccepted = true;
    localStorage.setItem('idpOpportunityAccepted', JSON.stringify(state.idpOpportunityAccepted));

    state.hasPlayedInformalPD = true;
    localStorage.setItem('idpOpportunityAccepted', JSON.stringify(state.hasPlayedInformalPD));

    state.dilemmaUnlockedStatus = {
      0: { status: 3, policies: {} }, 1: { status: 1, policies: { 1: 5, 2: 6, 3: 6 } }, 2: { status: 1, policies: { 1: 6, 2: 6, 3: 5 } }, 3: { status: 2, policies: { 1: 4, 2: 4, 3: 4 } }, 4: { status: 3, policies: { 1: 4, 2: 4, 3: 4 } },
    };
    const argumentIndexesPerPolicyIndex = {
      0: [],
      1: [],
      2: [],
      3: [],
    };
    localStorage.setItem('argumentIndexesPerPolicyIndex', JSON.stringify(argumentIndexesPerPolicyIndex));
    state.argumentIndexesPerPolicyIndex = argumentIndexesPerPolicyIndex;

    localStorage.setItem('dilemmaUnlockedStatus', JSON.stringify(state.dilemmaUnlockedStatus));
  },
  [CHEAT_FINISH_ALL_DILEMMAS]: (state) => {
    state.dilemmaUnlockedStatus = {
      0: { status: 3, policies: {} }, 1: { status: 1, policies: { 1: 5, 2: 6, 3: 6 } }, 2: { status: 1, policies: { 1: 6, 2: 6, 3: 5 } }, 3: { status: 1, policies: { 1: 6, 2: 6, 3: 5 } }, 4: { status: 1, policies: { 1: 6, 2: 6, 3: 5 } },
    };
    localStorage.setItem('dilemmaUnlockedStatus', JSON.stringify(state.dilemmaUnlockedStatus));

    const argumentIndexesPerPolicyIndex = {
      0: [],
      1: [],
      2: [],
      3: [],
    };
    localStorage.setItem('argumentIndexesPerPolicyIndex', JSON.stringify(argumentIndexesPerPolicyIndex));
    state.argumentIndexesPerPolicyIndex = argumentIndexesPerPolicyIndex;

    state.idpOpportunityAccepted = false;
    localStorage.setItem('idpOpportunityAccepted', JSON.stringify(state.idpOpportunityAccepted));
  },
  [CHEAT_SKIP_TO_BOSS_FIGHT]: (state) => {
    state.dilemmaUnlockedStatus = {
      0: { status: 3, policies: {} }, 1: { status: 1, policies: { 1: 5, 2: 6, 3: 6 } }, 2: { status: 1, policies: { 1: 6, 2: 6, 3: 5 } }, 3: { status: 1, policies: { 1: 6, 2: 6, 3: 5 } }, 4: { status: 1, policies: { 1: 6, 2: 6, 3: 5 } },
    };
    localStorage.setItem('dilemmaUnlockedStatus', JSON.stringify(state.dilemmaUnlockedStatus));

    const knowledgeVaultSavedIds = ['3', '5'];
    localStorage.setItem('knowledgeVaultSavedIds', JSON.stringify(knowledgeVaultSavedIds));
    state.knowledgeVaultSavedIds = knowledgeVaultSavedIds;

    const argumentIndexesPerPolicyIndex = {"0": ["10", "35"], "1":["2","21"],"2":["24","28"],"3":["23","26"]};
    localStorage.setItem('argumentIndexesPerPolicyIndex', JSON.stringify(argumentIndexesPerPolicyIndex));
    state.argumentIndexesPerPolicyIndex = argumentIndexesPerPolicyIndex;

    state.isKnowledgeVaultOpen = false;

    state.currentDilemmaId = null;
    localStorage.setItem('currentDilemmaId', JSON.stringify(state.currentDilemmaId));

    state.selectedScenarioIndex = 1;
    localStorage.setItem('selectedScenarioIndex', JSON.stringify(1));

    state.currentEvalAndPDPageIndex = 19;
    localStorage.setItem('currentEvalAndPDPageIndex', JSON.stringify(19));

    const argumentsUsedInBossFight = {
      1: '10',
      2: '35',
      3: '21',
      4: '24',
    };
    localStorage.setItem('argumentsUsedInBossFight', JSON.stringify(argumentsUsedInBossFight));
    state.argumentsUsedInBossFight = argumentsUsedInBossFight;
  },
};
