export const BACKGROUND_FARM = 'farm';
export const BACKGROUND_GONDWANA = 'gondwana';
export const BACKGROUND_DARK_GONDWANA = 'dark-gondwana';
export const BACKGROUND_BLUE_GONDWANA = 'blue-gondwana';
export const BACKGROUND_PLANE = 'plane';
export const BACKGROUND_OVERVIEW = 'overview';
export const BACKGROUND_PLANE_PHONE = 'plane-phone';
export const BACKGROUND_BOSS_FIGHT = 'boss-fight';
export const BACKGROUND_BOSS_PARTY = 'boss-party';
export const BACKGROUND_DILEMMA_POLICY_SELECT = 'dilemma-policy-select';
export const BACKGROUND_PHASE_1 = 'phase-1';
export const BACKGROUND_PHASE_2 = 'phase-2';
export const BACKGROUND_PHASE_3 = 'phase-3';
export const BACKGROUND_PHASE_4 = 'phase-4';
export const BACKGROUND_PHASE_5 = 'phase-5';
