<template>
  <div class="story-container container-fluid">
    <div class="row">
      <div class="col-12">
        <!-- TOP BAR -->
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="speaker-info" v-if="currentSpeaker">
          <div class="speaker-name">Speaker name: {{ currentSpeaker.name }}</div>
          <div class="speaker-role">Speaker role: {{ currentSpeaker.role }}</div>
        </div>
        <div v-else class="speaker-info">
          <div class="speaker-name">Speaker name: (none)</div>
          <div class="speaker-role">Speaker role: (none)</div>
        </div>
      </div>
      <div class="col-6">
        <div class="story-message-container" :class="showHistory ? 'story-message-container-with-history' : ''" ref="storyMessageContainer">
          <div v-if="loading" class="loading">Loading {{ story }}..</div>
          <div class="story-message-row" v-for="storyMessage in storyMessageHistory"
               v-if="showHistory">
            <accordion :open="storyMessage.open">
              <story-message
                :key="storyMessage.index"
                :speaker="storyMessage.speaker"
                :text="storyMessage.text"
              />
            </accordion>
          </div>
          <div class="story-message-row row" v-if="!showHistory && lastStoryMessage">
            <div class="col-2">
              <button class="btn btn-success" :disabled="!canSaveToKnowledgeBank" @click="saveToKnowledgeBank">
                Save to
              </button>
            </div>
            <div class="col-10">
              <story-message
                :key="lastStoryMessage.index"
                :speaker="lastStoryMessage.speaker"
                :text="lastStoryMessage.text"
              />
            </div>
          </div>
          <div class="current-speaker" v-if="currentSpeaker && speakerIsTyping">
            {{ currentSpeaker.name }} is typing..
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2">
        <div class="story-choice-container" v-if="currentChoices.length">
          <story-choices
            :choices="currentChoices"

            @pick-choice="onPickChoice"
          />
        </div>
      </div>
    </div>
    <div class="row debug-info" v-if="showDebugInfo">
      <div class="col-4 offset-2">
        <strong>Tags</strong>
        <ul>
          <li v-for="tag in currentTags">
            <strong>{{ tag }}</strong>
          </li>
        </ul>
      </div>
      <div class="col-4">
        <strong>Variables</strong>
        <ul>
          <li v-for="(value, variable) in variables">
            <strong>{{ variable }}</strong>: {{ value }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BaseStory from "./BaseStory";
import {ADD_KNOWLEDGE_BANK_ITEM} from "../../store/mutation-types";
import {mapState} from "vuex";

export default {
  name: 'story',
  extends: BaseStory,
  props: {
    /** maps to "dilemma" **/
    dilemmaIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentSpeaker: null,
      currentMessageSavedToKnowledgeBank: false,
    };
  },
  watch: {
    'story.currentMessage'() {
      console.log('story currentmessage', this.story.currentIndex);
      this.currentMessageSavedToKnowledgeBank = false;
    },
  },
  methods: {
    saveToKnowledgeBank() {
      if (!this.canSaveToKnowledgeBank) {
        return;
      }

      this.currentMessageSavedToKnowledgeBank = true;

      const currentMessage = this.story.currentMessage;

      this.$store.commit(ADD_KNOWLEDGE_BANK_ITEM, {
        sourceName: this.currentSpeaker.role,
        // TODO: Maybe this.currentSpeaker.name ???
        message: currentMessage.message,
        sourceDilemmaIndex: this.dilemmaIndex,
        messageId: currentMessage.id,
      });
    },
  },
  computed: {
    ...mapState([
      'dilemmas',
    ]),
    dilemma() {
      return this.dilemmas[this.dilemmaIndex];
    },
    canSaveToKnowledgeBank() {
      return !this.currentMessageSavedToKnowledgeBank;
    },
  },
};
</script>

<style lang="scss" scoped>
.story-container {
  padding-top: 60px;
}

.story-message-container {
  &.story-message-container-with-history {
    height: 60vh;
    overflow-y: scroll;
    //mask-image: linear-gradient(transparent 0%, black 20%);
  }
}

.speaker-info {
  background: rgba(0, 0, 0, 80%);
  padding: 10px;
  color: white;
}

.story-message-row {
  &:first-child {
    margin-top: 0;
  }

  margin-top: 15px;
}

.debug-info {
  color: white;
  background: rgba(0, 0, 0, 50%);

  pre {
    font-size: 10px;
    color: white;
  }
}

.argument-bar-container {
  margin-top: 15px;
  border: 5px dotted #333333;
  height: 100px;
  padding: 15px;
}

.argument-bar {
  background: #333333;
  border-radius: 10px;
  color: white;
  overflow: hidden;
  height: 100%;
  display: inline-block;
  padding: 15px;
  white-space: nowrap;
  line-height: 5px;
}
</style>
