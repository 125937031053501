import textToHtml from './textToHtml';

export function createOnboardingPointFinalEvalText(onboardingPoints, selectedScenarioIndex, dealAccepted, translate) {
  const scoreTextObject = {};
  let scoreText;
  let score;

  if (onboardingPoints > 12) {
    score = 'finalScoreView.scoreRankAPlus';
    if (dealAccepted) {
      if (selectedScenarioIndex === 0 || selectedScenarioIndex === 1) {
        scoreText = 'finalScoreView.onboardingScoreAPlus1';
      } else {
        scoreText = 'finalScoreView.onboardingScoreAPlus2';
      }
    } else {
      scoreText = 'finalScoreView.onboardingScoreAPlus3';
    }
  } else if (onboardingPoints > 8) {
    score = 'finalScoreView.scoreRankBPlus';
    if (dealAccepted) {
      if (selectedScenarioIndex === 0 || selectedScenarioIndex === 1) {
        scoreText = 'finalScoreView.onboardingScoreBPlus1';
      } else {
        scoreText = 'finalScoreView.onboardingScoreBPlus2';
      }
    } else {
      scoreText = 'finalScoreView.onboardingScoreBPlus3';
    }
  } else {
    score = 'finalScoreView.scoreRankC';
    if (dealAccepted) {
      if (selectedScenarioIndex === 0 || selectedScenarioIndex === 1) {
        scoreText = 'finalScoreView.onboardingScoreC1';
      } else {
        scoreText = 'finalScoreView.onboardingScoreC2';
      }
    } else {
      scoreText = 'finalScoreView.onboardingScoreC3';
    }
  }

  scoreTextObject.scoreText = translate(scoreText);
  scoreTextObject.score = translate(score);

  return scoreTextObject;
}

export function createSustainabilityFinalEvalObject(selectedScenarioIndex, translate) {
  const sustainabilityAppreciationObject = {};

  let score;
  let scoreText;

  if (selectedScenarioIndex === 0) {
    score = 'finalScoreView.scoreRankB';
    scoreText = 'finalScoreView.sustainScoreB';
  } else if (selectedScenarioIndex === 1) {
    score = 'finalScoreView.scoreRankAMinus';
    scoreText = 'finalScoreView.sustainScoreAMinus';
  } else if (selectedScenarioIndex === 2) {
    score = 'finalScoreView.scoreRankBPlus';
    scoreText = 'finalScoreView.sustainScoreBPlus';
  }

  sustainabilityAppreciationObject.score = translate(score);
  sustainabilityAppreciationObject.scoreTextHtml = textToHtml(translate(scoreText), translate);

  return sustainabilityAppreciationObject;
}

export function createEvidenceBasedScoreFinalEvalObject(evidencePointsHarvestData, evidencePointsTransformData, evidencePointsBossFight, translate) {
  const ebsFinalEvalObject = {};

  const argumentBasedScore = evidencePointsTransformData + evidencePointsHarvestData;
  const negotiatingBasedScore = evidencePointsBossFight;

  let scoreArgumentsTitle;
  let scoreArgumentsText;
  let argumentsScore;
  let negotiationScore;
  let scoreNegotiationText;

  if (argumentBasedScore > 24) {
    argumentsScore = 'finalScoreView.scoreRankA';
    scoreArgumentsTitle = 'finalScoreView.argumentScoreTitleA';
    scoreArgumentsText = 'finalScoreView.argumentScoreTextA';
  } else if (argumentBasedScore > 18) {
    argumentsScore = 'finalScoreView.scoreRankB';
    scoreArgumentsTitle = 'finalScoreView.argumentScoreTitleB';
    scoreArgumentsText = 'finalScoreView.argumentScoreTextB';
  } else {
    argumentsScore = 'finalScoreView.scoreRankC';
    scoreArgumentsTitle = 'finalScoreView.argumentScoreTitleC';
    scoreArgumentsText = 'finalScoreView.argumentScoreTextC';
  }

  if (negotiatingBasedScore > 9) {
    negotiationScore = 'finalScoreView.scoreRankA';
    scoreNegotiationText = 'finalScoreView.negotiationScoreTextA';
  } else if (negotiatingBasedScore > 5) {
    negotiationScore = 'finalScoreView.scoreRankB';
    scoreNegotiationText = 'finalScoreView.negotiationScoreTextB';
  } else {
    negotiationScore = 'finalScoreView.scoreRankC';
    scoreNegotiationText = 'finalScoreView.negotiationScoreTextC';
  }

  ebsFinalEvalObject.scoreArgumentsTitle = translate(scoreArgumentsTitle);
  ebsFinalEvalObject.scoreArgumentsText = translate(scoreArgumentsText);
  ebsFinalEvalObject.argumentsScore = translate(argumentsScore);
  ebsFinalEvalObject.negotiationScore = translate(negotiationScore);
  ebsFinalEvalObject.scoreNegotiationText = translate(scoreNegotiationText);

  return ebsFinalEvalObject;
}
