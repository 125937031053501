<template>
  <div class="document-news-pres">
    <div class="row">
      <div class="col-12 text">
        <h5>{{ $t('document.gondwanaPresentation.mapTitle') }}</h5>
        <div v-html="mapTextHtml"/>
      </div>
      <div class="col-12 map">
        <no-drag-image src="@/assets/img/ui/gondwanaPresentation/map.png"/>
      </div>
      <div class="col-5 rice background-image "/>
      <div class="col-7 text">
        <h5>{{ $t('document.gondwanaPresentation.riceConsumptionTitle') }}</h5>
        <div v-html="riceConsumptionTextHtml"/>
      </div>
      <div class="col-5 text">
        <h5>{{ $t('document.gondwanaPresentation.riceFarmingTitle') }}</h5>
        <div v-html="riceFarmingTextHtml"/>
      </div>
      <div class="col-7 farmers background-image"/>
    </div>

    <div class="row">
      <div class="col-4 standalone-row ">
        <div class="standalone-row-content">
          <div class="savannah background-image"/>
          <div class="text fill">
            <h5>{{ $t('document.gondwanaPresentation.savannahTitle') }}</h5>
            {{ $t('document.gondwanaPresentation.savannahText') }}
          </div>
        </div>
      </div>
      <div class="col-4 standalone-row">
        <div class="standalone-row-content">
          <div class="desserts background-image"/>
          <div class="text fill">
            <h5>{{ $t('document.gondwanaPresentation.desertTitle') }}</h5>
            {{ $t('document.gondwanaPresentation.desertText') }}
          </div>
        </div>
      </div>
      <div class="col-4 standalone-row">
        <div class="standalone-row-content">
          <div class="mountain background-image"/>
          <div class="text fill">
            <h5>{{ $t('document.gondwanaPresentation.mountainTitle') }}</h5>
            {{ $t('document.gondwanaPresentation.mountainText') }}
          </div>
        </div>
      </div>
    </div>

    <div class="row border-top">
      <div class="col-4 ganar background-image "/>
      <div class="col-8 text">
        <h5>{{ $t('document.gondwanaPresentation.GanirTitle') }}</h5>
        <div v-html="ganirTextHtml"/>
      </div>
    </div>

    <div class="row smaller-row border-top">
      <div class="col-5 text">
        <h5>{{ $t('document.gondwanaPresentation.cultureTitle') }}</h5>
        {{ $t('document.gondwanaPresentation.cultureText') }}
      </div>
      <div class="col-7 blues background-image"/>
      <div class="col-5 handshake background-image "/>
      <div class="col-7 text">
        <h5>{{ $t('document.gondwanaPresentation.cooperationTitle') }}</h5>
        {{ $t('document.gondwanaPresentation.cooperationText') }}
      </div>
    </div>
  </div>
</template>

<script>

import textToHtml from '../../../utils/textToHtml';
import NoDragImage from '../../common/NoDragImage';

export default {
  name: 'DocumentGondwanaPresentation',
  components: { NoDragImage },
  computed: {
    mapTextHtml() {
      return textToHtml(this.$t('document.gondwanaPresentation.mapText'), this.$t);
    },
    riceConsumptionTextHtml() {
      return textToHtml(this.$t('document.gondwanaPresentation.riceConsumptionText'), this.$t);
    },
    riceFarmingTextHtml() {
      return textToHtml(this.$t('document.gondwanaPresentation.riceFarmingText'), this.$t);
    },
    ganirTextHtml() {
      return textToHtml(this.$t('document.gondwanaPresentation.GanirText'), this.$t);
    },
  },
};
</script>
<style lang="scss" scoped>
.document-news-pres {
  width: 60vw;

  .smaller-row {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .row.display-flex > [class*='col-'] {
    flex-grow: 1;
  }

  .fill {
    height: 100%;
  }

  .background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #bbb;
    min-height: 200px;
    background-position: center;
  }

  .text {
    color: #fff;
    background-color: #4C6066;
    padding: 15px;
  }

  .border-top {
    position: relative;
    border-top: 14px solid #F6F6F6 !important;
  }

  .standalone-row {
    padding: 14px 7px;
    .standalone-row-content {
      height: 100%;
    }
  }

  .standalone-row:first-child {
    padding-left: 0;
  }

  .standalone-row:last-child {
    padding-right: 0;
  }

  .map {
    width: 100%;
    padding: 0 !important;
    background-color: #bbb;
    img {
      width: 100%;
    }
  }

  .desserts {
    background-image: url("./../../../assets/img/ui/gondwanaPresentation/desserts.jpg");
  }

  .farmers {
    background-position: center top !important;
    background-image: url("./../../../assets/img/ui/gondwanaPresentation/farmers.jpg");
  }

  .ganar {
    background-image: url("./../../../assets/img/ui/gondwanaPresentation/ganar.jpg");
  }

  .handshake {
    background-image: url("./../../../assets/img/ui/gondwanaPresentation/handshake.jpg");
  }

  .mountain {
    background-image: url("./../../../assets/img/ui/gondwanaPresentation/mountain.jpg");
  }

  .blues {
    background-position: center top !important;
    background-image: url("./../../../assets/img/ui/gondwanaPresentation/blues.jpg");
  }

  .rice {
    background-image: url("./../../../assets/img/ui/gondwanaPresentation/rice.jpg");
  }

  .savannah {
    background-image: url("./../../../assets/img/ui/gondwanaPresentation/savannah.jpg");
  }

}

</style>
