<template>
  <div class="back-button" v-if="!disabled" @click="onClickButton">
    <div class="text-wrapper">
      <slot/>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BackButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
  .back-button {
    width: 50px;
    height: 80px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("./../../assets/img/ui/back.png");
    margin: 15px;

    .text-wrapper {
      margin-left: 70px;
      width: 108px;
      padding-top: 4px;
    }
  }

</style>
