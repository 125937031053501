<template>
  <div class="info-view-container">
    <div class="content-height-wrapper">
      <div class="content-wrapper" :class="textRight ? 'content-right-align' : ''">
        <div class="title-wrapper">
          <h2>{{ $t(title) }}</h2>
        </div>
        <div v-if="showKnowledgeVaultButton" class="knowledge-vault-button center-button-wrapper">
          <AppButton class="knowledge-vault-button" color="green" type="icon_btn" @click="onClickKnowledgeButton()">
            <no-drag-image class="button-icon" src="@/assets/img/ui/icon_knowldgevault_alt.png" /><span>{{$t('misc.knowledgeVault')}}</span>
          </AppButton>
        </div>
        <div class="text-wrapper">
          <p v-html="textHtml" />
        </div>
        <div v-if="image" class="image-wrapper image-fade-in-animation">
          <no-drag-image :src="getImageSrc(this.image)"/>
        </div>
        <div v-if="!infoButtonsIsEmpty" class="">
          <div class="info-button-wrapper d-flex">
            <div v-for="(button, i) in infoButtons" :key="i" class="flex-fill">
              <div class="tab" :class="i === currentTab ? 'active-tab' : ''" @click="infoButtonClick(button.title, button.info, i)">
                {{ button.title }}
              </div>
            </div>
          </div>
          <div class="info-content-wrapper flex-fill">
            <h5>{{ currentTitle }}</h5>
            <p>{{ currentInfo }}</p>
          </div>
        </div>
      </div>
      <div v-if="showReplayBossFightButton" class="center-button-wrapper">
        <AppButton color="green" type="icon_btn" @click="onClickReplayFinalPolicyDialogue">
          {{ $t('infoView.replayScenario') }}
        </AppButton>
      </div>
      <div v-if="showScenarios" class="strategy-buttons-wrapper d-flex justify-content-center bd-highlight">
        <div v-for="(strategy, i) in strategies" :key="i" class="p-0 flex-fill bd-highlight">
          <DilemmaButton :state="getStrategyState(i)"
                         :policies="getPoliciesAndStates(strategy.data)"
                         :is-strategy-button="true"
                         :index="i"
                         :is-clickable="canPickScenarios"
                         @click="onClickStrategyButton(i)"
          >
            {{ $t(strategy.title) }}
          </DilemmaButton>
        </div>
      </div>
      <div v-if="scenarioDataAfterText" class="content-wrapper">
        <div class="text-wrapper">
          <p>{{ $t(scenarioDataAfterText) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import textToHtml from '@/utils/textToHtml';
import AppButton from '@/components/common/AppButton';
import DilemmaButton from '@/components/DilemmaButton/DilemmaButton';
import { STATUS_AVAILABLE, STATUS_COMPLETED } from '@/enum/UnlockedStatus';
import { mapState } from 'vuex';
import strategyData from '@/data/strategyData.json';
import { SET_IS_KNOWLEDGE_VAULT_OPEN, SET_SELECTED_SCENARIO_INDEX, RESET_TO_BOSS_FIGHT } from '@/store/mutation-types';
import NoDragImage from '@/components/common/NoDragImage';

export default {
  name: 'InfoView',
  components: { NoDragImage, DilemmaButton, AppButton },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['pageCompleted'],
  data() {
    return {
      currentTitle: '',
      currentInfo: '',
      currentTab: 0,
      strategies: strategyData,
      hasClickedStrategy: false,
      selectedStrategy: -1,
    };
  },
  computed: {
    ...mapState([
      'dilemmas',
      'dilemmaUnlockedStatus',
    ]),
    title() {
      return this.data.title;
    },
    text() {
      return this.data.text;
    },
    image() {
      return this.data?.image;
    },
    showScenarios() {
      return this.data?.showScenarios;
    },
    textRight() {
      return this.data?.textRight;
    },
    showKnowledgeVaultButton() {
      return this.data?.showKnowledgeVaultButton;
    },
    showReplayBossFightButton() {
      return this.data?.showReplayBossFightButton;
    },
    scenarioData() {
      return this.data?.showScenariosData;
    },
    scenarioDataAfterText() {
      const scenarioDataAfterText = this.scenarioData?.textAfterScenarios || false;
      return scenarioDataAfterText === '' ? false : scenarioDataAfterText;
    },
    canPickScenarios() {
      return this.scenarioData?.canPickScenarios;
    },
    infoButtons() {
      return this.data?.infoButtons ? this.data.infoButtons : [];
    },
    textHtml() {
      return textToHtml(this.$t(this.text), this.$t);
    },
    infoButtonsIsEmpty() {
      return this.infoButtons.length === 0;
    },
  },
  mounted() {
    if (this.infoButtons.length > 0) {
      this.currentTitle = this.infoButtons[0].title;
      this.currentInfo = this.infoButtons[0].info;
    }
  },
  methods: {
    infoButtonClick(newTitle, newInfo, tabIndex) {
      this.currentTitle = newTitle;
      this.currentInfo = newInfo;
      this.currentTab = tabIndex;
    },
    onClickReplayFinalPolicyDialogue() {
      if (confirm('Are you sure that you want to replay the policy dialogue?')) {
        this.$store.commit(RESET_TO_BOSS_FIGHT);
      } else {

      }
    },
    onClickKnowledgeButton() {
      this.$store.commit(SET_IS_KNOWLEDGE_VAULT_OPEN, true);
      this.$emit('pageCompleted');
    },
    getImageSrc(key) {
      const images = require.context('../../assets/img/ui');
      const imgSrc = images('./' + key + '.png');
      return imgSrc;
    },
    getPoliciesAndStates(policyIdentification) {
      const policiesAndStates = [];

      for (const policyIdentifier of policyIdentification) {
        const tempPolicy = {};

        const dilemmaId = policyIdentifier.dilemmaId.toString();
        const policyId = policyIdentifier.policyId.toString();

        // find policyName
        const policyInDilemmas = this.dilemmas?.[dilemmaId].policyUnlocks?.[policyId];
        const policyTitle = policyInDilemmas?.title;
        tempPolicy.title = policyTitle;

        // find policyState
        const policyState = this.dilemmaUnlockedStatus?.[dilemmaId].policies?.[policyId];
        tempPolicy.state = policyState;

        policiesAndStates.push(tempPolicy);
      }

      return policiesAndStates;
    },
    onClickStrategyButton(i) {
      if (!this.canPickScenarios) {
        return;
      }
      this.hasClickedStrategy = true;
      this.selectedStrategy = i;
      this.$store.commit(SET_SELECTED_SCENARIO_INDEX, i);
      this.$emit('pageCompleted');
    },
    getStrategyState(strategyIndex) {
      if (this.canPickScenarios && !this.hasClickedStrategy) {
        return STATUS_COMPLETED;
      }
      return this.selectedStrategy === strategyIndex ? STATUS_AVAILABLE : STATUS_COMPLETED;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-view-container {
  color: #ffffff;
  font-weight: 700;

  .content-height-wrapper {
    padding-bottom: 60px;

    .content-wrapper {
      width: 65%;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5%;
      padding: 0 55px 30px;

      &.content-right-align {
        width: 40%;
        margin-right: 7%;
      }

      .title-wrapper {
        text-align: center;
        font-size: 32px;
        margin-bottom: 45px;
      }

      .text-wrapper {
        text-align: justify;
      }

      .image-wrapper {
        width: 100%;
        margin-top: 25px;
        img {
          width: 100%;
        }
      }

      .image-fade-in-animation {
        animation-name: imageFadeIn;
        animation-duration: 0.3s;
        animation-delay: 0.1s;
        animation-timing-function: ease-in-out;
      }

      @keyframes imageFadeIn {
        0%   {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .info-button-wrapper {
        @media(min-width: 50rem) {
        }

        .tab {

          background-color: #2C4A57;
          padding: 15px 15px 10px 15px;
          height: 100%;

          margin: 0 2px;

          border: none;
          border-radius: 10px 10px 0 0;
          position: relative;
          cursor: pointer;
        }

        .active-tab {

          background-color: lighten(#2C4A57, 10%);
        }
      }

      .info-content-wrapper {
        background-color: lighten(#2C4A57, 10%);
        margin: 0 2px;
        padding: 15px 15px 25px;
        font-weight: 400;
      }
    }
  }

  .center-button-wrapper {
    margin-right: auto;
    margin-left: auto;
    width: fit-content;
    margin-top: 25px;
    white-space: nowrap;
    height: 50px;

    &.knowledge-vault-button {
      margin-bottom: 55px;

      .knowledge-vault-button::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        border-radius: 10px;
        width: calc(100% + 2px);
        height: calc(100% + 6px);
        box-shadow: rgba(255, 255, 255, 0.7) 0px 12px 28px 0px, rgba(255, 255, 255, 0.6) 0px 2px 4px 0px, rgba(255, 255, 255, 0.4) 0px 0px 0px 1px inset;
        animation: pulse 2s infinite;
      }
    }

    img {
      width: 40px;
      margin-right: 15px;
    }
  }

  .strategy-buttons-wrapper {
    margin-right: auto;
    margin-left: auto;
    width: fit-content;
    padding-left: 10px;
    padding-top:10px;
    column-gap: 10px;

    .start-button-wrapper {
      margin-right: auto;
      margin-left: auto;
      width: fit-content;
    }
  }

}

</style>
