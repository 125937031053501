import { createStore } from 'vuex';
import initState from './state';
import getters from './getters';
import mutations from './mutations';

export default createStore({
  state() {
    return initState;
  },
  getters,
  mutations,
});
