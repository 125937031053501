<template>
  <AssistantFull v-if="showFull" :start-delay="startDelay" :is-first-message="currentMessageIndex === 0"
                 :current-message="currentMessage" :current-choices="choiceButtons"
                 @click="progressFlowFull"
  />
  <AssistantBanner v-if="showBanner" :start-delay="startDelay" :is-first-message="currentMessageIndex === 0"
                   :current-choices="choiceButtons" :assistant-type="currentAssistantType"
                   :current-message="currentMessage" :tutorial-highlight="tutorialHighlight"
                   :interaction-required="interactionRequired" :interaction-element-id="interactionElementId"
                   :current-assistant-placement="currentAssistantPlacement"
                   @showAnswer="showAnswer" @click="progressFlow"
  />
</template>
<script>

import AssistantBanner from '@/components/AssistantBanner/AssistantBanner';
import AssistantFull from '@/components/AssistantBanner/AssistantFull';
import assistantChoiceData from '@/data/assistant/assistantChoiceData.json';
import { mapState } from 'vuex';
import { VIEW_OVERVIEW } from '@/enum/Views.js';
import { STATUS_SELECTED } from '../../enum/UnlockedStatus';
import { SET_POLICY_UNLOCKED_STATUS } from '../../store/mutation-types';
import { ASSISTANT_TYPE_FULL, ASSISTANT_TYPE_BANNER, ASSISTANT_TYPE_BANNER_INSTANT } from '../../enum/AssistantTypes';

export default {
  name: 'AssistantController',
  components: {
    AssistantBanner,
    AssistantFull,
  },
  props: {
    assistantMessage: {
      type: Object,
      default: null,
    },
  },
  emits: ['click'],
  data() {
    return {
      currentMessageIndex: 0,
      flowIsActive: false,
      bannerChoiceSelected: false,
      currentChoiceResponse: '',
    };
  },
  watch: {
    assistantMessage() {
      if (!this.assistantMessage) {
        return;
      }
      this.startNewFlow();
    },
  },
  computed: {
    ...mapState([
      'dilemmas',
      'dilemmaStatus',
      'currentDilemmaId',
    ]),
    currentMessages() {
      return this.assistantMessage?.messages || [];
    },
    currentAssistantType() {
      return this.assistantMessage?.type || '';
    },
    startDelay() {
      return this.assistantMessage?.startDelay || 0;
    },
    currentAssistantPlacement() {
      return this.assistantMessage?.placement || 'bottom';
    },
    currentChoiceType() {
      return this.currentMessages[this.currentMessageIndex]?.choiceType || null;
    },
    currentMessage() {
      if (this.currentChoiceResponse !== '') {
        return this.currentChoiceResponse;
      }
      return this.currentMessages[this.currentMessageIndex]?.message || '';
    },
    interactionElementId() {
      return this.currentMessages[this.currentMessageIndex]?.interactionElementId || null;
    },
    interactionRequired() {
      return this.currentMessages[this.currentMessageIndex]?.interactionRequired || false;
    },
    tutorialHighlight() {
      return this.currentMessages[this.currentMessageIndex]?.tutorialHighlight || null;
    },
    showBanner() {
      return this.flowIsActive && (this.currentAssistantType === ASSISTANT_TYPE_BANNER || this.currentAssistantType === ASSISTANT_TYPE_BANNER_INSTANT);
    },
    showFull() {
      return this.flowIsActive && this.currentAssistantType === ASSISTANT_TYPE_FULL;
    },
    choiceButtons() {
      /**
       *           FULL
       *           text: 'Choice text long Choice text long Choice text long Choice text long',
       *           value: 'long',
       *
       *           BANNER
       *           "choice": "To be well informed on what I work on from a technical point of view",
       *           "response": "That could possibly be useful indeed.",
       */

      if (this.currentAssistantType === ASSISTANT_TYPE_BANNER && this.currentChoiceType === 'choice' && !this.bannerChoiceSelected) {
        return this.currentMessages[this.currentMessageIndex]?.choices || [];
      }

      if (this.currentAssistantType !== ASSISTANT_TYPE_FULL) {
        return [];
      }

      // If the assistant type is 'full', make list of choices
      const choiceButtons = [];
      const optionDataFromType = assistantChoiceData?.[this.currentChoiceType] || null;

      if (!optionDataFromType) {
        return choiceButtons;
      }

      for (let i = 0, len = optionDataFromType.length; i < len; i++) {
        const choice = optionDataFromType[i];
        if (this.currentChoiceType === 'policies') {
          const dilemmaData = this.dilemmas[this.currentDilemmaId];
          choice.text = dilemmaData.policyUnlocks[(i + 1).toString()].title;
        }

        choiceButtons.push({
          text: choice.text,
          btnText: choice.text,
          value: (i + 1).toString(), // Just use the index
        });
      }
      return choiceButtons;
    },
  },
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      console.log("on click button 2");
      this.$emit('click');
    },
    showAnswer(response) {
      // ensure that the choices is set to empty list in the computed value for current choices
      // set correct message
      this.bannerChoiceSelected = true;
      this.currentChoiceResponse = response;
    },
    progressFlow() {
      this.currentMessageIndex++;

      // reset choice impacts
      this.bannerChoiceSelected = false;
      this.currentChoiceResponse = '';

      // hide assitant if flow is done
      if (this.currentMessageIndex === this.currentMessages.length) {
        this.flowIsActive = false;
      }
    },
    progressFlowFull(chosenIndex) {
      const actualChosenIndex = chosenIndex - 1;
      if (this.currentChoiceType === 'policies') {
        // Set selected policy status as selected
        const chosenOption = this.choiceButtons[actualChosenIndex];
        const policyId = chosenOption.value;

        const data = {
          dilemmaId: this.currentDilemmaId,
          policyId,
          newStatus: STATUS_SELECTED,
        };

        this.$store.commit(SET_POLICY_UNLOCKED_STATUS, data);

        // Close assistant and go to the dilemma view
        this.endFlow();
        this.$router.push({ name: VIEW_OVERVIEW });
      }

      if (this.currentChoiceType === 'continue') {
        // Stop flow if option to go back was selected
        if (chosenIndex === 0) {
          this.endFlow();
          return;
        }
      }

      this.currentMessageIndex++;
      if (this.currentMessageIndex === this.currentMessages.length) {
        this.endFlow();
      }
    },
    startNewFlow() {
      // console.log('start new flow', this.currentMessages, this.currentMessages.length);
      if (this.currentMessages.length > 0) {
        this.currentMessageIndex = 0;
        this.flowIsActive = true;
      }
    },
    endFlow() {
      this.flowIsActive = false;
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
