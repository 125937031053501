<template>
  <div class="home-view-wrapper">
    <div class="title-wrapper">
      <h4>{{ $t('homeView.title') }}</h4>
    </div>
    <div class="dilemma-buttons-wrapper d-flex justify-content-center bd-highlight">
      <div class="dilemma-background-color"/>
      <div class="p-0 flex-fill bd-highlight" v-for="(dilemma, i) in dilemmas" :key="i">
        <DilemmaButton v-if="!isTutorialDilemma(i)" :dilemma-question="getSpecificDilemmaQuestion(i)" :state="getDilemmaState(dilemma.dilemma)"
                       :policies="getPolicies(dilemma.dilemma, dilemma.policyUnlocks)"
                       @click="onClickDilemmaButton(dilemma.dilemma)"
        >
          {{ getDilemmaTitle($t(dilemma.title), dilemma.dilemma) }}
        </DilemmaButton>
      </div>
    </div>
    <div v-if="allDilemmasCompleted" class="bottom-button-wrapper">
      <AppButton :disabled="cannotContinueToBossFight" size="'large'" @click="startStrategy()">
        {{ $t('homeView.initiatePolicyDialogue') }}
      </AppButton>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import DilemmaButton from '@/components/DilemmaButton/DilemmaButton';
import AppButton from '@/components/common/AppButton';
import {
  VIEW_DILEMMA,
  VIEW_DILEMMA_INTRO,
  VIEW_EVAL_AND_PD,
  VIEW_INFORMAL_PD,
} from '@/enum/Views.js';
import { BACKGROUND_OVERVIEW } from '@/enum/Background.js';
import {
  STATUS_LOCKED,
  STATUS_UNAVAILABLE,
  STATUS_COMPLETED,
  STATUS_AVAILABLE,
} from '@/enum/UnlockedStatus.js';
import {
  SET_CURRENT_DILEMMA_ID,
  SET_BACKGROUND,
} from '@/store/mutation-types';
import * as DataUtil from '../utils/dataUtil';

export default {
  name: 'HomeView',
  components: {
    DilemmaButton,
    AppButton,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState([
      'dilemmas',
      'dilemmaUnlockedStatus',
      'hasPlayedInformalPD',
    ]),
    allDilemmasCompleted() {
      for (const key in this.dilemmaUnlockedStatus) {
        // Skip the tutorial
        if (key === '0') {
          continue;
        }

        console.log(`${key}`, this.dilemmaUnlockedStatus[key].status);
        if (this.dilemmaUnlockedStatus[key].status !== STATUS_COMPLETED) {
          return false;
        }
      }

      return true;
    },
    cannotContinueToBossFight() {
      return !this.allDilemmasCompleted;
    },
    dilemmaQuestions() {
      return DataUtil.getDilemmaQuestions();
    },
  },
  mounted() {
    this.$store.commit(SET_BACKGROUND, BACKGROUND_OVERVIEW);

    // check if informal pd should be initiated and start it
    if (this.shouldStartInformalPD()) {
      setTimeout(() => {
        this.startInformalPD();
      }, 1000);
    }
  },
  methods: {
    shouldStartInformalPD() {
      if (this.dilemmaUnlockedStatus['3'].status === STATUS_AVAILABLE || this.dilemmaUnlockedStatus['3'].status === STATUS_COMPLETED) {
        return false;
      }
      if (this.hasPlayedInformalPD) {
        return false;
      }
      if (this.dilemmaUnlockedStatus['1'].status === STATUS_COMPLETED && this.dilemmaUnlockedStatus['2'].status === STATUS_COMPLETED) {
        return true;
      }
      return false;
    },
    getSpecificDilemmaQuestion(key) {
      return this.dilemmaQuestions?.[key] ?? '';
    },
    startInformalPD() {
      this.$router.push({ name: VIEW_INFORMAL_PD });
    },
    isTutorialDilemma(dilemmaId) {
      return dilemmaId === 'INTRO2';
    },
    onClickDilemmaButton(dilemmaId) {
      this.$store.commit(SET_CURRENT_DILEMMA_ID, dilemmaId);
      // analytics
      if (this.getDilemmaState(dilemmaId) === STATUS_AVAILABLE) {
        this.$root.callDilemmaStartEvent(dilemmaId);
      }

      if (dilemmaId === 1) {
        this.$router.push({ name: VIEW_DILEMMA_INTRO });
        return;
      }
      this.$router.push({ name: VIEW_DILEMMA });
    },
    getDilemmaState(dilemmaId) {
      return this.dilemmaUnlockedStatus?.[dilemmaId.toString()]?.status || STATUS_UNAVAILABLE;
    },
    getDilemmaTitle(dilemmaTitle, dilemmaId) {
      return this.getDilemmaState(dilemmaId) !== STATUS_UNAVAILABLE ? dilemmaTitle : this.$t('misc.unknown');
    },
    getPolicies(dilemmaId, policies) {
      const policyArray = [];

      for (const key in policies) {
        const policyId = key.toString();
        const tempPolicy = policies[key];
        tempPolicy.state = this.dilemmaUnlockedStatus?.[dilemmaId.toString()]?.policies?.[policyId] || STATUS_LOCKED;
        policyArray.push(policies[key]);
      }

      return policyArray;
    },
    startStrategy() {
      this.$store.commit(SET_CURRENT_DILEMMA_ID, null);
      this.$router.push({ name: VIEW_EVAL_AND_PD });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-view-wrapper {
  color: #ffffff;
  padding: 70px;

  .title-wrapper {
    padding-bottom: 50px;
  }

  .dilemma-buttons-wrapper {
    position: relative;
    width: 100%;
    height: 65vh;
    max-width: 1210px;
    padding-left: 10px;
    padding-top:10px;
    column-gap: 10px;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    border-radius: 10px;

    .dilemma-background-color {
      background: linear-gradient(180deg, #839FA2 75.3%, rgba(131, 159, 162, 0) 100%);
      opacity: 0.52;
      position: absolute;
      border-radius: 10px;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  .bottom-button-wrapper {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 15px;

    .info-text {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

</style>
