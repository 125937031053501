<template>
  <div class="dilemma-button-wrapper" :class="stateClass" @click="onClickButton">
    <p v-if="!isStrategyButton" :class="stateClass">{{ stateText }}</p>
    <div class="title-wrapper" :class="isStrategyButton ? 'tall-title' : ''">
      <h5 v-if="!isStrategyButton"><slot/></h5>
      <h5 v-if="isStrategyButton"><slot/></h5>
      <div v-if="dilemmaQuestion" class="dilemma-question">
        <p>{{ $t(dilemmaQuestion) }}</p>
      </div>
    </div>
    <div v-if="!isUnavailable" class="policy-wrapper">
      <p class="cursive small-title" v-if="!isStrategyButton" :class="stateClass">{{$t('misc.policyMeasures')}}</p>
      <div v-for="(policy, i) in policies" :key="i">
        <PolicyButton :state="getCorrectedPolicyState(policy.state)" :is-clickable="isClickable">{{ $t(policy.title) }}</PolicyButton>
      </div>
    </div>

    <div v-if="replayButtonVisible" class="replay-btn">
      <AppButton :size="'small'" :color="'light_grey'" @click="onClickButton">
        {{ $t('misc.replay') }}
      </AppButton>
    </div>

    <div class="image-wrapper" v-if="isUnavailable">
      <no-drag-image src="@/assets/img/ui/source_unknown.png"/>
    </div>
  </div>
</template>
<script>

import { STATUS_COMPLETED, STATUS_AVAILABLE, STATUS_UNAVAILABLE, STATUS_LOCKED, STATUS_UNLOCKED } from '@/enum/UnlockedStatus.js';
import PolicyButton from '@/components/DilemmaButton/PolicyButton';
import AppButton from '@/components/common/AppButton';
import NoDragImage from '@/components/common/NoDragImage';

export default {
  name: 'DilemmaButton',
  components: {
    NoDragImage,
    AppButton,
    PolicyButton,
  },
  props: {
    state: {
      type: Number,
      default: STATUS_UNAVAILABLE,
    },
    policies: {
      type: Array,
      default: null,
    },
    isStrategyButton: {
      type: Boolean,
      default: false,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0,
      required: false,
    },
    dilemmaQuestion: {
      type: String,
      default: null,
    },
  },
  emits: ['click'],
  computed: {
    isUnavailable() {
      return this.state === STATUS_UNAVAILABLE;
    },
    stateClass() {
      const stateClasses = [];
      const className = this.getTranslation(this.state, false);
      const classNameIndex = `${className}-${this.index}`;
      stateClasses.push(className);
      stateClasses.push(classNameIndex);

      if (!this.isClickable) {
        stateClasses.push('non-clickable');
      }

      if (this.isStrategyButton) {
        stateClasses.push('strategy-button');
      }

      return stateClasses;
    },
    stateText() {
      const state = this.getTranslation(this.state, false);
      const localizationKey = `misc.${state}`;
      return this.$t(localizationKey);
    },
    replayButtonVisible() {
      if (this.isStrategyButton) {
        return false;
      }

      if (this.state !== STATUS_COMPLETED) {
        return false;
      }

      return true;
    },
  },
  methods: {
    onClickButton() {
      if (this.isUnavailable || !this.isClickable) {
        return;
      }

      this.$emit('click');
    },
    getCorrectedPolicyState(policyState) {
      if (!this.isStrategyButton) {
        return policyState;
      }

      return policyState === STATUS_LOCKED ? STATUS_UNLOCKED : policyState;
    },
    getPolicyTitle(policyIndex) {
      return this.policies?.[policyIndex].title || '';
    },
    getPolicyState(policyIndex) {
      return this.policies?.[policyIndex.toString()].state || STATUS_LOCKED;
    },
    getTranslation(state, capitalFirstLetter) {
      let translatedString = '';

      switch (state) {
        case STATUS_COMPLETED:
          translatedString = 'completed';
          break;
        case STATUS_AVAILABLE:
          translatedString = 'available';
          break;
        case STATUS_UNAVAILABLE:
          translatedString = 'unavailable';
          break;
        default:
          console.log('state did not match known states');
          translatedString = 'unavailable';
      }

      if (capitalFirstLetter) {
        translatedString = translatedString[0].toUpperCase() + translatedString.substring(1);
      }

      return translatedString;
    },
  },
};
</script>
<style lang="scss" scoped>

  .dilemma-button-wrapper {
    width: 100%;
    max-width: 310px;
    // height: 300px;
    height: 93%;
    cursor: pointer;
    padding: 8px;
    color: #fff;
    text-align: center;
    font-family: "Fira Sans";
    transition: background-color 250ms ease-in-out, transform 250ms cubic-bezier(0.175, 0.885, 0.320, 1.575);
    z-index: 1;
    position: relative;
    border-radius: 10px;

    &.strategy-button {

      &.available {
        z-index: 200;
        transform: scale(1.04) translate(0, -15px);
      }

      &.available::before {
        content: "";
        position: absolute;
        top: -7px;
        left: 0px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        max-width: 310px;
        border-radius: 10px;

        animation: pulse 2s infinite;
        //box-shadow: rgba(255, 191, 0, 0.4) 0px 5px, rgba(255, 207, 64, 0.3) -4px 10px, rgba(255, 207, 64, 0.2) -8px 15px, rgba(255, 207, 64, 0.1) -12px 20px, rgba(255, 207, 64, 0.05) -16px 25px;
      }

      &.available-0::before {
        box-shadow: rgba(255, 191, 0, 0.4) 0px 5px, rgba(255, 207, 64, 0.3) -4px 10px, rgba(255, 207, 64, 0.2) -8px 15px, rgba(255, 207, 64, 0.1) -12px 20px, rgba(255, 207, 64, 0.05) -16px 25px;
      }

      &.available-1::before {
        box-shadow: rgba(255, 191, 0, 0.4) 0px 5px, rgba(255, 207, 64, 0.3) 0px 10px, rgba(255, 207, 64, 0.2) 0px 15px, rgba(255, 207, 64, 0.1) 0px 20px, rgba(255, 207, 64, 0.05) 0px 25px;
      }

      &.available-2::before {
        box-shadow: rgba(255, 191, 0, 0.4) 0px 5px, rgba(255, 207, 64, 0.3) 4px 10px, rgba(255, 207, 64, 0.2) 8px 15px, rgba(255, 207, 64, 0.1) 12px 20px, rgba(255, 207, 64, 0.05) 16px 25px;
      }

    }

    .small-title {
      font-weight: 400;
      margin-top: 15px;
      line-height: 0.9;
      font-size: 14px;
    }

    .replay-btn {
      position: absolute;
      top: 0;
      right: 0;
      transform: scale(0.8) translate(8px, -4px);
    }

    .title-wrapper {
      // height: 21%;
      min-height: 50px;
      margin: 2px;
    }

    .tall-title {
      //height: 17.5%;
      min-height: 50px;
    }

    .policy-wrapper {
      position: relative;
      z-index: 3;
      width: 100%;
    }

    .image-wrapper{
      width: 70%;
      margin-top: -20px;
      margin-right: auto;
      margin-left: auto;
      img{
        width: 100%;
      }
    }

    .dilemma-question {
      color: #E4EDF2;
    }

    p {
      color: #E4EDF2;
      font-weight: 700;
      font-size: 16px;
      margin: 0;

      &.available {
        color: #839FA1;
      }

      &.unavailable {
        color: #839FA1;
      }
    }

    h5 {
      font-weight: bold;
    }

    &.completed {
      background: linear-gradient(180deg, #4C6066 13.45%, rgba(76, 96, 102, 0) 98.73%);
    }

    &.available {
      color: #000000;
      background: linear-gradient(180deg, #E0E9EB 13.45%, rgba(76, 96, 102, 0) 98.73%);

      .title-wrapper {
        .dilemma-question {
          p {
            color: #839FA1;
          }
        }
      }
    }

    &.unavailable {
      pointer-events: none;
      background: linear-gradient(180deg, rgba(70, 89, 90, 0.67) 13.45%, rgba(76, 96, 102, 0) 98.73%);
      color: #B8C7CC;
    }
  }

</style>
