<template>
  <AssistantController :assistant-message="currentMessage" />
  <div class="row mt-4">
    <h3>Send assistant message banner</h3>
    <div class="col">
      <button class="btn btn-light me-4" @click="sendLongMessage()">
        Send long
      </button>
      <button class="btn btn-light me-4" @click="sendShortMessage()">
        Send short
      </button>
      <button class="btn btn-light me-4" @click="sendShortMessage2()">
        Send short instant feedback
      </button>
      <button class="btn btn-light me-4" @click="sendShortMessage2WithDelay()">
        Send short instant feedback with delay
      </button>
    </div>
    <br>
    <br>
    <br>
    <h3>Send assistant full page</h3>
    <div class="col">
      <button class="btn btn-light me-4" @click="sendLongMessageFull()">
        Send long
      </button>
      <button class="btn btn-light me-4" @click="sendShortMessageFull()">
        Send short
      </button>
      <button class="btn btn-light me-4" @click="sendShortMessage2Full()">
        Send short 2
      </button>
    </div>
    <br>
    <br>
    <br>
    <h3>Send assistant interactive</h3>
    <div class="col">
      <button class="btn btn-light me-4" @click="sendMessageHighlight()">
        Send highlight
      </button>
      <button class="btn btn-light me-4" @click="sendMessageInteractive()">
        Send interactive
      </button>
      <button class="btn btn-light me-4" @click="sendShortMultipleChoice()">
        Send multiple choice
      </button>
    </div>
    <br>
    <br>
    <br>
    <h3>Highlight items</h3>
    <div class="col">
      <div id="circle" class="circle highlight-element">
        circle
      </div>
      <div v-if="clickCount > 0" id="square" class="square highlight-element">
        square
      </div>
      <div id="text-element" class="text-element highlight-element" @click="incrementClickCounter()">
        text-element: {{clickCount}}
      </div>
    </div>
  </div>
</template>
<script>

import AssistantController from '@/components/AssistantBanner/AssistantController';

export default {
  name: 'AssistantDev',
  components: {
    AssistantController,
  },
  emits: ['click'],
  data() {
    return {
      currentMessage: [],
      clickCount: 0,
    };
  },
  methods: {
    sendLongMessage() {
      this.sendAssistantMessage(this.assistantData.longAssistantMessage);
    },
    sendShortMessage() {
      this.sendAssistantMessage(this.assistantData.shortAssistantMessage);
    },
    sendShortMessage2() {
      this.sendAssistantMessage(this.assistantData.shortAssistantMessage2);
    },
    sendShortMessage2WithDelay() {
      this.sendAssistantMessage(this.assistantData.shortAssistantMessage2WithStartDelay);
    },
    sendLongMessageFull() {
      this.sendAssistantMessage(this.assistantData.longAssistantMessageFull);
    },
    sendShortMessageFull() {
      this.sendAssistantMessage(this.assistantData.shortAssistantMessageFull);
    },
    sendShortMessage2Full() {
      this.sendAssistantMessage(this.assistantData.shortAssistantMessage2Full);
    },
    sendMessageHighlight() {
      this.sendAssistantMessage(this.assistantData.highlightAssistantMessage);
    },
    sendMessageInteractive() {
      this.sendAssistantMessage(this.assistantData.interactiveAssistantMessage);
    },
    sendShortMultipleChoice() {
      this.sendAssistantMessage(this.assistantData.multipleChoiceAssistantMessage);
    },
    incrementClickCounter() {
      this.clickCount += 1;
    },
    sendAssistantMessage(message) {
      this.currentMessage = null;
      this.$nextTick(() => {
        this.currentMessage = message;
      });
    },
  },
  mounted() {
    this.assistantData = require('@/data/assistant/assistantTestData.json');
  },
};
</script>
<style lang="scss" scoped>

.highlight-element {
  margin: 0 30px;
  width: 150px;
  height: 150px;
  display: inline-flex;
  padding: 25px;
}

.circle {
  background-color: #2C4A57;
  color: white;
  border-radius: 50%;

}

.square {
  background-color: #1C709B;
  color: white;
  border-radius: 5px;

}

.text-element {
  background-color: #263250;
  color: white;

  &:hover {
    background-color: #223;
    cursor: pointer;
  }
}

</style>
