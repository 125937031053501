<template>
  <div class="video-modal-wrapper">
    <div class="blackness" @click="onClickBackButton"/>
    <div class="video-modal" :class="attentionWobble ? 'wobble-hor-top' : ''">
      <div class="back-button" @click="onClickBackButton">✖</div>
      <div class="text-wrapper">
        <YouTube
          ref="youtube"
          :src="videoUrl"
          height="360"
          width="560"
          @ready="onReady"
          @state-change="stateChanged"/>
      </div>
    </div>
  </div>

</template>

<script>
import { defineComponent, nextTick } from 'vue';
import YouTube from 'vue3-youtube';
import { UPDATE_TUTORIAL_ASSISTANT_STATUS } from '@/store/mutation-types';

export default {
  name: 'VideoModal',
  emits: ['closeModal'],
  components: { YouTube },
  data() {
    return {
      shouldPlayVideo: false,
      attentionWobble: false,
    };
  },
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClickBackButton() {
      this.$refs.youtube.stopVideo();
      this.$emit('closeModal');
    },
    stateChanged(state) {
      // going back to autostarting the video

      /*if (!this.shouldPlayVideo && state.data !== 3) {
        return;
      }

      if (!this.shouldPlayVideo) {
        this.$refs.youtube.pauseVideo();
        this.shouldPlayVideo = true;
        return;
      }

      if (state.data === 3) {
        this.$store.commit(UPDATE_TUTORIAL_ASSISTANT_STATUS, 'videoSeen');
      }*/
    },
    activateAttentionWobble() {
      this.attentionWobble = false;
      nextTick(() => {
        this.attentionWobble = true;
      });
    },
    onReady() {
      this.$refs.youtube.playVideo();
    },
  },
};
</script>
<style lang="scss" scoped>

  .video-modal-wrapper {
    position: absolute;
    z-index: 100000;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;

    .blackness {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.6);
    }

    .video-modal {
      z-index: 2;
      position: relative;
      height: fit-content;
      width: fit-content;
      background-color: #F6F6F6;
      border-radius: 15px;
      color: #000;
      padding: 50px 25px 25px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10%;

      .back-button {
        position: absolute;
        font-size: 1.5em;
        color: #777;
        right: 20px;
        top: 10px;
        cursor: pointer;
      }
    }
  }

</style>
