export const VIEW_DILEMMA = 'dilemmaView';
export const VIEW_DILEMMA_POLICY_CHOICE = 'dilemmaPolicyChoiceView';
export const VIEW_DIALOGUE = 'dialogueView';
export const VIEW_OVERVIEW = 'overviewView';
export const VIEW_INTRO = 'Intro';
export const VIEW_INFORMAL_PD = 'InformalPDView';
export const VIEW_EVAL_AND_PD = 'EvalAndPDView';
export const VIEW_TUTORIAL_OUTRO = 'TutorialOutroView';
export const VIEW_DILEMMA_INTRO = 'DilemmaIntroView';
export const VIEW_PRINT_VOCABULARY = 'PrintVocabularyView';
export const VIEW_PRINT_RESULTS = 'PrintResultsView';
