<template>
  <div class="document-pager">
    <div class="message-wrapper">
      <SideMenu6Pager
        :expert-data-title="currentSpeakerName"
        :expert-data-text="currentSpeakerMessage"
        :data-ids6-pager="data6PagerPlacement"
        :current-page-index="currentPageIndex"
        @dataPointClicked="highlightDataPoint"
        />
    </div>
    <div class="document-pager-content-wrapper">
      <div class="document-pager-content-wrapper-inner">

        <NoDragImage @load="onPagerImageLoaded" v-show="currentPageIndex === 0" class="pager-image" :src="getImageSrc(1)"/>
        <NoDragImage v-show="currentPageIndex === 1" class="pager-image" :src="getImageSrc(2)"/>
        <NoDragImage v-show="currentPageIndex === 2" class="pager-image" :src="getImageSrc(3)"/>
        <NoDragImage v-show="currentPageIndex === 3" class="pager-image" :src="getImageSrc(4)"/>
        <NoDragImage v-show="currentPageIndex === 4" class="pager-image" :src="getImageSrc(5)"/>
        <NoDragImage v-show="currentPageIndex === 5" class="pager-image" :src="getImageSrc(6)"/>

        <div class="data-item-wrapper">
          <div class="data-item-wrapper-inner">
            <div v-for="(dataItem, i) in filteredDataIds6PagerWithDuplicates" :key="i">
              <div :id="'data-icon-' + dataItem.id"
                   :ref="'dataIcon' + dataItem.id"
                   class="data-item-icon"
                   :class="dataItem.id === currentDataPoint ? 'top-level' : ''"
                   :style="getStyle(dataItem)">
                <Data6PagerButton :data-key="dataItem.dataKey"
                                  :open-left="dataItem.openLeft"
                                  :open-top="dataItem.y > 60 && !dataItem.forceOpenDown"
                                  @click="setActiveDataPoint(dataItem.id)"
                                  @scrollToSaveButton="scrollToSaveDataButton"
                                  :icon-page-id="dataItem.id"
                                  :ref="'dataButton' + dataItem.id"/>
              </div>
            </div>
            <div class="background-click-catcher" @click="closeOpenDataPoint"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import NoDragImage from '../../common/NoDragImage';
import Message from '@/components/common/Message';
import { CONVERSATIONS } from '@/enum/Conversations';
import Data6PagerButton from '@/components/Data6PagerButton/Data6PagerButton';
import { mapState } from 'vuex';
import { UPDATE_TUTORIAL_ASSISTANT_STATUS } from '@/store/mutation-types';
import SideMenu6Pager from '@/components/SideMenu6Pager/SideMenu6Pager';

export default {
  name: 'Document6Pager',
  components: { SideMenu6Pager, Data6PagerButton, Message, NoDragImage },
  props: {
    currentPageIndex: {
      type: Number,
      default: 0,
    },
    isTutorial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMultiPage: true,
      numberOfPages: 6,
      expertComments6Pager: {},
      data6PagerPlacement: [],
      currentDataPoint: null,
    };
  },
  emits: ['scrollTextToPosition', 'setNoScroll'],
  mounted() {
    this.expertComments6Pager = CONVERSATIONS.expertComments6Pager;
    this.data6PagerPlacement = CONVERSATIONS.data6PagerPlacement;


    if (!this.tutorialAssistantMessagesShown.sixPagerIconExplainer && this.currentPageIndex === 0) {
      this.$emit('setNoScroll');

      // this assistant has an inbuilt delay in the startDelay variable.
      this.$root.sendAssistantMessagesByKey('sixPagerInteractiveExplainer');
    }
  },
  computed: {
    ...mapState([
      'tutorialAssistantMessagesShown',
    ]),
    hasTutorialMessage() {
      return this.expertComments6Pager.hasOwnProperty(this.currentPageIndex.toString());
    },
    currentSpeakerName() {
      return this.expertComments6Pager?.[this.currentPageIndex.toString()]?.name || '';
    },
    currentSpeakerMessage() {
      return this.expertComments6Pager?.[this.currentPageIndex.toString()]?.text || '';
    },
    filteredDataIds6PagerWithDuplicates() {
      if (!this.data6PagerPlacement) {
        return [];
      }

      const pageFilteredList = this.data6PagerPlacement.filter((dataId) => dataId.page === this.currentPageIndex);

      return pageFilteredList;
    },
  },
  methods: {
    getImageSrc(index) {
      const images = require.context('../../../assets/pdf/');
      const imgSrc = images('./rvc_Page_' + index + '.jpg');
      return imgSrc;
    },
    onPagerImageLoaded() {
      if (!this.tutorialAssistantMessagesShown.sixPagerIconExplainer && this.currentPageIndex === 0) {
        setTimeout(() => {
          let dataIconRef = this.$refs.dataIcon1[0];
          let dataIconRefY = dataIconRef.getBoundingClientRect().y;

          this.$emit('scrollTextToPosition', dataIconRefY);
          this.$store.commit(UPDATE_TUTORIAL_ASSISTANT_STATUS, 'sixPagerIconExplainer');

        }, 20);
      }
    },
    getStyle(dataItem) {
      return { top: `${dataItem.y}%`, left: `${dataItem.x}%` };
    },
    setActiveDataPoint(dataId) {
      this.closeOpenDataPoint()
      this.currentDataPoint = dataId;
    },
    closeOpenDataPoint() {
      if (this.currentDataPoint) {
        // dynamic refs are returned as arrays
        let dataButton = this.$refs?.[`dataButton${this.currentDataPoint}`]?.[0];
        if (!dataButton) {
          console.log("data button not found");
          return;
        }
        dataButton.closeDataObject();
      }
    },
    highlightDataPoint(dataObject) {
      const { dataKey, dataId } = dataObject;

      // Scroll down to button
      let dataIconRef = this.$refs['dataIcon' + dataId][0];
      let dataIconRefY = dataIconRef.getBoundingClientRect().y;

      this.$emit('scrollTextToPosition', dataIconRefY);

      // highlight button
      let dataButtonRef = this.$refs['dataButton' + dataId][0];
      setTimeout(() => {
        dataButtonRef.highlightButton();
      }, 500)

    },
    scrollToSaveDataButton(dataBreakModuleSaveButtonRefY) {
      this.$emit('scrollTextToPosition', dataBreakModuleSaveButtonRefY);
    }
  },
};
</script>
<style lang="scss" scoped>

$spaceToMenu: 15px;

.document-pager {
  position: relative;
  width: calc(90vw - 260px - #{$spaceToMenu});
  max-width: 1200px;
  height: 90vh;
  padding-bottom: 55px;

  .document-pager-content-wrapper {
    padding-bottom: 55px;

    .document-pager-content-wrapper-inner {
      position: relative;
      height: fit-content;
      margin-bottom: 55px;
      // overflow: hidden;

      .data-item-wrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        .data-item-wrapper-inner {
          // position: relative;
          height: 100%;
          width: 100%;
          overflow: hidden;

          .background-click-catcher {
            position: absolute;
            z-index: 1;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }

          .data-item-icon {
            position: absolute;

            z-index: 2;
            &.top-level {
              z-index: 100;
            }
          }
        }
      }
    }
  }

  .message-wrapper {
    position: fixed;
    z-index: 999999;
    right: -$spaceToMenu;
    top: 0;
    width: 260px;
    height: 100%;
    transform: translate(100%, 0);
  }

  .pager-image {
    position: relative;
    width: 100%;
    img {
      width: 100%;
    }
  }
}

</style>
