export default {
  currentRouterPage(state) {
    return state.currentRouterPage;
  },
  background(state) {
    return state.background;
  },
  isKnowledgeVaultOpen(state) {
    return state.isKnowledgeVaultOpen;
  },
  isGameReplay(state) {
    return state.isGameReplay;
  },
  currentDilemmaId(state) {
    return state.currentDilemmaId;
  },
  currentStakeholderId(state) {
    return state.currentStakeholderId;
  },
  currentDocumentId(state) {
    return state.currentDocumentId;
  },
  studyBriefMenuTabIndex(state) {
    return state.studyBriefMenuTabIndex;
  },
  selectedScenarioIndex(state) {
    return state.selectedScenarioIndex;
  },
  argumentIndexesPerPolicyIndex(state) {
    return state.argumentIndexesPerPolicyIndex;
  },
  currentIntroPageIndex(state) {
    return state.currentIntroPageIndex;
  },
  currentIntroUnlockPageIndex(state) {
    return state.currentIntroUnlockPageIndex;
  },
  currentInformalPDPageIndex(state) {
    return state.currentInformalPDPageIndex;
  },
  idpOpportunityAccepted(state) {
    return state.idpOpportunityAccepted;
  },
  scenario1Seen(state) {
    return state.scenario1Seen;
  },
  scenario2Seen(state) {
    return state.scenario2Seen;
  },
  scenario3Seen(state) {
    return state.scenario3Seen;
  },
  currentEvalAndPDPageIndex(state) {
    return state.currentEvalAndPDPageIndex;
  },
  evidencePointsHarvestData(state) {
    return state.evidencePointsHarvestData;
  },
  evidencePointsTransformData(state) {
    return state.evidencePointsTransformData;
  },
  evidencePointsBossFight(state) {
    return state.evidencePointsBossFight;
  },
  dealAccepted(state) {
    return state.dealAccepted;
  },
  hasPlayedInformalPD(state) {
    return state.hasPlayedInformalPD;
  },
  knowledgeVaultSavedIds(state) {
    return state.knowledgeVaultSavedIds;
  },
  argumentsUsedInBossFight(state) {
    return state.argumentsUsedInBossFight;
  },
  tutorialStepIndex(state) {
    return state.tutorialStepIndex;
  },
  currentDataBreakData(state) {
    return state.currentDataBreakData;
  },
  vca4dDocumentsOrdered(state) {
    return state.vca4dDocumentsOrdered;
  },
  tutorialAssistantMessagesShown(state) {
    return state.tutorialAssistantMessagesShown;
  },
  onBoardingPoints(state) {
    return state.onBoardingPoints;
  },
  dilemmas(state) {
    return state.dilemmas;
  },
  dilemmaUnlockedStatus(state) {
    return state.dilemmaUnlockedStatus;
  },
  dilemmaStakeholderProgressionStatus(state) {
    return state.dilemmaStakeholderProgressionStatus;
  },
  stakeholderOnboardStatus(state) {
    return state.stakeholderOnboardStatus;
  },
  playerName(state) {
    return state.playerName;
  },
  playerSurname(state) {
    return state.playerSurname;
  },
  playerPosition(state) {
    return state.playerPosition;
  },
  playerLocation(state) {
    return state.playerLocation;
  },
  stakeholderDialogueHistories(state) {
    return state.stakeholderDialogueHistories;
  },
};
