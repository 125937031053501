export const VARIABLE_TYPE_HINT = 'hint';
export const VARIABLE_TYPE_ASSISTANT = 'assistant';
export const VARIABLE_TYPE_ASSISTANT_ID = 'assistantId';
export const VARIABLE_TYPE_ON_BOARDING = 'onBoarding';
export const VARIABLE_TYPE_IDP_OPPORTUNITY_ACCEPTED = 'idpOpportunityAccepted';
export const VARIABLE_TYPE_IDP_OPPORTUNITY_DENIED = 'idpOpportunityDenied';
export const VARIABLE_TYPE_SCENARIO_1_SEEN = 'scenario1Seen';
export const VARIABLE_TYPE_SCENARIO_2_SEEN = 'scenario2Seen';
export const VARIABLE_TYPE_SCENARIO_3_SEEN = 'scenario3Seen';
export const VARIABLE_TYPE_SCENARIO_1_NOT_SEEN = 'scenario1NotSeen';
export const VARIABLE_TYPE_SCENARIO_2_NOT_SEEN = 'scenario2NotSeen';
export const VARIABLE_TYPE_SCENARIO_3_NOT_SEEN = 'scenario3NotSeen';
export const VARIABLE_TYPE_SCENARIO_1_SELECTED = 'scenario1Chosen';
export const VARIABLE_TYPE_SCENARIO_2_SELECTED = 'scenario2Chosen';
export const VARIABLE_TYPE_SCENARIO_3_SELECTED = 'scenario3Chosen';
export const VARIABLE_TYPE_CONDITIONAL = 'conditional';
export const VARIABLE_TYPE_SUPER_POINT_LOW = 'superDataPointsLow';
export const VARIABLE_TYPE_SUPER_POINT_HIGH = 'superDataPointsHigh';
export const VARIABLE_TYPE_DEAL_ACCEPTED = 'dealAccepted';
export const VARIABLE_TYPE_ARGUMENT_BOSS_FIGHT_ID = 'argumentBossFightId';
