<template>
  <div class="dialogue-view-wrapper">
    <div>
      <ChoicePopUp ref="choicePopUp" :choice-text="$t('misc.savedEnoughData')" @acceptChoice="goToDilemmaScreen" @cancelChoice="choicePopupClosed"/>
    </div>
    <div>
      <DataBreakPanel class="data-break-wrapper" :data-break-data-keys="currentDataBreakData" :data-break-window-open="dataBreakWindowOpen" @toggle="toggleDataBreakWindow()" />
    </div>
    <div class="persistent-assistant-wrapper">
      <PersistentAssistantButton assistant-message-id="persistentDialogueScreenMessage" />
    </div>
    <div class="content-wrapper" :class="revealClass">
      <DialogueGroup ref="dialogueGroup" style="display: block;" :story-data-key="conversationId"
                     @conversationDone="dialogueCompleted"
      />
    </div>
    <div class="ui-wrapper">
      <div class="complete-button">
        <AppButton v-if="isConversationDone" :size="'large'" :color="'light_grey'" @click="onClickBackButton()">
          {{ endConversationButtonText }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { VIEW_DILEMMA } from '@/enum/Views.js';
import DataBreakPanel from '@/components/DataBreak/DataBreakPanel';
import DialogueGroup from '@/components/DialogGroup/DialogueGroup';
import StakeholderBackgroundData from '@/data/stakeholderBackgroundData.json';
import { clearTimeoutPromise } from '@/utils/promiseUtil';
import PersistentAssistantButton
from '@/components/PersistentAssistantButton/PersistentAssistantButton';
import {
  UPDATE_TUTORIAL_ASSISTANT_STATUS,
  SET_BACKGROUND, SET_POLICY_UNLOCKED_STATUS,
} from '@/store/mutation-types';
import { STATUS_UNLOCKED } from '@/enum/UnlockedStatus';
import ChoicePopUp from '@/components/common/ChoicePopup';
import AppButton from '../components/common/AppButton';

export default {
  name: 'DialogueView',
  components: {
    ChoicePopUp,
    PersistentAssistantButton,
    DataBreakPanel,
    AppButton,
    DialogueGroup,
  },
  data() {
    return {
      currentSpeaker: null,
      currentSpeakerIndex: 0,
      dataBreakWindowOpen: false,
      revealStarted: false,
      currentChoices: [],
      isConversationDone: false,
      getEvidencePointFromDataButton: true,
      minimumSaveDataPercentage: 30,
    };
  },
  watch: {
    'currentSpeaker.id': function () {
      const currentSpeakerId = this.currentSpeaker.id;

      this.currentSpeakerIndex = null;

      for (let i = 0, len = this.dilemmaSources.length; i < len; i++) {
        const source = this.dilemmaSources[i];

        if (source.id === currentSpeakerId) {
          this.currentSpeakerIndex = i;
        }
      }
    },
    currentDataBreakData: {
      handler(newValue, oldValue) {
        this.dataBreakWindowOpen = false;
        this.getEvidencePointFromDataButton = true;

        if (newValue !== null) {
          if (!this.tutorialAssistantMessagesShown.dataBreakExplanation) {
            setTimeout(() => {
              this.$root.sendAssistantMessagesByKey('dataBreakExplanation');
              this.$store.commit(UPDATE_TUTORIAL_ASSISTANT_STATUS, 'dataBreakExplanation');
            }, 450);
          }
        }
      },
      deep: true,
    },
    personArray: {
      handler(newValue, oldValue) {
        for (let i = 0; i < newValue.length; i++) {
          const tempNewValue = newValue[i];
          const tempOldValue = oldValue[i];

          if (tempNewValue !== tempOldValue && tempNewValue >= 100) {
            // unlock policy
            const policyId = i + 1;
            const data = {
              dilemmaId: this.currentDilemmaId,
              policyId,
              newStatus: STATUS_UNLOCKED,
            };

            this.$store.commit(SET_POLICY_UNLOCKED_STATUS, data);
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState([
      'currentDilemmaId',
      'currentStakeholderId',
      'dilemmas',
      'dilemmaStakeholderProgressionStatus',
      'stakeholderOnboardStatus',
      'tutorialAssistantMessagesShown',
      'currentDataBreakData',
      'knowledgeVaultSavedIds',
    ]),
    conversationId() {
      if (!isNaN(parseInt(this.currentDilemmaId, 10))) {
        return 'dilemma';
      }
      return this.currentDilemmaId;
    },
    revealClass() {
      return this.revealStarted ? '' : 'hide-left';
    },
    endConversationButtonText() {
      let buttonText = this.$t('dialogueView.buttonEndConversation');

      if (this.conversationId === 'INTRO2') {
        buttonText = this.$t('dialogueView.buttonRequestStudy');
      }

      return buttonText;
    },
  },
  created() {
    const backgroundId = StakeholderBackgroundData?.[this.currentDilemmaId.toString()]?.[this.currentStakeholderId];

    if (backgroundId) {
      this.$store.commit(SET_BACKGROUND, backgroundId);
    } else {
      this.$store.commit(SET_BACKGROUND, 'room-1');
    }
  },
  mounted() {
    // allow skipping conversation pauses on click
    document.addEventListener('click', this.onDocumentClick);

    // tutorial data
    this.assistantData = require('@/data/assistant/assistantData.json');

    // play reveal group animation
    setTimeout(() => {
      this.revealStarted = true;
    }, 50);

    setTimeout(() => {
      this.$refs.dialogueGroup.showChoiceUI();
    }, 3000);
  },
  unmounted() {
    document.removeEventListener('click', this.onDocumentClick);
  },
  methods: {
    onDocumentClick() {
      clearTimeoutPromise(true);
    },
    dialogueCompleted() {
      this.isConversationDone = true;

      const nonTutorialDilemmaIds = ['1', '2', '3', '4'];
      console.log('this.currentDilemmaId', this.currentDilemmaId, nonTutorialDilemmaIds.includes(this.currentDilemmaId.toString()), nonTutorialDilemmaIds);
      if (nonTutorialDilemmaIds.includes(this.currentDilemmaId.toString())) {
        const data = {
          dilemmaId: this.currentDilemmaId,
          policyId: this.currentStakeholderId + 1,
          newStatus: STATUS_UNLOCKED,
        };

        this.$store.commit(SET_POLICY_UNLOCKED_STATUS, data);
      }
    },
    onClickBackButton() {
      // check if more than 30% of the data is not saved, we show a popup and you stay on the page if you say no.
      if (!this.currentDataBreakData) {
        this.goToDilemmaScreen();
      }
      const total = this.currentDataBreakData.length;
      let savedData = 0;

      for (const i in this.currentDataBreakData) {
        const dataId = this.currentDataBreakData[i];
        if (this.knowledgeVaultSavedIds.includes(dataId)) {
          savedData += 1;
        }
      }

      const percentageSaved = (savedData / total) * 100;

      if (total > 3 && percentageSaved <= this.minimumSaveDataPercentage) {
        this.$refs.choicePopUp.onShowChoice();

        return;
      }

      this.goToDilemmaScreen();
    },
    goToDilemmaScreen() {
      this.$router.push({ name: VIEW_DILEMMA });
    },
    choicePopupClosed() {
    },
    toggleDataBreakWindow() {
      this.dataBreakWindowOpen = !this.dataBreakWindowOpen;
      this.getEvidencePointFromDataButton = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogue-view-wrapper {
  color: #ffffff;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  .ui-wrapper {
    position: relative;
    height: 100%;

    .complete-button{
      position: absolute;
      bottom: 45px;
      left: 50%;
      z-index: 999999;
      transform: translateX(-50%);
    }
  }

  .hide-left {
    transform: translateX(-100%);
  }

  .data-break-wrapper {
    z-index: 9999999;
  }

  .persistent-assistant-wrapper {
    position: absolute;
    top: 16px;
    left: 15px;

    z-index: 999999;
  }

  .content-wrapper {
    position: absolute;
    transition: transform 1.5s cubic-bezier(0.68, -0.25, 0.265, 1.15);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 3;

    .phone-background-wrapper {
      height: 100%;
      left: 0;
      position: absolute;
      img {
        height: 100%;
      }

    }
  }
}

</style>
