<template>
  <button
    :class="buttonClasses"
    class="app-button"
    :disabled="disabled"
    @click.prevent.stop="onClickButton"
  >
    <slot/>
  </button>
</template>
<script>
export const STATE_TRANSITION_UP_SHOW = 'transition-up-show';
export const STATE_TRANSITION_UP_HIDE = 'transition-up-hide';
export const STATE_HIDE_INSTANT = 'hide-instant';
export const STATE_HIDE = 'hide';

export const POSITION_RELATIVE = 'relative';
export const POSITION_FIXED = 'fixed';

export const COLOR_BLUE = 'blue';
export const COLOR_GREEN = 'green';
export const COLOR_WHITE = 'white';
export const COLOR_GREY = 'grey';
export const COLOR_LIGHT_GREY = 'light-grey';
export const COLOR_DATA_GREEN = 'data-green';
export const COLOR_DATA_ORANGE = 'data-orange';
export const COLOR_DATA_BLUE = 'data-blue';
export const COLOR_DATA_LIGHT_BLUE = 'data-light-blue';
export const COLOR_DATA_YELLOW = 'data-yellow';

export const SIZE_SMALL = 'small';
export const SIZE_MEDIUM = 'medium';
export const SIZE_LARGE = 'large';
export const SIZE_FIT = 'fit';

export const TYPE_ICON = 'icon_btn';
export const TYPE_STANDARD = 'standard';

export default {
  name: 'AppButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: POSITION_RELATIVE,
      required: false,
    },
    color: {
      type: String,
      default: COLOR_GREY,
      required: false,
    },
    state: {
      type: String,
      default: null,
      required: false,
    },
    size: {
      type: String,
      default: SIZE_MEDIUM,
      required: false,
    },
    type: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['click'],
  computed: {
    buttonClasses() {
      const buttonClasses = [
        `app-button-state-${this.state || 'none'}`,
        `app-button-color-${this.color || COLOR_GREY}`,
        `app-button-position-${this.position || POSITION_RELATIVE}`,
        `app-button-size-${this.size || SIZE_MEDIUM}`,
        `app-button-type-${this.type || TYPE_STANDARD}`,
      ];

      if (this.disabled) {
        buttonClasses.push('app-button-disabled');
      }

      return buttonClasses;
    },
  },
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

$buttonHeight: 50px;
$borderRadius: 8px;

$colorBlueBg: #45A4CD;
$colorBlueText: #EDF1F4;
$colorBlueShadow: #4d4d4d;

$colorWhiteBg: #EDF1F4;
$colorWhiteText: #063C5A;
$colorWhiteShadow: #4d4d4d;

$colorGreyBg: #686868;
$colorGreyText: #FFFFFF;
$colorGreyShadow: #4d4d4d;

$colorLightGreyBg: #DBDBDB;
$colorLightGreyText: #283F46;
$colorLightGreyShadow: #BEBEBE;

$colorGreenBg: #418185;
$colorGreenText: #ffffff;
$colorGreenShadow: #265F63;

$colorDataOrangeBg: $colorDataTypeSocSus;
$colorDataOrangeText: #ffffff;
$colorDataOrangeShadow: $colorDarkDataTypeSocSus;

$colorDataGreenBg: $colorDataTypeEnvSus;
$colorDataGreenText: #ffffff;
$colorDataGreenShadow: $colorDarkDataTypeEnvSus;

$colorDataBlueBg: $colorDataTypeFuncAna;
$colorDataBlueText: #ffffff;
$colorDataBlueShadow: $colorDarkDataTypeFuncAna;

$colorDataLightBlueBg: $colorDataTypeEcoGrow;
$colorDataLightBlueText: #ffffff;
$colorDataLightBlueShadow: $colorDarkDataTypeEcoGrow;

$colorDataYellowBg: $colorDataTypeInclu;
$colorDataYellowText: #ffffff;
$colorDataYellowShadow: $colorDarkDataTypeInclu;

@mixin appButtonColors($name, $backgroundColor, $textColor, $shadowColor) {
  &.app-button-color-#{$name} {
    background-color: $backgroundColor;
    color: $textColor;

    -webkit-box-shadow: 0 4px 0 0 $shadowColor;
    box-shadow: 0 4px 0 0 $shadowColor;

    &.app-button-disabled {
      opacity: 0.5;
      &:hover {
        background-color: $backgroundColor !important;
        -webkit-box-shadow: 0 4px 0 0 $shadowColor !important;
        box-shadow: 0 4px 0 0 $shadowColor !important;
      }
    }

    &.app-button-position-relative {
      &:hover {
        background-color: lighten($backgroundColor, 7%);
        -webkit-box-shadow: 0 4px 0 0 lighten($shadowColor, 7%);
        box-shadow: 0 4px 0 0 lighten($shadowColor, 7%);
      }

      &:active {
        background-color: darken($backgroundColor, 15%);
        box-shadow: 0 4px 0 0 darken($shadowColor, 15%);
      }
    }
  }
}

.app-button {
  height: $buttonHeight;
  line-height: 100%; // $buttonHeight does not look correct on firefox android
  cursor: pointer;
  outline: 0;
  border: 0;
  font-weight: 600;
  font-family: 'Fira Sans Condensed','Fira Sans', sans-serif;

  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  width: 100%;

  &.app-button-disabled {
    opacity: .8;
    cursor: default;
  }

  &:focus {
    outline: 0;
    border: 0;
  }

  transition: all 800ms cubic-bezier(0.860, 0.000, 0.070, 1.000),
    height 800ms cubic-bezier(0.860, 0.000, 0.070, 1.000),
    color 100ms ease-in-out,
    background-color 100ms ease-in-out,
    box-shadow 100ms ease-in-out;

  // Colors
  @include appButtonColors('blue', $colorBlueBg, $colorBlueText, $colorBlueShadow);
  @include appButtonColors('white', $colorWhiteBg, $colorWhiteText, $colorWhiteShadow);
  @include appButtonColors('grey', $colorGreyBg, $colorGreyText, $colorGreyShadow);
  @include appButtonColors('light_grey', $colorLightGreyBg, $colorLightGreyText, $colorLightGreyShadow);
  @include appButtonColors('green', $colorGreenBg, $colorGreenText, $colorGreenShadow);
  @include appButtonColors('data-green', $colorDataGreenBg, $colorDataGreenText, $colorDataGreenShadow);
  @include appButtonColors('data-orange', $colorDataOrangeBg, $colorDataOrangeText, $colorDataOrangeShadow);
  @include appButtonColors('data-blue', $colorDataBlueBg, $colorDataBlueText, $colorDataBlueShadow);
  @include appButtonColors('data-light-blue', $colorDataLightBlueBg, $colorDataLightBlueText, $colorDataLightBlueShadow);
  @include appButtonColors('data-yellow', $colorDataYellowBg, $colorDataYellowText, $colorDataYellowShadow);

  &.app-button-color-light_grey {
    &.app-button-disabled {
      background-color: $colorGreyBg !important;
      -webkit-box-shadow: 0 4px 0 0 $colorGreyShadow !important;
      color: #7C8F9F !important;
      opacity: .8;
      cursor: default;
      &:hover {
        background-color: $colorGreyBg !important;
        box-shadow: 0 4px 0 0 $colorGreyShadow !important;
      }
    }
  }

  &.app-button-color-green {
    &.app-button-disabled {
      background-color: $colorGreyBg !important;
      -webkit-box-shadow: 0 4px 0 0 $colorGreyShadow !important;
      color: #7C8F9F !important;
      opacity: .8;
      cursor: default;

      &:hover {
        background-color: $colorGreyBg !important;
        box-shadow: 0 4px 0 0 $colorGreyShadow !important;
      }
    }
  }

  // Sizes
  &.app-button-size-small {
    font-size: 12pt;
    width: fit-content;
    height: 35px;
  }

  &.app-button-size-medium {
    font-size: 1.2rem;
    width: 200px;
    min-height: 42px;
    height: fit-content;
  }

  &.app-button-size-large {
    font-size: 1.2rem;
    width: 100%;
    height: 52px;
  }

  &.app-button-size-fit {
    font-size: 1rem;
    width: fit-content;
    height: fit-content;
  }

  &.app-button-size-fill {
    font-size: 1rem;
    width: 100%;
    height: 100%;
    min-height: fit-content;
    margin: 0 0 12px 0 !important;
    padding: 10px !important;
  }

  &.app-button-type-icon_btn {
    font-size: 1.3rem;
    width: 100%;
    height: fit-content;
    padding: 10px 25px 6px 10px !important;
    text-align: left;
    font-weight: 400;
    margin: 5px -5px !important;
  }

  // Positions
  &.app-button-position-relative {
    position: relative;
    border-radius: $borderRadius;
    padding: 5px 10px;
    max-width: 500px;
    margin: 5px;
  }

  &.app-button-position-fixed {
    padding: 0;
    position: fixed;
    bottom: -$buttonHeight;
    left: 0;
    border-radius: $borderRadius $borderRadius 0 0;

    z-index: 100000;
  }

  // States - NOTE: states are only relevant for fixed buttons
  &.app-button-position-fixed {
    &.app-button-state-peak {
      bottom: 0;
    }

    &.app-button-state-transition-up-show {
      color: transparent !important;
      cursor: default;
      height: 100vh;
      bottom: 0;
      border-radius: 0 0 0 0;
    }

    &.app-button-state-hide-instant {
      transition-duration: 0ms;
      bottom: -$buttonHeight;
    }

    &.app-button-state-hide {
      bottom: -$buttonHeight;
    }

    &.app-button-state-transition-up-hide {
      color: transparent !important;
      cursor: default;
      height: 0;
      top: 0;
      border-radius: 0 0 8px 8px;
    }
  }
}
</style>
