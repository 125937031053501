<template>
  <div class="notification-icon-wrapper">
    <div class="notification-icon">
      <slot/>
    </div>
    <div class="notification-icon-pulse">
      <div class="ping ping-container"/>
    </div>
  </div>
</template>
<script>

export default {
  name: 'NotificationIcon',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-icon-wrapper {
  position: relative;
  width: 50px;
  height: 50px;
  pointer-events: none;

  .notification-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0 12px;
    border-radius: 50%;
    background: #FF8A45;
    color: white;
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  .notification-icon-pulse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 2;
    .ping-container {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #DD8A45;
    }
  }
}

</style>
