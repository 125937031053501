export default {
  midTermEvaluation: [
    {
      title: 'midtermEvaluation.valueChainTitle',
      icon: 'icon_valuechainunderstanding',
      data: [
        {
          text: 'midtermEvaluation.valueChainText1',
          requirements: [
            {
              requirementType: 'stakeholdersMet',
              operator: '=',
              requirement: 12,
            },
            {
              requirementType: 'evidencePointsHarvestData',
              operator: '>',
              requirement: 11,
            },
          ],
        },
        {
          text: 'midtermEvaluation.valueChainText2',
          requirements: [
            {
              requirementType: 'stakeholdersMet',
              operator: '>',
              requirement: 7,
            },
            {
              requirementType: 'evidencePointsHarvestData',
              operator: '>',
              requirement: 7,
            },
          ],
        },
        {
          text: 'midtermEvaluation.valueChainText3',
          requirements: [],
        },
      ],
    },
    {
      title: 'midtermEvaluation.onboardingTitle',
      icon: 'icon_onboarding',
      data: [
        {
          text: 'midtermEvaluation.onboardingText1',
          requirements: [
            {
              requirementType: 'stakeholderPoints',
              operator: '>',
              requirement: 9,
            },
          ],
        },
        {
          text: 'midtermEvaluation.onboardingText2',
          requirements: [
            {
              requirementType: 'stakeholderPoints',
              operator: '>',
              requirement: 5,
            },
          ],
        },
        {
          text: 'midtermEvaluation.onboardingText3',
          requirements: [],
        },
      ],
    },
  ],
};
