import axios from 'axios';
import { saveAs } from 'file-saver';
import resolve from 'resolve';

export default function createPdf(data) {
  const pdfUsername = 'agrinatura';
  const pdfPassword = '/e$ONXF!(rLfmJe,^+-&3J!*3D0Fl(+F4/W&_R=}ij(@m=.n_APn}.bPK`}3x9we';
  const token = Buffer.from(`${pdfUsername}:${pdfPassword}`, 'utf8').toString('base64');
  data.devDebug = 1;
  const options = {
    headers: {
      'Authorization': `Basic ${token}`
    },
  };
  return axios.post('https://pdf.seriousgames.net/convert', data, options).then((response) => {
    if (response.status !== 200) {
      throw new Error(`PDF converter received a non 200 OK response (${response.status}`);
    }
    const responseData = response.data;
    if (!responseData.success) {
      let errorMessage = responseData.error || 'unknown';
      throw new Error(`PDF converter received a non-successful response (${errorMessage}`);
    }

    const responseFilteredData = {
      url: responseData.url,
      deleteUrl: responseData.deleteUrl,
    };
    return responseFilteredData;
  });
}

export function deleteCreatedPdf(data) {
  return axios.post(data.deleteUrl, {}, {}).then((response) => {
    if (response.status !== 200) {
      throw new Error(`PDF was not deleted (${response.status}`);
    }
    const responseData = response.data;
    if (!responseData.success) {
      let errorMessage = responseData.error || 'unknown';
      throw new Error(`PDF converter received a non-successful response (${errorMessage}`);
    }

    console.log("Return data from the delete pdf method", responseData);

    return true;
  });
}

export function createAndSavePdf(data) {
  return new Promise((resolve, reject) => {
    createPdf(data).then((pdfUrlData) => {
      const deleteData = {
        deleteUrl: pdfUrlData.deleteUrl,
      };
      //resolve(pdfUrl, false);
      //return;
      let savedFile = false;
      try {
        const isFileSaverSupported = !!new Blob;
        if (isFileSaverSupported) {
          saveAs(pdfUrlData.url);
          savedFile = true;
          resolve(pdfUrlData.url, true);
        }
        this.deleteCreatedPdf(deleteData).then();
      } catch (e) {
        console.log('Could not call FileSaver.saveAs', e);
      }
      if (!savedFile) {
        resolve(pdfUrlData.url, false);
        this.deleteCreatedPdf(deleteData).then();
      }
    });
  });
}

export function saveStaticPdf(pdfName) {
  let savedFile = false;

  try {
    const isFileSaverSupported = !!new Blob();
    if (isFileSaverSupported) {
      saveAs(pdfName);
      savedFile = true;
      resolve(pdfName, true);
    }
  } catch (e) {
    console.log('Could not call FileSaver.saveAs', e);
  }
  if (!savedFile) {
    resolve(pdfName, false);
  }
}
