let promiseTimeout;
let promiseResolve;

export function clearTimeoutPromise(callResolve = false) {
  if (promiseTimeout) {
    clearTimeout(promiseTimeout);

    promiseTimeout = null;
  }

  if (callResolve && promiseResolve) {
    promiseResolve();
  }

  promiseResolve = null;
}

export function timeoutPromise(timeout, clearTimeout = true) {
  return new Promise((resolve) => {
    if (clearTimeout) {
      clearTimeoutPromise(false);
    }

    promiseResolve = resolve;

    promiseTimeout = setTimeout(() => {
      if (clearTimeout) {
        clearTimeoutPromise(false);
      }

      resolve();
    }, timeout);
  });
}
