<template>
  <div class="save-button-wrapper">
    <button class="save-button" :disabled="disabled" @click="onClickButton" />
  </div>
</template>
<script>

export default {
  name: 'PersistentAssistantButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    assistantMessageId: {
      type: String,
      default: '',
    },
  },
  emits: ['argument-added'],
  methods: {
    onClickButton() {
      if (this.assistantMessageId === '') {
        console.error('PersistentAssistantButton: assistantMessageId empty');
        return;
      }
      this.$root.sendAssistantMessagesByKey(this.assistantMessageId);
    },
  },
};
</script>
<style lang="scss" scoped>

$saveButtonSize: 40px;
$boxShadowOffset: -3px;

.save-button-wrapper {
  position: relative;
  width: $saveButtonSize;
  height: $saveButtonSize;

  -webkit-filter: drop-shadow(0px 0px 7px rgba(255,255,255,0.5));

  .save-button {
    border: none;
    height: $saveButtonSize;
    width: $saveButtonSize;
    border-radius: 50%;
    -webkit-box-shadow: inset 0 $boxShadowOffset 0 #2C4A57;
    box-shadow: inset 0 $boxShadowOffset 0 #2C4A57;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-image: url("./../../assets/img/assistant/assistant-button.png");
    cursor: pointer;

    &:hover {
      background-color: #535353;
      filter: brightness(0.9);
      box-shadow: inset 0 $boxShadowOffset 0 #2d2d2d;
    }

    &:active {
      filter: brightness(0.7);
      box-shadow: inset 0 0 0 #4C6A77;
    }

    &:disabled {
      opacity: .6;
      cursor: not-allowed;
      box-shadow: none !important;
    }
  }
}

</style>
