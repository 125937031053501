<template>
  <div className="dynamic-text-view-container">
    <div class="content-wrapper">
      <div class="title-wrapper">
        <p>{{ $t(title) }}</p>
      </div>
      <div v-if="textHtml !== ''" class="text-wrapper">
        <p v-html="textHtml" />
      </div>
    </div>
    <div class="dynamic-text-object-wrapper d-flex justify-content-between flex-wrap">
      <div class="dynamic-text-object-item" v-for="(dynamicTextItem, i) in dynamicTextObject" :key="i">
        <div class="item-icon-wrapper">
          <NoDragImage class="dynamic-text-item-image" :src="getImageSrc(dynamicTextItem.icon)"/>
        </div>
        <div class="item-title-wrapper">
          <p>{{$t(dynamicTextItem.title)}}</p>
        </div>
        <div class="item-text-wrapper">
          <p v-html="parseRequirementObject(dynamicTextItem.data)"></p>
        </div>
      </div>
    </div>
    <div className="text-wrapper">
      <!-- <div class="icon-wrapper" v-if="icon">
        <NoDragImage class="news-item-image" :src="getImageSrc(icon)"/>
      </div>
      <p v-html="textHtml"/>-->
    </div>
  </div>
</template>

<script>

import textToHtml from '@/utils/textToHtml';
import NoDragImage from '@/components/common/NoDragImage';
import DynamicTextObjects from '@/data/dynamicTextObjects.js';
import { mapState } from 'vuex';
import {
  REQUIREMENT_TYPE_STAKEHOLDER_POINTS, REQUIREMENT_TYPE_STAKEHOLDERS_MET,
  REQUIREMENT_TYPE_TIME_POINTS, REQUIREMENT_TYPE_EVIDENCE_HARVEST_POINTS
} from '@/enum/RequirementType';

export default {
  name: 'DynamicTextView',
  components: { NoDragImage },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState([
      'stakeholderOnboardStatus',
      'evidencePointsHarvestData',
      'evidencePointsTransformData',
      'evidencePointsBossFight',
    ]),
    title() {
      return this.data?.title;
    },
    text() {
      return this.data?.text;
    },
    textHtml() {
      if (!this.text || this.text === '') {
        return '';
      }
      return textToHtml(this.$t(this.text), this.$t);
    },
    dynamicDataID() {
      return this.data?.dynamicDataID;
    },
    dynamicTextObject() {
      if (!this.dynamicDataID || !DynamicTextObjects.hasOwnProperty(this.dynamicDataID)) {
        return [];
      }
      return DynamicTextObjects[this.dynamicDataID];
    },
    accumulatedStakeholderPoints() {
      let accumulatedStakeholderPoints = 0;

      for (const stakeholder in this.stakeholderOnboardStatus) {
        if (this.stakeholderOnboardStatus?.[stakeholder]) {
          accumulatedStakeholderPoints += this.stakeholderOnboardStatus[stakeholder];
        }
      }

      return accumulatedStakeholderPoints;
    },
    accumulatedEvidencePoints() {
      const accumulatedEvidencePoints = this.evidencePointsBossFight + this.evidencePointsHarvestData + this.evidencePointsTransformData;
      return accumulatedEvidencePoints;
    },
    dilemmaStakeholdersMet() {
      // TODO THESE ARE MADE FROM STATIC NUMBERS WHEN THEY REALLY SHOULDN'T BE
      // ONLY THESE SPECIFIC PEOPLE ARE STAKEHOLDERS
      const dilemmaStakeholderId = ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'];
      let dilemmaStakeholdersMet = 0;

      for (const stakeholder in this.stakeholderOnboardStatus) {
        if (dilemmaStakeholderId.includes(stakeholder)) {
          dilemmaStakeholdersMet += 1;
        }
      }

      return dilemmaStakeholdersMet;
    },
  },
  methods: {
    getImageSrc(pictureId) {
      const images = require.context('../../assets/img/ui');
      const imgSrc = images(`./${pictureId}.png`);
      return imgSrc;
    },
    parseRequirementObject(requirementDataArray) {
      let requirementText = '';

      for (const requirementDataItem of requirementDataArray) {
        const requirementArray = requirementDataItem?.requirements || [];
        const text = requirementDataItem?.text || '';
        let playerMeetsRequirements = true;

        for (const requirementItem of requirementArray) {
          const { requirementType, operator, requirement } = requirementItem;
          if (requirementType === REQUIREMENT_TYPE_STAKEHOLDER_POINTS) {
            if (!this.fulfillRequirement(this.accumulatedStakeholderPoints, operator, requirement)) {
              playerMeetsRequirements = false;
              break;
            }
          } else if (requirementType === REQUIREMENT_TYPE_EVIDENCE_HARVEST_POINTS) {
            if (!this.fulfillRequirement(this.accumulatedEvidencePoints, operator, requirement)) {
              playerMeetsRequirements = false;
              break;
            }
          } else if (requirementType === REQUIREMENT_TYPE_STAKEHOLDERS_MET) {
            if (!this.fulfillRequirement(this.dilemmaStakeholdersMet, operator, requirement)) {
              playerMeetsRequirements = false;
              break;
            }
          } else {
            playerMeetsRequirements = false;
            break;
          }
        }

        if (playerMeetsRequirements) {
          requirementText = text;
          break;
        }
      }

      return textToHtml(this.$t(requirementText), this.$t);
    },
    fulfillRequirement(playerValue, operator, requirement) {
      if (operator === '=') {
        return playerValue === requirement;
      }
      if (operator === '>') {
        return playerValue > requirement;
      }
      if (operator === '<') {
        return playerValue < requirement;
      }
      return false;
    }
  },
};
</script>

<style lang="scss" scoped>
.dynamic-text-view-container {
  color: #ffffff;
  font-weight: 700;

  .content-wrapper {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    overflow-y: auto;
    padding: 0 55px;
    max-height: calc(100vh - 250px);

    .title-wrapper {
      text-align: center;
      font-size: 22px;
      margin-bottom: 45px;
    }

    .text-wrapper {
      text-align: center;
    }
  }

  .dynamic-text-object-wrapper {
    width: 85%;
    margin-right: auto;
    margin-left: auto;

    .dynamic-text-object-item {
      width: 32vw;

      .item-title-wrapper {
        font-size: 18px;
        text-align: center;
      }

      .item-text-wrapper {
        font-weight: 400;
        font-size: 16px;
      }

      .item-icon-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .dynamic-text-item-image {
          margin: 25px;
          width: 40%;
        }
      }
    }
  }
}

</style>
