<template>
  <template>
    <div class="dialogue-group-wrapper">
      <div class="stakeholder-group-container" :class="isBossFight ? 'boss-fight' : ''">
        <StakeholderGroup v-if="dilemmaSources" :current-stakeholder-index="currentSpeakerIndex" :stakeholders="dilemmaSources" />
      </div>

      <div class="message-outer-wrapper">
        <div ref="messageWrapper" class="message-wrapper" :class="visibleClass">
          <div v-for="(historyMessage, i) in formattedHistory" :key="i" class="single-message">
            <Message
              v-if="currentSpeaker && lastStoryMessage && historyMessage.text !== '' && historyMessage.text !== '{EMPTY}' && historyMessage.text !== '{EMPTYNOSPACE}'"
              :type="historyMessage.type"
              :message-id="lastStoryMessage.id"
              :speaker-name="historyMessage.speakerName"
              :speaker-role="historyMessage.speakerRole"
              :message="historyMessage.text"
            />
            <div v-if="historyMessage.text === '' || (historyMessage.text === '{EMPTY}' && historyMessage.type === 1)" class="message-space" />
          </div>
        </div>

        <div v-if="showAnticipationDots" class="anticipation-dots-wrapper">
          <MessageAnticipationDots/>
        </div>
      </div>

      <div id="story-choices" v-if="currentChoices.length && !shouldUseArgument" class="choice-button-wrapper" :class="visibleClass">
        <StoryChoices :choices="currentChoicesFormatted" :is-replay-choice-for-replay-conversation="isReplayChoiceForReplayDialogue" @pickChoice="onPickChoice" />
      </div>
      <div>
        <UseArgumentPanel :use-argument-panel-open="shouldUseArgument" @acceptChosenArgument="acceptChosenArgument"/>
      </div>
    </div>
  </template>
</template>

<script>
import StakeholderGroup from '@/components/Stakeholder/StakeholderGroup';
import ChoiceButtonGroup from '@/components/ChoiceButton/ChoiceButtonGroup';
import Message from '@/components/common/Message';
import BaseStory from '@/components/StoryDialogue/BaseStory';
import { STATUS_UNLOCKED } from '@/enum/UnlockedStatus';
import { CONVERSATIONS } from '@/enum/Conversations';
import { STORY_MESSAGE_SPEAKER_THEM } from '@/components/StoryDialogue/enum/StoryMessageSpeaker';
import {
  SET_CURRENT_DATA_BREAK_DATA,
  UPDATE_ONBOARDING_STATUS,
  SET_IDP_OPPORTUNITY_ACCEPTED,
  SET_VARIABLE_TYPE_SCENARIO_1_SEEN,
  SET_VARIABLE_TYPE_SCENARIO_2_SEEN,
  SET_VARIABLE_TYPE_SCENARIO_3_SEEN,
  SET_DEAL_ACCEPTED, USE_ARGUMENT,
  SET_STAKEHOLDER_DIALOGUE_HISTORY, ADD_EVIDENCE_BOSS_FIGHT_POINTS,
} from '@/store/mutation-types';
import { mapState } from 'vuex';
import {
  VARIABLE_TYPE_HINT,
  VARIABLE_TYPE_ASSISTANT,
  VARIABLE_TYPE_ASSISTANT_ID,
  VARIABLE_TYPE_IDP_OPPORTUNITY_ACCEPTED,
  VARIABLE_TYPE_ON_BOARDING,
  VARIABLE_TYPE_SCENARIO_1_SEEN,
  VARIABLE_TYPE_SCENARIO_2_SEEN,
  VARIABLE_TYPE_SCENARIO_3_SEEN,
  VARIABLE_TYPE_DEAL_ACCEPTED,
} from '@/enum/VariableType';
import { ASSISTANT_TYPE_BANNER_INSTANT, ASSISTANT_TYPE_BANNER } from '../../enum/AssistantTypes';
import UseArgumentPanel from '@/components/ChooseArgument/UseArgumentPanel';
import { VIEW_DIALOGUE } from '../../enum/Views';
import MessageAnticipationDots from '../common/MessageAnticipationDots';

export default {
  name: 'DialogueGroup',
  components: {
    MessageAnticipationDots,
    UseArgumentPanel,
    StakeholderGroup,
    Message,
  },
  extends: BaseStory,
  props: {
    storyDataKey: {
      type: String,
      required: true,
    },
    isBossFight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['conversationDone'],
  data() {
    return {
      currentSpeaker: null,
      currentSpeakerIndex: 0,
      currentChoices: [],
      showMessageAndChoices: false,
      dataBreakData: {},
      assistantData: {},
    };
  },
  watch: {
    'formattedHistory.length': function () {
      if (this.savedStakeholderDialogueHistory.length < this.storyMessageHistory.length && this.isReplayDialogue) {
        const dialogueHistoryData = {
          dilemmaId: this.currentDilemmaId,
          stakeholderId: JSON.stringify(this.currentStakeholderId),
          dialogueHistory: JSON.parse(JSON.stringify(this.storyMessageHistory)),
        };
        this.$store.commit(SET_STAKEHOLDER_DIALOGUE_HISTORY, dialogueHistoryData);
      }

      setTimeout(() => {
        if (this.$refs.messageWrapper?.scrollHeight) {
          this.$refs.messageWrapper.scrollTop = this.$refs.messageWrapper.scrollHeight;
        }
      }, 150);
    },
    'currentSpeaker.id': function () {
      const currentSpeakerId = this.currentSpeaker.id;

      this.updateOnboarding(this.currentSpeaker.id, 0);

      this.currentSpeakerIndex = null;

      for (let i = 0, len = this.dilemmaSources.length; i < len; i++) {
        const source = this.dilemmaSources[i];

        if (source.id === currentSpeakerId) {
          this.currentSpeakerIndex = i;
        }
      }
    },
    currentData: {
      handler(newValue, oldValue) {
        this.$store.commit(SET_CURRENT_DATA_BREAK_DATA, newValue);
      },
      deep: true,
    },
    /* 'currentChoices.length': function () {
      console.log("current choices. length", this.currentChoices.length);
      console.log("currentMessageIndex", this.currentMessageIndex, this.storySource.length, this.currentSpeakerIndex);
      console.log("tstory", this, this.storySource.length, this.chosenChoiceIndex !== null);
      if (this.currentChoices.length === 0 && this.storySourceHasLoaded) {
        setTimeout(() => {
          if(this.currentChoices.length === 0) {
            // this.$emit('conversationDone');
          }
        }, 250);
      }
    }, */
    personArray: {
      handler(newValue, oldValue) {
        for (let i = 0; i < newValue.length; i++) {
          const tempNewValue = newValue[i];
          const tempOldValue = oldValue[i];

          if (tempNewValue !== tempOldValue && tempNewValue >= 100) {
            // unlock policy
            const policyId = i + 1;
            const data = {
              dilemmaId: this.currentDilemmaId,
              policyId,
              newStatus: STATUS_UNLOCKED,
            };

            // this.$store.commit(SET_POLICY_UNLOCKED_STATUS, data);
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState([
      'currentDilemmaId',
      'currentStakeholderId',
      'dilemmas',
      'dilemmaStakeholderProgressionStatus',
      'stakeholderOnboardStatus',
      'stakeholderDialogueHistories'
    ]),
    visibleClass() {
      return this.showMessageAndChoices ? 'ui-visible' : 'ui-invisible';
    },
    isReplayChoiceForReplayDialogue() {
      if (!this.isReplayDialogue) {
        return false;
      }

      if (this.savedStakeholderDialogueHistory.length > this.storyMessageHistory.length) {
        return true;
      }

      return false;
    },
    savedStakeholderDialogueHistory() {
      const savedStakeholderDialogueHistory = this.stakeholderDialogueHistories?.[this.currentDilemmaId]?.[JSON.stringify(this.currentStakeholderId)];
      return savedStakeholderDialogueHistory || [];
    },
    personArray() {
      const personArray = [];
      for (let i = 0; i < 3; i++) {
        // personArray.push(this.stakeholderOnboardStatus[(this.currentDilemmaId).toString()].person[i].onBoardingBercentage);
        personArray.push(0);
      }
      return personArray;
    },
    dilemmaSources() {
      if (!this.storySource) {
        return [];
      }

      const { speakers } = this.storySource;
      const dilemmaSources = [];
      for (const speaker of speakers) {
        dilemmaSources.push({
          pictureId: speaker.pictureId,
          name: speaker.name,
          role: speaker.role,
          id: speaker.id,
        });
      }
      return dilemmaSources;
    },
    dilemma() {
      for (const dilemma of this.dilemmas) {
        if (dilemma.dilemma === this.currentDilemmaId) {
          return dilemma;
        }
      }

      return null;
    },
    startCurrentStakeholderProgress() {
      // const currentIndex = this.dilemmaStakeholderProgressionStatus[this.currentDilemmaId].person[this.currentStakeholderId];
      // return currentIndex;
    },
    startCurrentMessageIndex() {
      // const { currentIndex } = this.dilemmaStakeholderProgressionStatus[this.currentDilemmaId].person[this.currentStakeholderId];
      // return currentIndex;
    },
    startCurrentChoiceIndex() {
      // console.log('in the startCurrentChoiceIndex method');
      // const { currentChoiceIndex } = this.dilemmaStakeholderProgressionStatus[this.currentDilemmaId].person[this.currentStakeholderId];
      // return currentChoiceIndex;
      return 0;
    },
    startStoryMessageHistory() {
      // const { storyMessageHistory } = this.dilemmaStakeholderProgressionStatus[this.currentDilemmaId].person[this.currentStakeholderId];
      // return storyMessageHistory;
      return [];
    },
    startIsCurrentKnowledgeVaultItemSaved() {
      // const { isCurrentKnowledgeVaultItemSaved } = this.dilemmaStakeholderProgressionStatus[this.currentDilemmaId].person[this.currentStakeholderId];
      // return isCurrentKnowledgeVaultItemSaved;
      return false;
    },
    formattedHistory() {
      const formattedHistory = [];
      for (const message of this.storyMessageHistory) {
        if (message.btnText === '{KEEP_LISTENING}' || message.btnText === 'misc.neutral') {
          formattedHistory.push({
            text: '',
            type: message.speaker === STORY_MESSAGE_SPEAKER_THEM ? 0 : 1,
            speakerName: '',
            speakerRole: '',
          });
          continue;
        }
        formattedHistory.push({
          text: message.text !== '' ? message.text : message.btnText,
          type: message.speaker === STORY_MESSAGE_SPEAKER_THEM ? 0 : 1,
          speakerName: message.speakerInfo?.name ? message.speakerInfo.name : '',
          speakerRole: message.speakerInfo?.role ? message.speakerInfo.role : '',
        });
      }

      return formattedHistory;
    },
    isReplayDialogue() {
      const nonTutorialDilemmaIds = ['1', '2', '3', '4'];
      return this.$route.name === VIEW_DIALOGUE && nonTutorialDilemmaIds.includes(this.currentDilemmaId.toString());
    },
    currentChoicesFormatted() {
      const currentChoicesFormatted = [];

      const currentHistoryProgression = this.storyMessageHistory.length;

      const usedChoiceIndexFromHistory = this.savedStakeholderDialogueHistory?.[currentHistoryProgression]?.choicePickedIndex;
      const usedChoiceTextFromHistory = this.savedStakeholderDialogueHistory?.[currentHistoryProgression]?.text || '';

      if (!this.isReplayDialogue) {
        return this.currentChoices;
      }

      let indexCounter = 0;
      for (const choiceButton of this.currentChoices) {
        const tempChoiceButton = JSON.parse(JSON.stringify(choiceButton));

        let currentChoiceDisabled = false;
        /* if (this.savedStakeholderDialogueHistory.length < currentHistoryProgression) {
          currentChoicesDisabled.push(false);
          continue;
        } */

        if (usedChoiceIndexFromHistory !== null && usedChoiceIndexFromHistory !== undefined && usedChoiceIndexFromHistory !== indexCounter) {
          currentChoiceDisabled = true;
        }

        // this is just a check
        if (usedChoiceIndexFromHistory && usedChoiceIndexFromHistory === indexCounter && usedChoiceTextFromHistory !== choiceButton.text) {
          console.error(`Text of current choice button is not the same as text from history chosen choise button. Current choice text: "${choiceButton.text}". Text from history: "${usedChoiceTextFromHistory}"`);
        }

        // currentChoicesDisabled.push(false);
        tempChoiceButton.disabled = currentChoiceDisabled;
        currentChoicesFormatted.push(tempChoiceButton);
        indexCounter += 1;
      }

      return currentChoicesFormatted;
    },
  },
  mounted() {
    this.assistantData = require('@/data/assistant/assistantData.json');

    if (this.storyDataKey === 'dilemma') {
      this.storySource = CONVERSATIONS.dilemmas[this.currentDilemmaId].stakeholders[this.currentStakeholderId];
      return;
    }
    if (!CONVERSATIONS.hasOwnProperty(this.storyDataKey)) {
      console.log('invalid story data key');
    }
    this.storySource = CONVERSATIONS[this.storyDataKey];
    this.dataBreakData = CONVERSATIONS.dataBreakData;
    // this.startCurrentMessageIndex = this.startCurrentMessageIndex;
    // this.$store.commit(SET_BACKGROUND, 'room-1');
  },
  methods: {
    showChoiceUI() {
      this.showMessageAndChoices = true;
    },
    acceptChosenArgument(dataKey) {
      // console.log("Accept the argument", dataKey);
      const argumentData = {
        dataKey: dataKey,
        argumentBossFightId: this.currentArgumentId.toString(),
      };

      this.$store.commit(USE_ARGUMENT, argumentData);
      this.getPointsForUsedArgument(dataKey);

      this.shouldUseArgument = false;

      this.onPickChoice(0, dataKey);
    },

    getPointsForUsedArgument(argumentDataId) {
      const argumentScenarioSupport = this.dataBreakData[argumentDataId].scenarioSupport[this.selectedScenarioIndex];
      const { isSupported, isSuperData } = argumentScenarioSupport;

      if (isSuperData) {
        this.$store.commit(ADD_EVIDENCE_BOSS_FIGHT_POINTS, 5);
      } else if (isSupported) {
        this.$store.commit(ADD_EVIDENCE_BOSS_FIGHT_POINTS, 1);
      }
    },
    processVariablesInChoice(choicePickedVariables) {
      for (const variableObject of choicePickedVariables) {

        const variable = variableObject?.variable;
        const value = variableObject?.value;
        const operator = variableObject?.operator;

        // value is allowed to be false
        if (!variable || value === null || value === undefined || !operator) {
          return;
        }

        if (variable === VARIABLE_TYPE_HINT && value !== '' && value !== ' ') {
          // send assistant hint
          const assistantMessage = {
            type: ASSISTANT_TYPE_BANNER_INSTANT,
            messages: [
              {
                message: value,
                mood: 'neutral',
              },
            ],
          };
          this.$root.sendAssistantMessages(assistantMessage);
        } else if (variable === VARIABLE_TYPE_ASSISTANT && value !== '' && value !== ' ') {
          // send assistant message
          const assistantMessage = {
            type: ASSISTANT_TYPE_BANNER,
            messages: [
              {
                message: value,
                mood: 'neutral',
              },
            ],
          };
          this.$root.sendAssistantMessages(assistantMessage);
        } else if (variable === VARIABLE_TYPE_ASSISTANT_ID) {
          this.$root.sendAssistantMessages(this.assistantData[value]);
        } else if (variable === VARIABLE_TYPE_ON_BOARDING) {
          // skip this if we're replaying a conversation
          if (this.isReplayChoiceForReplayDialogue) {
            continue;
          }
          const parsedValue = parseInt(value, 10);
          this.updateOnboarding(this.currentSpeaker.id, parsedValue);
        } else if (variable === VARIABLE_TYPE_IDP_OPPORTUNITY_ACCEPTED) {
          this.$store.commit(SET_IDP_OPPORTUNITY_ACCEPTED, JSON.parse(value));
          // analytics
          this.$root.callInformalEvent(JSON.parse(value));
        } else if (variable === VARIABLE_TYPE_SCENARIO_1_SEEN) {
          this.$store.commit(SET_VARIABLE_TYPE_SCENARIO_1_SEEN, JSON.parse(value));
        } else if (variable === VARIABLE_TYPE_SCENARIO_2_SEEN) {
          this.$store.commit(SET_VARIABLE_TYPE_SCENARIO_2_SEEN, JSON.parse(value));
        } else if (variable === VARIABLE_TYPE_SCENARIO_3_SEEN) {
          this.$store.commit(SET_VARIABLE_TYPE_SCENARIO_3_SEEN, JSON.parse(value));
        } else if (variable === VARIABLE_TYPE_DEAL_ACCEPTED) {
          this.$store.commit(SET_DEAL_ACCEPTED, JSON.parse(value));
        }
      }
    },
    updateOnboarding(speakerId, valueChange) {
      if (this.currentDilemmaId === 'INTRO2') {
        speakerId = 2;
      }
      const data = {
        stakeholderIndex: speakerId,
        stakeholderProgression: valueChange,
      };

      this.$store.commit(UPDATE_ONBOARDING_STATUS, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogue-group-wrapper {
  color: #ffffff;
  height: 100%;
  overflow-y: hidden;

  .stakeholder-group-container {
    max-width: 40%;
    height: 130vh;
    width: 100%;
    margin-left: 130px;
    margin-top: 120px;

    &.boss-fight {
      max-width: 80% !important;
      height: 230vh !important;
      width: 100% !important;
      margin-left: 2% !important;
      margin-top: 5% !important;
    }
  }
  .message-outer-wrapper {
    position: absolute;
    width: 40%;
    height: 60%;
    right: 22%;
    bottom: 40%;
    max-height: 60%;

    z-index: 5;

    .message-wrapper {
      scroll-behavior: smooth;
      padding-top: 65px;
      padding-right: 25px;
      padding-left: 25px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      height: fit-content;
      width: 100%;
      max-height: 100%;
      right: 0%;
      bottom: 0%;
      transform: translateY(-0%);
      transition: opacity 300ms ease-in-out;

      &::-webkit-scrollbar {
        width: 0.5em;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: lightgray;
        outline: 1px solid slategrey;
      }

      // my idea was to make them harmonica like - so they ease in.
      .single-message {
        transition: height 300ms ease-in-out;
      }

      /*.ui-visible {
        opacity: 1;
      }

      .ui-invisible {
        opacity: 0;
      }*/
    }
  }

  .choice-button-wrapper {
    width: 615px;
    position: absolute;
    bottom: 50px;
    left: 55%;
    transform: translateX(-50%);
    z-index: 5;
    transition: opacity 300ms ease-in-out;
  }

  .message-space {
    // height: 35px;
  }

  .ui-visible {
    opacity: 1;
  }

  .ui-invisible {
    opacity: 0;
  }

  .anticipation-dots-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% + 10px));
  }
}

</style>
