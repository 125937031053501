<template>
  <div class="message-component-wrapper" :class="isUserMessage ? 'message-component-wrapper-user' : ''">
    <div v-if="isSpeakerMessage && hasSpeaker" class="stakeholder-info-wrapper">
      <p class="stakeholder-info-wrapper-name">{{ $t(speakerName) }}</p>
      <p class="stakeholder-info-wrapper-role">{{ $t(speakerRole) }}</p>
    </div>
    <div v-if="isUserMessage" class="stakeholder-info-wrapper user-info">
      <p class="stakeholder-info-wrapper-name">{{ playerDisplayName }}</p>
      <p class="stakeholder-info-wrapper-role">{{ $t(displayPlayerPosition) }}</p>
    </div>
    <div class="message-outer-wrapper">
      <div v-if="isUserMessage" class="user-message-component-pointer"/>
      <div v-if="isSpeakerMessage" class="speaker-message-component-pointer"/>
      <div class="message-wrapper" :class="messageClass" v-html="messageHtml">
      </div>
    </div>
  </div>
</template>
<script>

import textToHtml from '@/utils/textToHtml';
import { mapState } from 'vuex';
import { USER_POSITION_EU_POLICY_OFFICER } from '@/enum/userEnums/UserPosition';

export default {
  name: 'Message',
  props: {
    messageId: {
      type: Number,
      default: -1,
    },
    speakerName: {
      type: String,
      default: '',
    },
    speakerRole: {
      type: String,
      default: '',
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    type: {
      // 0 = speaker message, 1 = user message
      type: Number,
      default: 0,
    },
    message: {
      type: String,
      default: '',
    },
  },
  emits: [],
  data() {
    return {
      canAddArgument: true,
    };
  },
  computed: {
    ...mapState([
      'playerName',
      'playerSurname',
      'playerPosition',
    ]),
    isSpeakerMessage() {
      return this.type === 0;
    },
    displayPlayerPosition() {
      return USER_POSITION_EU_POLICY_OFFICER;
    },
    playerDisplayName() {
      let playerDisplayName = this.playerName ? this.playerName : '';

      if (this.playerSurname) {
        playerDisplayName += ` ${this.playerSurname.substring(0, 1)}.`;
      }

      return playerDisplayName;
    },
    isUserMessage() {
      return this.type === 1;
    },
    hasSpeaker() {
      return this.speakerName !== '' || this.speakerRole !== '';
    },
    messageClass() {
      if (this.isSpeakerMessage) {
        return 'speaker-message';
      } else if (this.isUserMessage) {
        return 'user-message';
      }
      return '';
    },
    messageHtml() {
      return this.message === '' ? '' : textToHtml(this.$t(this.message), this.$t);
    },
  },
  watch: {
    messageId() {
      this.canAddArgument = true;
    },
  },
};
</script>

<style lang="scss" scoped>
$choiceColor: #F4F4F4;
$tickSize: 25px;
$tickSizeSmall: 15px;
$tickDistanceRight: 45px;

$minWidth: ($tickSize * 2) + $tickDistanceRight;

  .message-component-wrapper {
    width: 100%;
    max-width: 700px;
    position: relative;
    margin-top: 15px;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    /* &.message-component-wrapper-user {
      margin-bottom: 75px;
    } */

    .user-message-component-pointer {
      position: absolute;
      bottom: -$tickSizeSmall;
      right: $tickDistanceRight;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 $tickSizeSmall $tickSizeSmall 0;
      border-color: transparent $choiceColor transparent transparent;
      z-index: 0;
    }

    .speaker-message-component-pointer {
      width: 20px;
      height: 20px;
      position: absolute;
      left: -10px;
      top: 30px;
      background: white;
      transform: rotate(45deg);
      z-index: 0;
    }

    .stakeholder-info-wrapper-name {
      font-weight: 600;
    }

    .stakeholder-info-wrapper-role {
      font-size: 0.9rem;
    }

    .stakeholder-info-wrapper {
      background-color: #4C6066;
      padding: 10px 15px 10px 15px;
      width: fit-content;
      max-width: 72%;
      min-width:25%;
      position: relative;
      left: 15px;
      transform: translate(0px, 15%);
      z-index: 9;

      font-weight: 400;
      font-family: 'Fira Sans Condensed';
      font-style: italic;

      &.user-info {
        transform: translate(-30px, 15%);
        margin-left: auto;
      }

      p {
        margin: 0;
      }
    }

    .message-outer-wrapper {
      position: relative;
      .message-wrapper {
        font-weight: 400;
        font-family: 'Fira Sans Condensed';
        color: #000;
        border-radius: 10px;
        padding: 15px 15px 35px 15px;
        z-index: 1;
        overflow: auto;
        max-height: 100%;
      }
      .speaker-message {
        margin-right: 50px;
        Background: #fff;
      }

      .user-message {
        Background: #F4F4F4;
        margin-left: 50px;
      }
    }

    .save-source-wrapper {
      position: absolute;
      bottom: 30px;
      left: -20px;
    }
  }

</style>
