<template>
  <div class="assistant-banner-wrapper">
    <div class="assistant-banner" :class="isFirstMessage ? 'assistant-banner-reveal' : ''">
      <div class="assistant-banner-content" :class="isFirstMessage ? 'assistant-banner-content-reveal' : ''">
        <div class="assistant-banner-lighting"/>
        <div class="assistant-banner-avatar-wrapper">
          <div class="assistant-banner-avatar assistant-avatar-neutral"></div>
        </div>
        <div class="assistant-banner-text">
          <Message
            :message-id="-1"
            :speaker-name="'Agrinatura'"
            :speaker-role="'Assistant'"
            :message="currentMessage"
            :show-save-button="false"
          >
          </Message>
        </div>
        <div class="choice-button-wrapper" v-if="currentChoices.length">
          <ChoiceButtonGroup :choice-buttons="currentChoices"  v-model="clickedValue" />
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import Message from '@/components/common/Message';
import ChoiceButtonGroup from '@/components/ChoiceButton/ChoiceButtonGroup';

export default {
  name: 'AssistantFull',
  props: {
    startDelay: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isFirstMessage: {
      type: Boolean,
      default: false,
    },
    currentMessage: {
      type: String,
      default: '',
    },
    currentChoices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      clickedValue: null,
    };
  },
  watch: {
    clickedValue() {
      if (this.clickedValue === -1) {
        return;
      }
      this.onClickButton();
    },
  },
  components: {
    ChoiceButtonGroup,
    Message,
  },
  emits: ['click'],
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click', this.clickedValue);
      this.clickedValue = -1;
    },
  },
};
</script>
<style lang="scss" scoped>

$bottomOffset: 20px;

  .assistant-banner-wrapper{
    width: 100%;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    left: 0;
    height: 100%;
    z-index: 10000000;
    background-color: rgba(0,0,0,0.4);

    .assistant-banner-reveal {
      animation-name: reveal;
      animation-duration: 300ms;
      animation-timing-function: ease;

      .assistant-banner-content-reveal {
        animation-name: reveal-content;
        animation-timing-function: ease;
        animation-duration: 1.1s;
      }
    }

    .assistant-banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(169.84deg, #a3a3a3 8.34%, #2D2D2D 104.14%);;
      padding: 15px;

      .assistant-banner-lighting {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(111.02deg, #F8F8F8 -1.07%, rgba(162, 163, 168, 0) 47.17%);
      }

      .assistant-banner-content {
        opacity: 1;

        .assistant-banner-avatar-wrapper {
          position: absolute;
          bottom: 0;
          height: 100%;
          width: 100%;

          .assistant-banner-avatar {
            position: absolute;
            height: 80%;
            bottom: 0;
            left: 20px;
            width: 50%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .assistant-banner-text {
          color: #fff;
          left: 45%;
          top: 25%;
          width: 65%;
          min-height:60px;
          position: absolute;
        }

        .choice-button-wrapper {
          position: absolute;
          bottom: 35px;
          left: 50%;
          transform: translateX(-50%);
          width: fit-content;
        }

        .assistant-button-wrapper {
          right: 50px;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }

    .assistant-avatar-neutral {
      background-image: url("../../assets/img/assistant/assistant-full-neutral.png");
    }

    @keyframes reveal
    {
      0% {width:0px; opacity:50;}
      100% {width:100%;
        opacity:100;}
    }


    @keyframes reveal-content
    {
      0% {opacity:0;}
      50% {opacity:0;}
      100% {opacity:100;}
    }
  }

</style>
