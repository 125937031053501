<template>
  <div class="language-button" @click="onClickButton" :class="isSelected ? 'selected' : ''">
    <div>
      <no-drag-image :src="imageSrc"/>
    </div>
    <slot/>
  </div>
</template>
<script>

import NoDragImage from '@/components/common/NoDragImage';
export default {
  name: 'LanguageButton',
  components: { NoDragImage },
  props: {
    languageShort: {
      type: String,
      required: true,
    },
    currentLanguage: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
  computed: {
    imageSrc() {
      const images = require.context('../../assets/img/flags');
      const imgSrc = images(`./square-${this.languageShort}.png`);
      return imgSrc;
    },
    isSelected() {
      console.log("isSelected", this.currentLanguage, this.languageShort);
      return this.languageShort === this.currentLanguage;
    },
  },
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click', this.languageShort);
    },
  },
};
</script>

<style lang="scss" scoped>
.language-button {
  cursor: pointer;
  margin: 8px 8px;
  border: #dddddd solid 1px;
  border-radius: 15px;
  min-width: 165px;
  text-align: center;
  // -webkit-box-shadow: inset -37px -40px 27px -50px rgba(43,65,70,0.4);
  // -moz-box-shadow: inset -37px -40px 27px -50px rgba(43,65,70,0.4);
  // box-shadow: inset -37px -40px 27px -50px rgba(43,65,70,0.4);
  padding: 10px 35px;
  transition: transform 50ms ease-in-out;

  &:hover {
    transform: scale(1.02);
    background-color: #fafafa;
  }

  &.selected {
    background-color: #E1EDEA;
  }

  img {
    width: 50px;
  }
}

</style>
