import Dilemma1Data from './dilemma1/data.json';
import Dilemma2Data from './dilemma2/data.json';
import Dilemma3Data from './dilemma3/data.json';
import Dilemma4Data from './dilemma4/data.json';
import TutorialData from './dilemmaINTRO2/data.json';

export default {
  1: Dilemma1Data,
  2: Dilemma2Data,
  3: Dilemma3Data,
  4: Dilemma4Data,
  INTRO2: TutorialData,
};
