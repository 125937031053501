<template>
  <div class="data-break-container" :class="containerClasses">
    <div class="data-break-content-container">
      <h3 class="data-break-title">{{ $t('misc.dataCaps') }}</h3>
      <div class="persistent-assistant-wrapper">
        <PersistentAssistantButton assistant-message-id="persistentBossFightArgumentMessage" />
      </div>
      <div>
        <div class="data-wrapper" v-for="(dataItem, i) in useArgumentDataObject" :key="i">
          <UseArgumentModule :data-key="i" :data-name="dataItem.name" :data-passage="dataItem.passage"
                             :vocabulary-keys="dataItem.vocabularyKeys" :data-type="typeFromId(i)" @chooseArgument="chooseArgument"/>
        </div>
      </div>
    </div>
    <div class="chosen-argument-wrapper">
      <ChosenArgument :chosen-data-name="chosenArgumentName" :use-argument-panel-open="useArgumentPanelOpen"
                      @removeChosenArgument="removeChosenArgument" :data-type="typeFromId(chosenArgumentDataKey)" @acceptChosenArgument="acceptChosenArgument"/>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import textToHtml from '@/utils/textToHtml';
import SaveSourceButton from '@/components/SaveSourceButton/SaveSourceButton';
import { CONVERSATIONS } from '@/enum/Conversations';
import AppButton from '@/components/common/AppButton';
import UseArgumentModule from '@/components/ChooseArgument/UseArgumentModule';
import ChosenArgument from '@/components/ChooseArgument/ChosenArgument';
import PersistentAssistantButton from '@/components/PersistentAssistantButton/PersistentAssistantButton';
import NoDragImage from '../common/NoDragImage';

export default {
  name: 'UseArgumentPanel',
  props: {
    useArgumentPanelOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vocabularyModuleOpen: false,
      currentVocabularyName: '',
      currentVocabularyKey: '',
      currentVocabularyInsights: '',
      currentVocabularyBonusInsights: '',
      dataBreakData: {},
      vocabularyData: {},
      chosenArgumentDataKey: null,
    };
  },
  components: {
    PersistentAssistantButton,
    NoDragImage,
    UseArgumentModule,
    ChosenArgument,
    AppButton,
    SaveSourceButton,
  },
  emits: ['toggle', 'acceptChosenArgument'],
  mounted() {
    this.dataBreakData = CONVERSATIONS.dataBreakData;
    this.vocabularyData = CONVERSATIONS.glossaryData;
  },
  computed: {
    ...mapState([
      'argumentIndexesPerPolicyIndex',
    ]),
    chosenArgumentName() {
      return this.chosenArgumentDataKey ? this.dataBreakData[this.chosenArgumentDataKey].name : '';
    },
    useArgumentDataObject() {
      const useArgumentDataObject = {};

      if (!this.argumentIndexesPerPolicyIndex) {
        return useArgumentDataObject;
      }

      for (const policyKey in this.argumentIndexesPerPolicyIndex) {
        if (this.argumentIndexesPerPolicyIndex.hasOwnProperty(policyKey)) {
          const policy = this.argumentIndexesPerPolicyIndex[policyKey];
          for (const key of policy) {
            if (this.dataBreakData?.[key]) {
              useArgumentDataObject[key] = this.dataBreakData[key];
            } else {
              console.log("KEY NOT FOUND: ", key);
            }
          }
        }
      }

      // console.log("use useArgumentDataObject",useArgumentDataObject);
      return useArgumentDataObject;
    },
    containerClasses() {
      const containerClasses = [];
      if (this.useArgumentPanelOpen) {
        containerClasses.push('data-break-window-open');
      }
      if (!this.useArgumentPanelOpen) {
        containerClasses.push('data-break-window-closed');
      }
      return containerClasses;
    },
  },
  methods: {
    onClickToggle() {
      this.$emit('toggle');
      if (this.useArgumentPanelOpen) {
        this.closeVocabularyModule();
      }
    },
    typeFromId(dataId) {
      return this.dataBreakData?.[dataId]?.dataType ?? 'none';
    },
    closeVocabularyModule() {
      this.vocabularyModuleOpen = false;
      this.currentVocabularyKey = null;
    },
    dataTextToHtml(text) {
      return textToHtml(text, this.$t);
    },
    chooseArgument(dataKey) {
      this.chosenArgumentDataKey = dataKey;
    },
    acceptChosenArgument() {
      this.$emit('acceptChosenArgument', this.chosenArgumentDataKey);
      this.chosenArgumentDataKey = null;
    },
    removeChosenArgument() {
      this.chosenArgumentDataKey = null;
    },
    getImageSrcDocument() {
      const images = require.context('../../assets/img/ui');
      const imgSrc = images('./document1.png');
      return imgSrc;
    },
  },
};
</script>
<style lang="scss" scoped>
.data-break-container {
  position: absolute;
  width: 20%;
  min-width: 200px;
  right: 0;
  top: 0;
  height: 100%;
  transform: translateX(100%);
  padding: 0;
  transition-property: transform;
  transition-duration: 350ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);

  .no-pointer-events {
    pointer-events: none;
  }

  .backdrop {
    z-index: 0;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
  }

  .persistent-assistant-wrapper {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

  .data-break-title {
    text-align: center;
  }

  &.data-break-window-open {
    transform: translateX(0%);
  }

  &.data-break-window-hidden {
    transform: translateX(160%);
    pointer-events: none;
  }

  .data-break-content-container {
    z-index: 1;
    position: relative;
    padding: 30px 10px;
    background: linear-gradient(170deg, rgba(0,92,92,1) 8%, rgba(44,44,44,1) 100%);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .data-wrapper {
    .data-title-container {
      color: #fff;
      background-color: #263250;
      border-radius: 7px 7px 0 0;
      font-size: 16px;
      line-height: 1.2;
      width: 100%;
      margin-top: 15px;
      padding: 10px;
      text-align: center;
      font-family: 'Fira Sans Condensed','Fira Sans', sans-serif;

      p {
        margin: 0;
      }
    }

    .data-content-container {
      color: #5B5B5B;
      background-color: #F8F8F8;
      padding: 12px 10px 17px 10px;
      border-radius: 0 0 7px 7px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2;
    }
  }

  .vocabulary-module-wrapper {
    position: absolute;
    z-index: 1;
    top: 65px;
    right: 105%;
    width: 100%;
  }

  .chosen-argument-wrapper {

  }
}
</style>
