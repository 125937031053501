<template>
  <div :id="'onboarding-bar-' + stakeholderId" class="onboarding-bar-wrapper">
    <div class="onboarding-bar-wrapper-outline" :class="isOnboarded ? 'onboarded' : ''">
      <div class="onboarding-bar-wrapper-content"/>
      <div class="glow">
        <no-drag-image src="@/assets/img/ui/glow.png"/>
      </div>
      <div class="moving-bar" :style="{height: heightFromOnboardPercentage + '%'}"/>
      <div class="text-wrapper">
        <h4>{{ $t('misc.onboardCaps') }}</h4>
      </div>
    </div>
  </div>
</template>
<script>

import NoDragImage from '@/components/common/NoDragImage';

export default {
  name: 'OnboardingBar',
  components: { NoDragImage },
  props: {
    onBoardPercentage: {
      type: Number,
      default: 0,
    },
    stakeholderId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      lowestValue: 32,
      highestValue: 100,
    };
  },
  emits: ['click'],
  computed: {
    isOnboarded() {
      return this.onBoardPercentage >= 100;
    },
    heightFromOnboardPercentage() {
      const newHeight = this.onBoardPercentage * ((this.highestValue - this.lowestValue) / this.highestValue) + this.lowestValue;
      return newHeight;
    },
  },
};
</script>
<style lang="scss" scoped>

$gap: 3px;

.onboarding-bar-wrapper {
  height: 100%;

  .onboarding-bar-wrapper-outline {
    position: relative;
    width: 100%;
    max-width: 300px;
    max-height: 900px;
    height: 100%;
    border: solid 3px;
    border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(141, 141, 141, 0)) 1;
    text-align: center;
    padding: 3px;
    color: #4B4B4B;

    .onboarding-bar-wrapper-content{
      background: linear-gradient( rgba(0, 0, 0, 0.17), rgba(141, 141, 141, 0));
      position: absolute;
      top: 0;
      left:0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }
    .moving-bar {
      max-height: 100%;
      z-index: 0;
      position: absolute;
      bottom: -$gap * 2  ;
      left: $gap;
      height: 100%;
      width: calc(100% - $gap * 2);
      background-image: linear-gradient(rgba(233, 233, 233, 0.48), rgba(255, 255, 255, 0));
      transition: height 0.5s cubic-bezier(0.68, -0.25, 0.265, 1.25);
    }

    .glow {
      position: absolute;
      left: -25%;
      top: -5%;
      height: 90%;
      width: 150%;
      mix-blend-mode: hard-light;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      img {
        height: 100%;
        width: 100%;
      }
    }

    .text-wrapper {
      position: relative;
      margin-top: 5%;
      z-index: 20;
      opacity: 0.5;
      text-shadow: 0 0 15px #fff;
      transition: opacity 0.5s ease-in-out;

      h4 {
        font-size: min(max(14px, 3.2vw), 30px);
      }
    }
  }

  .onboarded {
    border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.30), rgba(141, 141, 141, 0)) 1;

    .onboarding-bar-wrapper-content{
      opacity: 1;
    }

    .moving-bar {
      background-image: linear-gradient(rgba(255, 255, 255, 0.81), rgba(255, 255, 255, 0));
    }

    .glow {
      opacity: 1;
    }

    .text-wrapper {
      opacity: 1;
    }
  }
}

</style>
