export const PAGE_TYPE_START = 'startView';
export const PAGE_TYPE_INFO = 'infoView';
export const PAGE_TYPE_INFO_SCROLL = 'infoScrollView';
export const PAGE_TYPE_SITUATION = 'situationView';
export const PAGE_TYPE_EMAIL = 'emailView';
export const PAGE_TYPE_PHONE = 'phoneConversationView';
export const PAGE_TYPE_BOSS_FIGHT = 'bossFightConversationView';
export const PAGE_TYPE_TEXT_INPUT = 'textInputView';
export const PAGE_TYPE_DYNAMIC_TEXT = 'dynamicTextView';
export const PAGE_TYPE_FINAL_SCORES = 'finalScoresView';
