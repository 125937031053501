<template>
  <div class="vocabulary-module-container" :class="containerClasses">
    <div class="data-wrapper">
      <div class="data-title-container">
        <p>{{vocabularyName}}</p>
        <div class="back-button" @click="onClickBackButton">
          ✖
        </div>
      </div>
      <div class="data-content-container">
        <div class="speaker-message-component-pointer"/>
        <div v-html="dataTextToHtml(vocabularyInsight)">
        </div>
        <div v-if="bonusInfoShown">
          <hr>
        </div>
        <div v-if="bonusInfoShown" v-html="dataTextToHtml(vocabularyBonusInsight)">
        </div>
        <div v-if="hasBonusInsights && !bonusInfoShown" class="bonus-button-wrapper">
          <AppButton :size="'fill'" :color="'light_grey'" @click="openBonusInsights">
            {{ $t('misc.additionalInsights') }}
          </AppButton>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import { mapState } from 'vuex';
import textToHtml from '@/utils/textToHtml';
import { CONVERSATIONS } from '@/enum/Conversations';
import AppButton from '@/components/common/AppButton';

export default {
  name: 'VocabularyModule',
  components: {
    AppButton,
  },
  props: {
    vocabularyKey: {
      type: String,
      required: true,
    },
    vocabularyName: {
      type: String,
      required: true,
    },
    vocabularyInsight: {
      type: String,
      required: true,
    },
    vocabularyBonusInsight: {
      type: String,
      default: null,
    },
    vocabularyModuleOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],
  data() {
    return {
      bonusInfoShown: false,
      forceCloseModule: false,
    };
  },
  watch: {
    vocabularyKey: {
      handler(newValue, oldValue) {
        if (oldValue && newValue !== oldValue) {
          this.forceCloseModule = true;
          this.bonusInfoShown = false;
          setTimeout(() => {
            this.forceCloseModule = false;
          }, 100);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState([
    ]),
    hasBonusInsights() {
      return this.vocabularyBonusInsight !== null && this.vocabularyBonusInsight !== 'nan';
    },
    containerClasses() {
      return this.vocabularyModuleOpen && !this.forceCloseModule ? 'module-open' : 'module-closed';
    },
  },
  mounted() {
    this.vocabularyData = CONVERSATIONS.glossaryData;
  },
  methods: {
    dataTextToHtml(text) {
      return textToHtml(text, this.$t);
    },
    openBonusInsights() {
      this.bonusInfoShown = true;
    },
    onClickBackButton() {
      this.$emit('toggle');
    },
  },
};
</script>
<style lang="scss" scoped>
$tickSize: 25px;
$tickSizeSmall: 15px;
$tickDistanceRight: 45px;

.vocabulary-module-container {
  width: 100%;
  // -webkit-animation: all 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  // animation: all 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;

  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);

  &.module-open {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-transform-origin: right;
    transform-origin: right;
    opacity: 1;
  }

  &.module-closed {
    pointer-events: none;
    -webkit-transform: rotateY(-100deg);
    transform: rotateY(-100deg);
    -webkit-transform-origin: right;
    transform-origin: right;
    opacity: 0;
  }

  .data-wrapper {
    position: relative;
    z-index: 1;
    max-height: calc(100vh - 180px);
  //overflow-y: auto;
    // overflow-x: hidden;

    .data-title-container {
      color: #fff;
      background-color: #2F7E96;
      border-radius: 7px 7px 0 0;
      font-size: 16px;
      line-height: 1.2;
      width: 100%;
      margin-top: 15px;
      padding: 10px 30px;
      position: relative;
      z-index: 1;
      text-align: center;
      font-family: 'Fira Sans Condensed','Fira Sans', sans-serif;

      .back-button {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 5px;
        cursor: pointer;
      }

      p {
        margin: 0;
      }
    }

    .data-content-container {
      color: #5B5B5B;
      background-color: #F8F8F8;
      padding: 12px 10px 17px 10px;
      border-radius: 0 0 7px 7px;
      font-weight: 400;
      font-size: 18px;
      line-height: 118%;
      box-shadow: -5px 13px 53px 0px rgba(0,0,0,0.75);
      overflow-y: auto;

      max-height: 50vh;

      .speaker-message-component-pointer {
        position: absolute;
        right: -15px;
        top: 85px;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid #F8F8F8;
      }
    }

    .bonus-button-wrapper {
      margin: 20px 0 0px 0;
    }
  }
}
</style>
