<template>
  <div class="story-choices row">
    <ChoiceButtonGroup v-model="activeChoice" :choice-buttons="choiceButtons" />
  </div>
</template>

<script>
import ChoiceButtonGroup from '../ChoiceButton/ChoiceButtonGroup';

export default {
  name: 'StoryChoices',
  components: {ChoiceButtonGroup},
  props: {
    choices: {
      type: null,
      required: false,
      default: null,
    },
    isReplayChoiceForReplayConversation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['pickChoice'],
  watch: {
    choices: {
      handler() {
        this.recalculateChoiceButtons();
      },
    },
    activeChoice() {
      this.pickChoice(this.activeChoice);
    },
  },
  data() {
    return {
      choiceButtons: [],
      activeChoice: null,
    };
  },
  mounted() {
    this.recalculateChoiceButtons();
  },
  methods: {
    recalculateChoiceButtons() {
      /**
       *
       *           text: 'Choice text long Choice text long Choice text long Choice text long',
       *           value: 'long',
       *           cost: 1,
       */
      const choiceButtons = [];

      for (let i = 0, len = this.choices.length; i < len; i++) {
        const choice = this.choices[i];

        choiceButtons.push({
          text: choice.text,
          btnText: choice.buttonText,
          cost: this.isReplayChoiceForReplayConversation ? 0 : choice.cost,
          disabled: choice?.disabled || false,
          value: i, // Just use the index
        });
      }

      this.choiceButtons = choiceButtons;
    },
    pickChoice(choiceIndex) {
      this.$emit('pickChoice', choiceIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.story-choice-container {
  padding: 10px;
}

.story-choice {
  cursor: pointer;
  text-decoration: auto;
  background: #888888;
  color: white;
  transition: background 200ms ease-in-out;
  padding: 10px;

  &:hover {
    background: #333333;
  }
}
</style>
