<template>
  <div v-if="show" class="language-select-popup-container">
    <div class="language-select-popup">
      <div class="language-select-flags d-flex justify-content-center flex-wrap">
        <language-button language-short="en" :current-language="chosenLanguage" @click="changeLanguage">
          English
        </language-button>
        <language-button language-short="fr" :current-language="chosenLanguage" @click="changeLanguage">
          Français
        </language-button>
        <language-button language-short="es" :current-language="chosenLanguage" @click="changeLanguage">
          Español
        </language-button>
        <language-button language-short="pt" :current-language="chosenLanguage" @click="changeLanguage">
          Português
        </language-button>
      </div>
      <div class="d-flex justify-content-center">
        <app-button color="green" @click="confirmLanguageSelect">
          {{ $t('misc.continue') }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>

import LanguageButton from '@/components/LanguageSelect/LanguageButton';
import AppButton from '@/components/common/AppButton';
export default {
  name: 'LanguageSelectPopup',
  components: { AppButton, LanguageButton },
  data() {
    return {
      show: true,
      chosenLanguage: '',
    };
  },
  mounted() {

  },
  methods: {
    changeLanguage(locale) {
      this.chosenLanguage = locale;
      this.$i18n.locale = locale;
    },
    confirmLanguageSelect() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .language-select-popup-container {
    background: rgba(255, 255, 255, .8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000001;
  }

  .language-select-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #f8f8f8;
    padding: 50px;
    border: 1px solid #333333;
    transform: translate(-50%, -50%);

    box-shadow: 10px 10px 27px 0 rgba(0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 27px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 27px 0 rgba(0,0,0,0.75);
    color: black;

    .language-select-flags {
      width: 400px;
      padding-bottom: 20px;
    }
  }
</style>
