import extend from 'extend';

export function randomBetween(start, end, decimals = 3) {
  const pow = Math.pow(10, decimals);

  start = Math.round(start * pow);
  end = Math.round(end * pow);

  // If end is less than start, then flip them around
  if (end < start) {
    const _end = end;

    end = start;
    start = _end;
  }

  const value = Math.round(start + (Math.random() * (end - start)));

  return value / pow;
}
