<template>
  <div id="app-container" class="td-600" :class="appContainerClasses">
    <cheat-window v-if="cheatsEnabled" style="z-index:1000000000" />
    <router-view />
    <AssistantController :assistant-message="currentAssistantMessage" />
    <div v-if="isKnowledgeVaultOpen" class="knowledge-vault-wrapper">
      <KnowledgeVaultFullPanel @click="toggleKnowledgeVaultOpen" />
    </div>
    <analytics-permission-popup ref="permissionPopUp" />
    <!--<language-select-popup/>-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CheatWindow from '@/components/CheatsPanel/CheatWindow';
import AssistantController from '@/components/AssistantBanner/AssistantController';
import AnalyticsPermissionPopup from '@/components/common/AnalyticsPermissionPopup';
import { SeriousAnalyticsEvent } from 'serious-analytics';
import {
  SERIOUS_ANALYTICS_TIMER_DILEMMA,
  SERIOUS_ANALYTICS_TIMER_GAME_SESSION,
} from '@/enum/SeriousAnalyticsTimers';
import strategyData from '@/data/strategyData.json';
import LanguageSelectPopup from '@/components/LanguageSelect/LanguageSelectPopup';
import KnowledgeVaultFullPanel from './components/KnowledgeVault/KnowledgeVaultFullPanel';
import { VIEW_INTRO } from './enum/Views';
import {
  INITIALIZE_STORE, RESET_GAME, SET_IS_KNOWLEDGE_VAULT_OPEN, SET_IS_GAME_REPLAY,
} from './store/mutation-types';

export default {
  name: 'App',
  components: {
    LanguageSelectPopup,
    AnalyticsPermissionPopup,
    KnowledgeVaultFullPanel,
    CheatWindow,
    AssistantController,
  },
  data() {
    return {
      currentAssistantMessage: [],
      logMessages: [],

      sessionId: null,

      creatingSession: false,
      postingEvent: false,
      postingTimer: false,

      analyticsDilemmaTimerRunning: false,
      analyticsOverallTimerRunning: false,

      callingEvent: false,
      callingTimer: false,

      timerIsRunning: false,

      strategies: strategyData,
    };
  },
  computed: {
    ...mapState([
      'background',
      'submissionToken',
      'isKnowledgeVaultOpen',
      'tutorialAssistantMessagesShown',
      'evidencePointsHarvestData',
      'evidencePointsTransformData',
      'evidencePointsBossFight',
      'playerPosition',
      'playerLocation',
      'dealAccepted',
      'argumentsUsedInBossFight',
      'selectedScenarioIndex',
      'cheatsWindowActivated',
      'isGameReplay',
    ]),
    appContainerClasses() {
      const appContainerClasses = [];

      if (this.background) {
        appContainerClasses.push(`background-${this.background}`);
      }

      return appContainerClasses;
    },
    accumulatedEvidencePoints() {
      const accumulatedEvidencePoints = this.evidencePointsBossFight + this.evidencePointsHarvestData + this.evidencePointsTransformData;
      return accumulatedEvidencePoints;
    },
    localizedPlayerPositionEnglish() {
      return this.$t(this.playerPosition, 'en');
    },
    localizedPlayerLocationEnglish() {
      return this.$t(this.playerLocation, 'en');
    },
    cheatsEnabled() {
      if (process.env.VUE_APP_CHEATS_ALLOWED === '1' || this.cheatsWindowActivated) {
        return true;
      }
      return false;
    },
    canCreateSession() {
      return !this.creatingSession && !this.sessionId;
    },
    canCallEvent() {
      return true;

      return this.callingEvent;
    },
    canStartTimer() {
      return !this.timerIsRunning;
    },
    canStopTimer() {
      return this.timerIsRunning;
    },

  },
  beforeCreate() {
    this.$store.commit(INITIALIZE_STORE);
  },
  mounted() {
    this.assistantData = require('@/data/assistant/assistantData.json');

    // For dev purposes
    this.$seriousAnalytics.on(SeriousAnalyticsEvent.Log, (message) => {
      this.log(`SeriousAnalyticsEvent.Log: ${message}`);
    });

    this.$seriousAnalytics.on(SeriousAnalyticsEvent.Error, (e) => {
      this.log(`SeriousAnalyticsEvent.Error: ${e.message}`);
    });

    this.$seriousAnalytics.on(SeriousAnalyticsEvent.PermissionRequested, (e) => {
      this.log('SeriousAnalyticsEvent.PermissionRequested');
    });

    this.$seriousAnalytics.on(SeriousAnalyticsEvent.PermissionGranted, (e) => {
      this.log('SeriousAnalyticsEvent.PermissionGranted');
    });

    this.$seriousAnalytics.on(SeriousAnalyticsEvent.PermissionRejected, (e) => {
      this.log('SeriousAnalyticsEvent.PermissionRejected');
    });

    // serious analutics
    this.$seriousAnalytics.createSession();
    // TODO: make dilemma Progression in state and dilemma unlock data
  },
  methods: {
    sendAssistantMessages(messages) {
      // console.log("Starting an assistant message", this.currentAssistantMessage, messages);

      this.currentAssistantMessage = null;
      this.$nextTick(() => {
        this.currentAssistantMessage = messages;
      });
    },
    sendAssistantMessagesByKey(messageKey) {
      const messages = this.assistantData?.[messageKey];
      if (!messages) {
        console.log('assistant message key not found');
        return;
      }
      this.sendAssistantMessages(messages);
    },
    resetGame() {
      if (confirm('Are you sure that you want to reset the game?')) {
        this.$store.commit(RESET_GAME);
        this.$store.commit(SET_IS_GAME_REPLAY, true);
        this.$router.push({ name: VIEW_INTRO });
      } else {

      }
    },
    toggleKnowledgeVaultOpen() {
      this.$store.commit(SET_IS_KNOWLEDGE_VAULT_OPEN, !this.isKnowledgeVaultOpen);
    },
    log(message) {
      // https://stackoverflow.com/a/10632399/247893
      const dt = new Date();
      const padL = (nr, len = 2, chr = '0') => `${nr}`.padStart(2, chr);

      const dateTimeStamp = `${
        padL(dt.getMonth() + 1)}/${
        padL(dt.getDate())}/${
        dt.getFullYear()} ${
        padL(dt.getHours())}:${
        padL(dt.getMinutes())}:${
        padL(dt.getSeconds())}`;

      this.logMessages.push({
        time: dateTimeStamp,
        message,
      });
    },
    clearPermissionGiven() {
      this.$seriousAnalytics.clearPermissionGiven();
    },
    createSession() {
      if (!this.canCreateSession) {
        return;
      }

      this.creatingSession = true;

      /* this.$seriousAnalytics.on(SeriousAnalyticsEvent.LOG, (message) => {
        this.log(message);
      }); */

      this.log('CreatingSession..');

      this.$seriousAnalytics.createSession().then((sessionId) => {
        this.sessionId = sessionId;

        this.creatingSession = false;

        this.log(`CreatedSession ${sessionId}`);
      });
    },
    callStartEvent() {
      // return;
      this.callingEvent = true;

      this.log('Event.StartApp.Submitting..');

      this.$seriousAnalytics.postEvent('StartApp', {
        position: this.localizedPlayerPositionEnglish,
        acceptedAgreement: false,
        continent: this.localizedPlayerLocationEnglish,
        language: this.getCurrentLocale(),
      }).then((e) => {
        console.log('event', e);
        this.callingEvent = false;

        this.log(`Event.StartApp.Submitted: ${e.id}`);
      }).catch((e) => {
        this.log(`Event.StartApp.Error: ${e.message}`);

        this.callingEvent = false;
      });

      // also start overall timer for the game
      this.startTimer(SERIOUS_ANALYTICS_TIMER_GAME_SESSION);
    },
    getCurrentLocale() {
      const shortLocale = this.$i18n.locale;
      let locale = 'English';

      if (shortLocale === 'en') {
        locale = 'English';
      } else if (shortLocale === 'es') {
        locale = 'Spanish';
      } else if (shortLocale === 'pt') {
        locale = 'Portuguese';
      } else if (shortLocale === 'fr') {
        locale = 'French';
      }

      return locale;
    },
    callDilemmaStartEvent(dilemmaId) {
      // return;
      this.callingEvent = true;

      this.log('Event.StartDilemma.Submitting..');

      this.$seriousAnalytics.postEvent('DilemmaStart', {
        dilemmaId: parseInt(dilemmaId, 10),
      }).then((e) => {
        console.log('event', e);
        this.callingEvent = false;

        this.log(`Event.StartApp.Submitted: ${e.id}`);
      }).catch((e) => {
        this.log(`Event.StartApp.Error: ${e.message}`);

        this.callingEvent = false;
      });

      // also start overall timer for the game
      this.startTimer(SERIOUS_ANALYTICS_TIMER_DILEMMA);
    },
    callDilemmaEndEvent(dilemmaId, stakeholdersMet) {
      // return;
      this.callingEvent = true;

      this.log('Event.EndDilemma.Submitting..');

      this.$seriousAnalytics.postEvent('DilemmaEnd', {
        dilemmaId: parseInt(dilemmaId, 10),
        evidencePoints: this.accumulatedEvidencePoints,
        stakeholdersMet,
        wentToInformalMeeting: false,
      }).then((e) => {
        console.log('event', e);
        this.callingEvent = false;

        this.log(`Event.StartApp.Submitted: ${e.id}`);
      }).catch((e) => {
        this.log(`Event.StartApp.Error: ${e.message}`);

        this.callingEvent = false;
      });

      // also stop specific dilemma timer for the game
      this.stopTimer(SERIOUS_ANALYTICS_TIMER_DILEMMA, dilemmaId);
    },
    callInformalEvent(wentToInformal) {
      // TODO removed check
      return;

      // they did not want to log informal event
      this.callingEvent = true;

      console.log(`Event.InformalMeeting.Submitting..went to informal: ${wentToInformal}`);
      console.log(wentToInformal);
      this.log(`Event.InformalMeeting.Submitting..went to informal: ${wentToInformal}`);

      this.$seriousAnalytics.postEvent('InformalMeeting', {
        wentToInformalMeeting: wentToInformal,
        position: this.localizedPlayerPositionEnglish,
        continent: this.localizedPlayerLocationEnglish,
      }).then((e) => {
        console.log('event', e);
        this.callingEvent = false;

        this.log(`Event.InformalMeeting.Submitted: ${e.id}`);
      }).catch((e) => {
        this.log(`Event.InformalMeeting.Error: ${e.message}`);

        this.callingEvent = false;
      });
    },
    callEndEvent(onboardingPoints, stakeholdersMet) {
      // return;

      this.callingEvent = true;

      this.log('Event.EndApp.Submitting..');

      const localizedScenarioNameEnglish = this.$t(strategyData[this.selectedScenarioIndex].title, 'en');

      this.$seriousAnalytics.postEvent('EndApp', {
        evidencePoints: this.accumulatedEvidencePoints,
        stakeholdersMetAmount: stakeholdersMet,
        onboardingPoints,
        superDataPoints: this.evidencePointsBossFight,
        scenario: localizedScenarioNameEnglish,
        data1: parseInt(this.argumentsUsedInBossFight['1'], 10),
        data2: parseInt(this.argumentsUsedInBossFight['2'], 10),
        data3: parseInt(this.argumentsUsedInBossFight['3'], 10),
        data4: parseInt(this.argumentsUsedInBossFight['4'], 10),
        isReplay: this.isGameReplay,
        position: this.localizedPlayerPositionEnglish,
        acceptedAgreement: this.dealAccepted,
        continent: this.localizedPlayerLocationEnglish,
        language: this.getCurrentLocale(),
      }).then((e) => {
        this.callingEvent = false;

        this.log(`Event.EndApp.Submitted: ${e.id}`);
      }).catch((e) => {
        this.log(`Event.EndApp.Error: ${e.message}`);

        this.callingEvent = false;
      });

      this.stopTimer(SERIOUS_ANALYTICS_TIMER_GAME_SESSION);
    },
    startTimer(timerName) {
      /* if (!this.canStartTimer) {
        return;
      } */
      if (timerName === SERIOUS_ANALYTICS_TIMER_DILEMMA) {
        if (this.analyticsDilemmaTimerRunning) {
          return;
        }
        this.analyticsDilemmaTimerRunning = true;
      }
      if (timerName === SERIOUS_ANALYTICS_TIMER_GAME_SESSION) {
        if (this.analyticsOverallTimerRunning) {
          return;
        }
        this.analyticsOverallTimerRunning = true;
      }

      // Doesn't call the API so no promise
      const timer = this.$seriousAnalytics.startTimer(timerName);

      console.log('timer', timer);

      this.timerIsRunning = true;
    },
    stopTimer(timerName, dilemmaId = null) {
      this.log(`Timer.${timerName}.Submitting`);
      this.callingTimer = true;

      if (timerName === SERIOUS_ANALYTICS_TIMER_DILEMMA) {
        if (!this.analyticsDilemmaTimerRunning) {
          return;
        }
        this.analyticsDilemmaTimerRunning = false;

        this.$seriousAnalytics.stopTimer(timerName, { dilemmaId }).then((e) => {
          this.callingTimer = false;

          this.log(`Timer.DilemmaEnd.Submitted: ${e.id}`);
        }).catch((e) => {
          this.log(`Timer.DilemmaEnd.Error: ${e.message}`);

          this.callingTimer = false;
        });
      }
      if (timerName === SERIOUS_ANALYTICS_TIMER_GAME_SESSION) {
        if (!this.analyticsOverallTimerRunning) {
          return;
        }
        this.analyticsOverallTimerRunning = false;

        this.$seriousAnalytics.stopTimer(timerName).then((e) => {
          this.callingTimer = false;

          this.log(`Timer.GameSession.Submitted: ${e.id}`);
        }).catch((e) => {
          this.log(`Timer.GameSession.Error: ${e.message}`);

          this.callingTimer = false;
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "sass/main.scss";

#app-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top center;

  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  //padding-left: 20px;
  //padding-right: 2px;

  transition-property: background-color;
  //transition-duration: 600ms; // This is set dynamically through the computed style property
  transition-timing-function: ease-in-out;
  transition: background 0.4s ease-out;

  .knowledge-vault-wrapper {
    position: absolute;
    width: 100%;
    z-index: 1000000;
    height: 100%;
    top: 0;
    left: 0;
  }

  // Backgrounds
  &.background-white {
    background-color: #F8F8F8;
  }

  &.background-farm {
    background-image: url("~@/assets/img/bg/bg_country_cropped.jpg");
  }

  &.background-room-1 {
    background-image: url("~@/assets/img/bg/bg1.jpg");
  }

  &.background-crops {
    background-image: url("~@/assets/img/bg/bg_fields_cropped.jpg");
  }

  &.background-phase-1 {
    background-image: url("~@/assets/img/bg/bg_phase_1.jpg");
  }

  &.background-phase-2 {
    background-image: url("~@/assets/img/bg/bg_phase_2.jpg");
  }

  &.background-phase-3 {
    background-image: url("~@/assets/img/bg/bg_phase_3.jpg");
  }

  &.background-phase-4 {
    background-image: url("~@/assets/img/bg/bg_phase_4.jpg");
  }

  &.background-phase-5 {
    background-image: url("~@/assets/img/bg/bg_phase_5.jpg");
  }

  &.background-gondwana {
    background-image: url("~@/assets/img/bg/bg_gondwana.jpg");
  }

  &.background-dark-gondwana {
    background-image: url("~@/assets/img/bg/bg_dark-gondwana.jpg");
  }

  &.background-blue-gondwana {
    background-image: url("~@/assets/img/bg/bg_blue-gondwana.jpg");
  }

  &.background-plane {
    background-image: url("~@/assets/img/bg/bg_plane.jpg");
  }

  &.background-overview {
    background-image: url("~@/assets/img/bg/bg_overview.jpg");
  }

  &.background-office {
    background-image: url("~@/assets/img/bg/bg_office.jpg");
  }

  &.background-plane-phone {
    background-image: url("~@/assets/img/bg/bg_plane_phone.jpg");
  }

  &.background-blue-gradient {
    background-image: url("~@/assets/img/bg/bg_blue_gradient.jpg");
  }

  &.background-boss-party {
    background-image: url("~@/assets/img/bg/bg_boss-fight-party.jpg");
  }

  &.background-bg-1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_1.jpg");
  }

  &.background-bg-2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_2.jpg");
  }

  &.background-bg-3 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_3.jpg");
  }

  &.background-bg-4 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_4.jpg");
  }

  &.background-bg-5 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_5.jpg");
  }

  &.background-bg-6 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_6.jpg");
  }

  &.background-bg-7 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_7.jpg");
  }

  &.background-bg-8 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_8.jpg");
  }

  &.background-bg-9 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_9.jpg");
  }

  &.background-bg-10 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_10.jpg");
  }

  &.background-bg-11 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_11.jpg");
  }

  &.background-bg-12 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url("~@/assets/img/bg/bg_12.jpg");
  }

  &.background-buffet {
    background-image: url("~@/assets/img/bg/bg_buffet.jpg");
  }

  &.background-boss-fight {
    background-image: url("~@/assets/img/bg/bg_boss-fight.jpg");
  }

  &.background-mission-1 {
    background-image: url("~@/assets/img/bg/bg_mission1.jpg");
  }

  &.background-mission-2 {
    background-image: url("~@/assets/img/bg/bg_mission2.jpg");
  }

  &.background-door {
    background-image: radial-gradient(circle, rgba(102,71,71,0.69) 0%, rgba(124,21,21,0) 55%), url("~@/assets/img/bg/bg_door.jpg");
  }

  &.background-dilemma-policy-select {
    background-image: url("~@/assets/img/bg/bg_dilemma_policy_select.jpg");
  }
}

@media print {
  #app-container {
    background-color: transparent !important;
    position: relative;
    overflow-y: visible;
  }
}
</style>
