<template>
  <button :class="btnClass" :disabled="disabled" class="btn choice" @click.prevent.stop="onClick">
    <span class="choice-contents" v-html="btnTextHmtl">
    </span>
    <!--<span class="choice-cost" v-if="cost">{{ cost }}</span>-->
    <span class="choice-tick" v-if="!isKeepListeningButton"/>
  </button>
</template>
<script>
import textToHtml from '@/utils/textToHtml';

export default {
  name: 'ChoiceButton',
  props: {
    cost: {
      type: Number,
      required: true,
    },
    btnText: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    btnTextHmtl() {
      return textToHtml(this.$t(this.btnText), this.$t);
    },
    btnClass() {
      const classes = [];
      if (this.isKeepListeningButton) {
        classes.push('keep-listening');
      }
      if (this.disabled) {
        classes.push('disabled');
      }
      return classes;
    },
    isKeepListeningButton() {
      return this.btnText === '{KEEP_LISTENING}';
    },
  },
  methods: {
    onClick() {
      if (this.disabled) {
        return;
      }
      this.$emit('click');
    },
  },
}
</script>
<style lang="scss" scoped>
$choiceColor: #F4F4F4;
$tickSize: 25px;
$tickSizeSmall: 15px;
$tickDistanceRight: 15px;

$minWidth: ($tickSize * 2) + $tickDistanceRight;

.choice {
  background: $choiceColor;
  border-radius: 6px;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  min-width: $minWidth;
  width: 200px;
  height: 100%;
  transition-property: all;
  padding: 0;
  margin-bottom: 20px;

  .choice-cost {
    position: absolute;
    top: -15px;
    right: -15px;

    display: inline-block;
    border: 3px solid red;
    background: #333333;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    line-height: 24px;
    text-align: center;
  }

  &:active {
    box-shadow: rgba(99, 99, 99, 0.25) 0px 10px 10px -6px inset, rgba(0, 0, 0, 0.3) 0px 10px 10px -6px inset !important;

    .choice-tick {
      bottom: 0 !important;
      border-width: 0 0 0 0 !important;
    }

    .choice-contents {
      // box-shadow: rgba(99, 99, 99, 0.25) 0px 10px 10px -6px inset, rgba(0, 0, 0, 0.3) 0px 10px 10px -6px inset !important;
    }
  }

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.7) 0px 2px 8px 0px;
    background-color: darken($choiceColor, 10%);

    .choice-tick {
      bottom: -$tickSize;
      border-width: 0 $tickSize $tickSize 0;
      border-color: transparent darken($choiceColor, 10%) transparent transparent;
    }
  }
}

.keep-listening {

}

.disabled {
  opacity: 0.4;
  cursor: default !important;
}

.choice-contents {
  position: relative;
  top: -2px;
  display: inline-block;
  text-align: center;
  padding: 14px 10px 6px 10px;
  margin: 0;
  width: 100%;
  overflow: hidden;
}

.choice-tick {
  position: absolute;
  bottom: -$tickSizeSmall;
  right: $tickDistanceRight;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 $tickSizeSmall $tickSizeSmall 0;
  border-color: transparent $choiceColor transparent transparent;

  transition-property: bottom, right, border-bottom-width, border-left-width, border-right-width, border-top-width;
}

.choice, .choice-tick, .choice-contents {
  transition-duration: 200ms;
  transition-timing-function: ease-out;
}
</style>
