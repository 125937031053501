import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import App from './App.vue';
import createSeriousAnalytics from "@/plugins/SeriousAnalytics/util/createSeriousAnalytics";

import router from './router';
import store from './store';
import localMessages from './lib/i18n/index.js';

// 2. Create i18n instance with options
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: localMessages,
});

// 4. Install i18n instance to make the whole app i18n-aware

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n);

const seriousAnalytics = createSeriousAnalytics({

  apiKey: process.env.VUE_APP_SERIOUS_ANALYTICS_API_KEY || window.seriousAnalyticsApiKey || null,
  baseUrl: process.env.VUE_APP_SERIOUS_ANALYTICS_API_URL || 'https://serious-analytics.seriousgames.net/api/',
  enableDebugging: true,

});

app.use(seriousAnalytics);

app.mount('#app');
