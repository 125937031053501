<script>
import { mapState } from 'vuex';
import extend from 'extend';
import StoryMessage from './StoryMessage';
import StoryChoices from './StoryChoices';
import { STORY_MESSAGE_SPEAKER_THEM, STORY_MESSAGE_SPEAKER_US } from './enum/StoryMessageSpeaker';
import { timeoutPromise } from '../../utils/promiseUtil';
import { randomBetween } from '../../utils/randomUtil';
import Accordion from '../common/Accordion';
import getConsole from '../../utils/getConsole';
import Story from '../../lib/StoryDialogue';
import {
  VARIABLE_TYPE_CONDITIONAL,
  VARIABLE_TYPE_SCENARIO_1_SEEN,
  VARIABLE_TYPE_IDP_OPPORTUNITY_ACCEPTED,
  VARIABLE_TYPE_SCENARIO_2_SEEN,
  VARIABLE_TYPE_SCENARIO_3_SEEN,
  VARIABLE_TYPE_IDP_OPPORTUNITY_DENIED,
  VARIABLE_TYPE_SCENARIO_1_NOT_SEEN,
  VARIABLE_TYPE_SCENARIO_2_NOT_SEEN,
  VARIABLE_TYPE_SCENARIO_3_NOT_SEEN,
  VARIABLE_TYPE_SCENARIO_1_SELECTED,
  VARIABLE_TYPE_SCENARIO_2_SELECTED,
  VARIABLE_TYPE_SCENARIO_3_SELECTED,
  VARIABLE_TYPE_SUPER_POINT_LOW,
  VARIABLE_TYPE_SUPER_POINT_HIGH,
  VARIABLE_TYPE_ARGUMENT_BOSS_FIGHT_ID,
} from '../../enum/VariableType';
import { EVIDENCE_POINTS_FOR_POSITIVE_DG } from '../../data/evaluationData/scoreConstants';

export default {
  name: 'Story',
  components: {
    Accordion,
    StoryMessage,
    StoryChoices,
  },
  props: {
    externalStorySource: {
      type: [String, Object],
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showHistory: true,
      typingDuration: 1350,
      addChosenChoicesToHistory: true,
      showDebugInfo: false,

      isStoryDone: false,

      storySourceHasLoaded: false,
      startStakeholderProgressHasLoaded: false,

      storySource: null,
      startCurrentMessageIndex: null,
      startCurrentChoiceIndex: null,
      startStoryMessageHistory: null,
      startIsCurrentKnowledgeVaultItemSaved: null,

      shouldUseArgument: false,
      currentArgumentId: null,

      loading: true,
      story: null,

      messageTimeoutPromiseActive: false,
      isFirstMessage: true,

      speakerIsTyping: false,
      storyMessageHistory: [],
      lastChoiceIndex: null,

      currentChoices: [],
      currentSpeaker: null,
      currentData: [],
      lastChosenChoice: {},

      console: getConsole('Story', '#55ffff'),

      currentTags: [],
      variables: {},
      currentChosenVariableChanges: {},
    };
  },
  watch: {
    externalStorySource() {
      this.storySource = this.externalStorySource;
    },
    storySource() {
      this.storySourceHasLoaded = true;
      this.load();
    },
    startCurrentStakeholderProgress() {
      this.startStakeholderProgressHasLoaded = true;
      this.load();
    },
    'story.currentMessage': function () {
      const { currentMessage } = this.story;

      if (!currentMessage || currentMessage === undefined) {
        return null;
      }

      const { speakerId } = currentMessage;

      let currentSpeaker;

      for (const speaker of this.story.speakers) {
        if (speaker.id === speakerId) {
          currentSpeaker = speaker;

          break;
        }
      }

      this.currentSpeaker = currentSpeaker;
      this.currentData = currentMessage.data;
    },
  },
  computed: {
    ...mapState([
      'idpOpportunityAccepted',
      'scenario1Seen',
      'scenario2Seen',
      'scenario3Seen',
      'evidencePointsBossFight',
      'selectedScenarioIndex',
    ]),
    lastStoryMessage() {
      const storyMessageHistoryLength = this.storyMessageHistory.length;
      return this.storyMessageHistory[storyMessageHistoryLength - 1];
    },
    showAnticipationDots() {
      if (this.isFirstMessage) {
        return false;
      }
      return this.messageTimeoutPromiseActive;
    },
  },
  mounted() {
    // this.load();
  },
  methods: {
    load() {
      /* if (!(this.storySourceHasLoaded && this.startStakeholderProgressHasLoaded)) {
        this.console.error('Required things not loaded');

        return;
      } */

      if (!this.storySource) {
        this.console.error('Story source was empty');

        return;
      }

      this.loading = true;

      if (typeof (this.storySource) === 'string') {
        this.console.log(`Story source of ${this.storySource} was a string, so loading the URL..`);

        // It's a string, therefore it's a URL
        fetch(this.storySource)
          .then((response) => response.text())
          .then(function (storySource) {
            this.startStory(storySource);
          });
      } else {
        this.startStory(this.storySource);
      }
    },

    onVariableChanged(variable, value) {
      this.__onVariableChanged(variable, value);
    },

    __onVariableChanged(variable, value) {
      this.variables[variable] = value;
    },

    startStory(story) {
      // this.playAreaDom.find('p').remove();

      this.story = new Story(story);

      // If a previous state was saved, then set values for that state
      if (this.startCurrentStakeholderProgress && this.startCurrentStakeholderProgress.currentIndex !== 0) {
        this.storyMessageHistory = this.startCurrentStakeholderProgress.storyMessageHistory;

        this.currentChoices = this.startCurrentStakeholderProgress.currentChoices;
        this.lastChoiceIndex = this.startCurrentStakeholderProgress.currentChoiceIndex;
        this.story.SetCurrentMessageIndex(this.startCurrentStakeholderProgress.currentIndex);
      }

      const globalVariables = this.story.variablesState?._globalVariables || [];

      console.log('globalVariables', globalVariables);

      for (const globalVariable of globalVariables) {
        const variable = globalVariable[0];
        const { value } = globalVariable[1];

        this.onVariableChanged(variable, value);

        this.story.ObserveVariable(variable, this.onVariableChanged);

        // console.log('Observing variable', variable);
      }

      // The story should do this to look at cost!
      // this.story.ObserveChoice(this.onPickChoice);

      this.console.log('Starting story', this.story);

      this.loading = false;

      this.continueToNextChoice();
    },
    continueToNextChoice() {
      if (!this.story.canContinue) {
        this.console.warn('continueToNextChoice was called, but canContinue was false');
        return;
      }

      this.currentChoices = [];
      this.currentTags = [];
      this.story.Continue();

      // end story if the story ends with player input and not NPC message
      if (!this.story.currentText) {
        this.console.warn('Story ended on user input');
        this.$emit('conversationDone');
        return;
      }

      const speakerText = this.sanitizeText(this.story.currentText);

      if (this.lastChoiceIndex !== null) {
        const lastChoiceText = this.storyMessageHistory[this.lastChoiceIndex].text;
        // console.log("speakerText === lastChoiceText", this.sanitizeText(this.story.currentText) === this.storyMessageHistory[this.lastChoiceIndex].text);

        if (speakerText === lastChoiceText) {
          // It's the same message, let us just continue already and ignore
          this.continueToNextChoice();
          return;
        }
      }

      // console.log('figuring out typing duration, need text', this.storyMessageHistory?.[this.lastChoiceIndex]?.text);

      const playerAnswer = this.storyMessageHistory?.[this.lastChoiceIndex]?.text;
      const btnText = this.storyMessageHistory?.[this.lastChoiceIndex]?.btnText;
      const lengthOfPlayerAnswer = playerAnswer?.length;

      const { typingDuration } = this;

      let typingDurationRandom = 0;

      if (typingDuration) {
        const random = randomBetween(0.95, 1.05);

        typingDurationRandom = Math.round(typingDuration * random);

        if (lengthOfPlayerAnswer) {
          typingDurationRandom += lengthOfPlayerAnswer * 45;
        }

        // certain tags also needs longer typing duration
        if (btnText && btnText.includes('{DATA_ITEM_')) {
          typingDurationRandom += 6500;
        }

        this.speakerIsTyping = true;

        // this.console.log(`Speaker is typing for ${typingDurationRandom}ms`);

        this.messageTimeoutPromiseActive = true;
      }

      timeoutPromise(typingDurationRandom).then(() => {
        this.pushMessage(STORY_MESSAGE_SPEAKER_THEM, speakerText, '', this.story.currentMessage.id);
        this.messageTimeoutPromiseActive = false;
        this.isFirstMessage = false;

        if (this.story.canContinue) {
          this.continueToNextChoice();
        } else {
          if (typingDuration) {
            this.speakerIsTyping = false;
          }

          if (this.story.currentChoices.length === 0) {
            this.$emit('conversationDone');
          }

          for (const choice of this.story.currentChoices) {
            // set shouldUseArgument based on choice text
            if (choice.buttonText.includes('{DATA_ITEM')) {
              this.shouldUseArgument = true;
            }

            // Some choices should not be added based on their variables
            let shouldAddChoice = true;

            const choicePickedVariables = choice.variables;

            for (const variableObject of choicePickedVariables) {
              const variable = variableObject?.variable;
              const value = variableObject?.value;
              const operator = variableObject?.operator;

              if (!variable || !value || !operator) {
                continue;
              }

              if (variable === VARIABLE_TYPE_CONDITIONAL) {
                if (value === VARIABLE_TYPE_IDP_OPPORTUNITY_ACCEPTED) {
                  shouldAddChoice = this.idpOpportunityAccepted;
                } else if (value === VARIABLE_TYPE_IDP_OPPORTUNITY_DENIED) {
                  shouldAddChoice = !this.idpOpportunityAccepted;
                } else if (value === VARIABLE_TYPE_SCENARIO_1_NOT_SEEN) {
                  shouldAddChoice = !this.scenario1Seen;
                } else if (value === VARIABLE_TYPE_SCENARIO_2_NOT_SEEN) {
                  shouldAddChoice = !this.scenario2Seen;
                } else if (value === VARIABLE_TYPE_SCENARIO_3_NOT_SEEN) {
                  shouldAddChoice = !this.scenario3Seen;
                } else if (value === VARIABLE_TYPE_SCENARIO_1_SELECTED) {
                  shouldAddChoice = this.selectedScenarioIndex === 0;
                } else if (value === VARIABLE_TYPE_SCENARIO_2_SELECTED) {
                  shouldAddChoice = this.selectedScenarioIndex === 1;
                } else if (value === VARIABLE_TYPE_SCENARIO_3_SELECTED) {
                  shouldAddChoice = this.selectedScenarioIndex === 2;
                } else if (value === VARIABLE_TYPE_SUPER_POINT_LOW) {
                  shouldAddChoice = this.evidencePointsBossFight < EVIDENCE_POINTS_FOR_POSITIVE_DG;
                } else if (value === VARIABLE_TYPE_SUPER_POINT_HIGH) {
                  shouldAddChoice = this.evidencePointsBossFight >= EVIDENCE_POINTS_FOR_POSITIVE_DG;
                } else {
                  shouldAddChoice = true;
                }
              }

              // if it's an argument we need to set which id it is
              if (variable === VARIABLE_TYPE_ARGUMENT_BOSS_FIGHT_ID) {
                this.currentArgumentId = value;
              }
            }

            if (shouldAddChoice) {
              this.currentChoices.push(extend({}, choice));
            } else {
              // Add a dummy option so the hidden options goToId is respected
              const dummyChoice = {
                text: null,
                buttonText: '{isDummy}',
                cost: 0,
                variables: [],
              };

              this.currentChoices.push(extend({}, dummyChoice));
            }
          }

          for (const tag of this.story.currentTags) {
            this.currentTags.push(tag);
          }
        }
      });
    },
    processVariablesInChoice(choicePickedVariables) {

    },
    getValue() {

    },
    onPickChoice(choiceIndex, dataKey = null) {
      const choicePicked = this.currentChoices[choiceIndex];

      if (!choicePicked) {
        throw new Error(`Could not find choice by index ${choiceIndex}`);
      }

      this.lastChosenChoice = choicePicked;
      if (choicePicked.variables && choicePicked.variables.length > 0) {
        this.processVariablesInChoice(choicePicked.variables);
      }

      const choiceText = choicePicked.text;
      const choiceBtnText = choicePicked.buttonText;

      if (this.addChosenChoicesToHistory) {
        this.pushMessage(STORY_MESSAGE_SPEAKER_US, choiceText, choiceBtnText, null, choiceIndex);
      }

      this.console.log(`Choosing choice by index ${choiceIndex}`, this.addChosenChoicesToHistory);

      this.story.ChooseChoiceIndex(choiceIndex);

      this.$nextTick(() => {
        this.continueToNextChoice();

        const newStatus = {
          currentChoiceIndex: choiceIndex,
          currentIndex: this.story.currentMessageIndex,
          currentChoices: this.currentChoices,
          storyMessageHistory: this.storyMessageHistory,
        };

        // this.$store.commit(SET_DILEMMA_STAKEHOLDER_PERSON_PROGRESS_STATUS, newStatus);
      });
    },
    pushMessage(speaker, messageText, messageBtnText, id, choicePickedIndex = null) {
      this.console.log('pushMessage', speaker, messageText, messageBtnText, id);

      const index = this.storyMessageHistory.length;
      messageText = this.sanitizeText(messageText);
      messageBtnText = messageBtnText ? this.sanitizeText(messageBtnText) : '';

      const speakerInfo = {};
      if (speaker === STORY_MESSAGE_SPEAKER_THEM) {
        speakerInfo.id = this.currentSpeaker.id;
        speakerInfo.name = this.currentSpeaker.name;
        speakerInfo.role = this.currentSpeaker.role;
      }

      const storyMessage = {
        id,
        text: messageText,
        btnText: messageBtnText,
        speaker,
        speakerInfo,
        index,
        open: false,
        choicePickedIndex,
      };

      this.console.log(`Pushing story message by index ${storyMessage.index}`, storyMessage);

      this.storyMessageHistory.push(storyMessage);

      this.lastChoiceIndex = index;

      if (this.showHistory) {
        this.$nextTick(() => {
          this.storyMessageHistory[index].open = true; // Open so the accordion moves
        });
      }
    },
    sanitizeText(text) {
      return text.replace(/^\s+|\s+$/g, '', text);
    },
  },
};
</script>
