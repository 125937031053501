export default class Story {
  variableObservers = [];
  choiceObserver = null;

  variables = {};
  variableTypes = {};

  messageIdIndexAssoc = {};
  currentMessageIndex = null;
  messages = [];
  speakers = [];

  chosenChoiceIndex = null;

  constructor(story) {
    this.messages = story.messages;
    this.speakers = story.speakers;

    for (let variableName in story.variables) {
      const variable = story.variables[variableName];

      this.variables[variable.variable] = variable.initialValue;
      this.variableTypes[variable.variable] = variable.types;
    }

    for (let i = 0, len = story.messages.length; i < len; i++) {
      const message = story.messages[i];

      this.messageIdIndexAssoc[message.id] = i;
    }

    this.SetCurrentMessageIndex(this.currentMessageIndex);
  }

  /** The only purpose for this is to be compatible with inky */
  get variablesState() {
    const globalVariables = [];

    const variableNames = Object.keys(this.variables);

    for (let variableName of variableNames) {
      const variableValue = this.variables[variableName];

      globalVariables.push([variableName, { value: variableValue }]);
    }

    return {
      _globalVariables: globalVariables,
    };
  }

  get currentChoice() {
    if (this.chosenChoiceIndex === null) {
      return null;
    }

    return this.currentChoices[this.chosenChoiceIndex];
  }

  get currentMessage() {
    /** id, choices, message, speakerId */
    return this.messages[this.currentMessageIndex];
  }

  get canContinue() {
    return this.currentMessageIndex === null
      || (this.currentChoices.length > 0 && this.chosenChoiceIndex !== null);
  }

  get currentSpeaker() {
    const speakerId = this.currentMessage?.speakerId;

    if (speakerId >= 1) {
      for (const speaker of this.speakers) {
        if (speakerId === speaker.id) {
          return speaker;
        }
      }
    }

    return null;
  }

  get currentText() {
    return this.currentMessage?.message;
  }

  get currentChoices() {
    return this.currentMessage?.choices || [];
  }

  get currentTags() {
    return this.currentMessage?.tags || [];
  }

  ParseVariable(variable) {
    /** operator, value, variable **/
    const previousValue = Object.keys(this.variables).includes(variable.variable)
      ? this.variables[variable.variable]
      : null;

    let newValue = previousValue;

    if (variable.operator === '-=') {
      newValue -= variable.value;
    } else if (variable.operator === '+=') {
      newValue += variable.value;
    } else if (variable.operator === '*=') {
      newValue *= variable.value;
    } else if (variable.operator === '/=') {
      newValue /= variable.value;
    } else if (variable.operator === '++') {
      newValue += 1;
    } else if (variable.operator === '--') {
      newValue -= 1;
    } else if (variable.operator === '=') {
      newValue = variable.value;
    }

    if (previousValue !== newValue) {
      const variableObserverVariables = Object.keys(this.variableObservers);

      console.log(`Variable operation (${variable.variable} ${variable.operator} ${variable.value}) - Previous value: ${previousValue} - New value: ${newValue}`);
      this.variables[variable.variable] = newValue;

      if (variableObserverVariables.includes(variable.variable)) {
        // Call observer as (variableName, newValue, previousValue)
        for (const variableObserver of this.variableObservers[variable.variable]) {
          variableObserver(variable.variable, newValue, previousValue);
        }
      }

      if (variableObserverVariables.includes('*')) {
        for (const variableObserver of this.variableObservers['*']) {
          variableObserver(variable.variable, newValue, previousValue);
        }
      }
    }
  }

  OnNewMessage(message) {
    this.chosenChoiceIndex = null;

    if (!message) {
      return;
    }

    if (message.variables && message.variables.length) {
      for (let variable of message.variables) {
        this.ParseVariable(variable);
      }
    }
    //console.log('ParseMessage', message);
  }

  ObserveChoice(callback) {
    this.choiceObserver = callback || null;
  }

  ObserveVariable(variable, callback) {
    if (!variable) {
      throw new Error('Cannot observe an empty variable - if you want to observe all variables use "*"');
    }

    if (!Object.keys(this.variableObservers).includes(variable)) {
      this.variableObservers[variable] = [];
    }

    this.variableObservers[variable].push(callback);
  }

  Continue() {
    if (!this.canContinue) {
      throw new Error('Tried to continue but "canContinue" was false');
    }

    if (this.currentMessageIndex === null) {
      // Haven't shown the first message yet - let's start!
      this.SetCurrentMessageIndex(0);
    } else if (this.currentChoices.length > 0 && this.chosenChoiceIndex !== null) {
      // Chosen a choice
      const chosenChoice = this.currentChoices[this.chosenChoiceIndex];
      if (this.choiceObserver) {
        this.choiceObserver(this.chosenChoiceIndex, chosenChoice);
      }

      let gotoMessageIndex = this.currentMessageIndex + 1;
      const choiceKeys = Object.keys(chosenChoice);

      if (choiceKeys.includes('gotoId')) {
        // zero index it
        const realGotoId = (chosenChoice.gotoId).toString();

        if (this.messageIdIndexAssoc.hasOwnProperty(realGotoId)) {
          gotoMessageIndex = this.messageIdIndexAssoc[realGotoId];
        } else {
          gotoMessageIndex = -1;
        }
      }

      if (choiceKeys.includes('variables') && chosenChoice.variables && chosenChoice.variables.length) {
        for (let variable of chosenChoice.variables) {
          this.ParseVariable(variable);
        }
      }

      this.SetCurrentMessageIndex(gotoMessageIndex);
    } else {
      // No choices to pick, so simply go to the next page
      this.SetCurrentMessageIndex(this.currentMessageIndex + 1);
    }
  }

  SetCurrentMessageIndex(messageIndex) {
    this.currentMessageIndex = messageIndex;

    if (messageIndex >= 0) {
      this.OnNewMessage(this.messages[this.currentMessageIndex]);
    }
  }

  ChooseChoiceIndex(choiceIndex) {
    this.chosenChoiceIndex = choiceIndex;
  }
}
