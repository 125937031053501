<template>
  <div class="image-logo-container-wrapper">
    <div class="image-logo-container logo-vca4d">
      <no-drag-image src="@/assets/img/ui/vca4d_logo_white.png" />
    </div>
    <div class="image-logo-container logo-agrinatura">
      <no-drag-image src="@/assets/img/ui/agrinatura-logo.png" />
    </div>
    <div class="image-logo-container logo-eu">
      <no-drag-image src="@/assets/img/ui/EN_fundedbyEU_Outline.png" />
    </div>
  </div>
</template>
<script>

import NoDragImage from '@/components/common/NoDragImage';
export default {
  name: 'LogoContainer',
  components: { NoDragImage },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>

.image-logo-container-wrapper {
  pointer-events: none;
}

.image-logo-container {
  position: absolute;
  bottom: 15px;
  img {
    width: 100%;
  }

  &.logo-vca4d {
    left: 15px;
    width: 165px;
  }

  &.logo-agrinatura {
    right: 105px;
    width: 85px;
  }

  &.logo-eu {
    right: 15px;
    width: 70px;
  }
}

</style>
