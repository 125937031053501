<template>
  <h1>ChoiceButtonDev</h1>

  <div class="row">
    <ChoiceButtonGroup :choice-buttons="choiceButtons" v-model="currentValue" />
  </div>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4" />

  <div class="row">
    <strong>Active value:</strong> {{ currentValue }}
  </div>
</template>

<script>
import ChoiceButton from "./ChoiceButton";
import EventTicks from "../DevComponent/EventTicks";
import EventTicksComponentMixin from "../DevComponent/EventTicksComponentMixin";
import ChoiceButtonGroup from "./ChoiceButtonGroup";
import DevComponentContainer from "../DevComponent/DevComponentContainer";

export default {
  name: 'ChoiceButtonDev',
  mixins: [EventTicksComponentMixin],

  components: {EventTicks, ChoiceButton, ChoiceButtonGroup },
  extends: DevComponentContainer,
  watch: {
    currentValue() {
      this.triggerEvent('click');
    },
  },
  data() {
    return {
      cost: 10,

      currentValue: null,

      choiceButtons: [
        {
          text: 'Choice text short',
          value: 'short',
          cost: 1,
        },
        {
          text: 'Choice text long Choice text long Choice text long Choice text long',
          value: 'long',
          cost: 1,
        },
        {
          text: 'Choice text newline\nChoice text newline\nChoice text newline',
          value: 'newLine',
          cost: 1,
        },
      ],

      events: ['click'],
    };
  },
};
</script>
