<template>
  <div class="document-pager">
    <div v-for="(vocabularyItem, i) in vocabularyData" :key="i">
      <div class="glossary-entry-wrapper">
        <div class="vocabulary-header d-flex">
          <div class="vocabulary-name">
            {{ $t('misc.name') }}
          </div>
          <div :class="vocabularyItem.bonusTechnicalInsight ? 'vocabulary-content' : 'vocabulary-content-full'">
            {{ $t('misc.content') }}
          </div>
          <div v-if="vocabularyItem.bonusTechnicalInsight" class="vocabulary-bonus">
            {{ $t('misc.bonusInsights') }}
          </div>
        </div>
        <div class="vocabulary-body d-flex">
          <div class="vocabulary-name">
            {{ i }}
          </div>
          <div :class="vocabularyItem.bonusTechnicalInsight ? 'vocabulary-content' : 'vocabulary-content-full'">
            {{ vocabularyItem.technicalInsight }}
          </div>
          <div v-if="vocabularyItem.bonusTechnicalInsight" class="vocabulary-bonus">
            {{ vocabularyItem.bonusTechnicalInsight }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { CONVERSATIONS } from '@/enum/Conversations';

export default {
  name: 'DocumentGlossary',
  components: { },
  data() {
    return {
      vocabularyData: {},
    };
  },
  mounted() {
    this.vocabularyData = CONVERSATIONS.glossaryData;
  },
};
</script>
<style lang="scss" scoped>
.document-pager {
  position: relative;
  max-width: 65vw;
  height: 80%;
  min-width:650px;

  .vocabulary-name {
    padding: 10px 20px;
    width: 20%;
    min-width: 165px;
  }

  .vocabulary-content {
    border-left: 3px solid #2F7E96;

    padding: 10px 20px;
    width: 40%;
  }

  .vocabulary-content-full {
    border-left: 3px solid #2F7E96;

    padding: 10px 20px;
    width: 80%;
  }

  .vocabulary-bonus {
    width: 40%;

    padding: 10px 20px;
    border-left: 3px solid #2F7E96;
  }

  .glossary-entry-wrapper {
    padding-bottom: 20px;

    .vocabulary-header {
      z-index: 999999;
      right: -36%;

      color: #fff;
      background-color: #2F7E96;
      font-weight: 600;

      border-radius: 12px 12px 0 0;
    }

    .vocabulary-body {
      z-index: 999999;
      right: -36%;
      border-radius: 0 0 12px 12px;
      border: solid #2F7E96 3px;
    }
  }

  .pager-image {
    position: relative;
    width: 100%;
    img {
      width: 100%;
    }
  }
}

</style>
