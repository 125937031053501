import { EVIDENCE_POINTS_FOR_DG_CHANGE_MIND } from './evaluationData/scoreConstants';

export default [
  {
    type: 'blackTransitionView',
    background: 'office',
    transition: 'medium',
    data: null,
    bottomButtons: [],
  },
  {
    type: 'dynamicTextView',
    background: 'dark-gondwana',
    transition: 'short',
    data: {
      title: 'evalAndPDFlow.1.data.title',
      text: 'evalAndPDFlow.1.data.text',
      dynamicDataID: 'midTermEvaluation',
    },
    bottomButtons: [
      {
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'blackTransitionView',
    background: 'dark-gondwana',
    transition: 'short',
    data: null,
    bottomButtons: [],
  },
  {
    type: 'situationView',
    background: 'office',
    transition: 'medium',
    data: {
      icon: 'incomingCall',
      text: 'evalAndPDFlow.3.data.text',
    },
    bottomButtons: [
      {
        text: 'misc.pickUpPhone',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'phoneConversationView',
    background: 'office',
    conditionalContinue: true,
    transition: 'short',
    data: {
      phoneBackground: 'phone_bg_chantall.png',
      conversationId: 'phase4step1',
    },
    bottomButtons: [
      {
        isHidden: true,
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'office',
    transition: 'short',
    data: {
      title: 'evalAndPDFlow.5.data.title',
      text: 'evalAndPDFlow.5.data.text',
      showScenarios: true,
      showScenariosData: {
        canPickScenarios: false,
        textAfterScenarios: 'evalAndPDFlow.5.data.showScenariosData.textAfterScenarios',
      },
    },
    bottomButtons: [
      {
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'office',
    transition: 'short',
    conditionalContinue: true,
    data: {
      title: 'evalAndPDFlow.6.data.title',
      text: 'evalAndPDFlow.6.data.text',
      showScenarios: true,
      showScenariosData: {
        canPickScenarios: true,
        textAfterScenarios: 'evalAndPDFlow.6.data.showScenariosData.textAfterScenarios',
      },
    },
    bottomButtons: [
      {
        text: 'persistentSources.0.name',
        type: 'document',
        data: '6pager',
      },
      {
        text: 'evalAndPDFlow.6.bottomButtons.1.text',
        type: 'document',
        data: 'scenarioExplanation',
      },
      {
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'office',
    transition: 'short',
    conditionalContinue: true,
    data: {
      title: 'misc.dataTransformation',
      text: 'evalAndPDFlow.7.data.text',
      showKnowledgeVaultButton: true,
    },
    bottomButtons: [
      {
        text: 'persistentSources.0.name',
        type: 'document',
        data: '6pager',
      },
      {
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'situationView',
    background: 'office',
    transition: 'short',
    data: {
      text: 'evalAndPDFlow.8.data.text',
    },
    bottomButtons: [
      {
        text: 'evalAndPDFlow.8.bottomButtons.0.text',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'emailView',
    background: 'office',
    transition: 'short',
    data: {
      title: 'evalAndPDFlow.9.data.title',
      toFromField: {
        from: 'evalAndPDFlow.9.data.toFromField.from',
        to: 'misc.emailToMe',
        when: 'misc.emailDate',
      },
      emailText: 'evalAndPDFlow.9.data.emailText',
      senderInfo: 'evalAndPDFlow.9.data.senderInfo',
      attachments: [],
    },
    bottomButtons: [
      {
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'blackTransitionView',
    background: 'office',
    transition: 'medium',
    data: null,
    bottomButtons: [],
  },
  {
    type: 'situationView',
    background: 'door',
    transition: 'short',
    data: {
      text: 'evalAndPDFlow.11.data.text',
    },
    bottomButtons: [
      {
        text: 'evalAndPDFlow.11.bottomButtons.0.text',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'blackTransitionView',
    background: 'door',
    transition: 'medium',
    data: null,
    bottomButtons: [],
  },
  {
    type: 'bossFightConversationView',
    background: 'boss-fight',
    conditionalContinue: true,
    transition: 'short',
    data: {
      phoneBackground: 'phone_bg_red_room.png',
      conversationId: 'phase5step1',
    },
    bottomButtons: [
      {
        isHidden: true,
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'situationView',
    background: 'office',
    transition: 'medium',
    data: {
      icon: 'incomingCall',
      text: 'evalAndPDFlow.14.data.text',
    },
    bottomButtons: [
      {
        text: 'misc.pickUpPhone',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'phoneConversationView',
    background: 'office',
    conditionalContinue: true,
    transition: 'short',
    conditionalShowPage: {
      conditional: 'evidencePointsBossFight',
      operator: '<',
      value: EVIDENCE_POINTS_FOR_DG_CHANGE_MIND,
    },
    data: {
      phoneBackground: 'phone_bg_chantall.png',
      conversationId: 'phase5step2option1',
    },
    bottomButtons: [
      {
        isHidden: true,
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'phoneConversationView',
    background: 'office',
    conditionalContinue: true,
    transition: 'short',
    conditionalShowPage: {
      conditional: 'evidencePointsBossFight',
      operator: '>=',
      value: EVIDENCE_POINTS_FOR_DG_CHANGE_MIND,
    },
    data: {
      phoneBackground: 'phone_bg_chantall.png',
      conversationId: 'phase5step2option2',
    },
    bottomButtons: [
      {
        isHidden: true,
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'office',
    transition: 'short',
    conditionalContinue: true,
    data: {
      title: 'misc.dataTransformation',
      text: 'evalAndPDFlow.17.data.text',
      showKnowledgeVaultButton: true,
    },
    bottomButtons: [
      {
        text: 'persistentSources.0.name',
        type: 'document',
        data: '6pager',
      },
      {
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'situationView',
    background: 'office',
    transition: 'black',
    data: {
      text: 'evalAndPDFlow.18.data.text',
    },
    bottomButtons: [
      {
        text: 'evalAndPDFlow.18.bottomButtons.0.text',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'bossFightConversationView',
    background: 'boss-fight',
    conditionalContinue: true,
    transition: 'short',
    data: {
      phoneBackground: 'phone_bg_red_room.png',
      conversationId: 'phase5step3',
    },
    bottomButtons: [
      {
        isHidden: true,
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'blue-gondwana',
    transition: 'short',
    conditionalShowPage: {
      conditional: 'dealAccepted',
      operator: '===',
      value: false,
    },
    data: {
      title: 'misc.congratulationsExclamation',
      text: 'evalAndPDFlow.20.data.text',
    },
    bottomButtons: [
      {
        text: 'misc.VCA4DScores',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'boss-party',
    transition: 'short',
    conditionalShowPage: {
      conditional: 'dealAccepted',
      operator: '===',
      value: true,
    },
    data: {
      textRight: true,
      title: 'misc.congratulationsExclamation',
      text: 'evalAndPDFlow.21.data.text',
    },
    bottomButtons: [
      {
        text: 'misc.VCA4DScores',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'finalScoresView',
    background: 'blue-gradient',
    transition: 'short',
    data: {},
    bottomButtons: [
      {
        text: 'misc.whyVCA4D',
        type: 'progressFlow',
        seriousAnalytics: 'endApp',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'blue-gradient',
    transition: 'short',
    data: {
      title: 'misc.whyVCA4D',
      text: 'evalAndPDFlow.23.data.text',
    },
    bottomButtons: [
      {
        text: 'misc.continue',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'blue-gradient',
    transition: 'short',
    data: {
      title: 'evalAndPDFlow.24.data.title',
      text: 'evalAndPDFlow.24.data.text',
      showReplayBossFightButton: true,
    },
    bottomButtons: [
      {
        text: 'evalAndPDFlow.24.bottomButtons.0.text',
        type: 'download',
        data: 'technicalInsights',
      },
      {
        text: 'evalAndPDFlow.24.bottomButtons.1.text',
        type: 'download',
        data: 'myScore',
      },
      {
        text: 'evalAndPDFlow.24.bottomButtons.2.text',
        type: 'progressFlow',
      },
    ],
  },
  {
    type: 'infoView',
    background: 'blue-gradient',
    transition: 'short',
    showLogos: true,
    data: {
      title: 'evalAndPDFlow.25.data.title',
      text: 'evalAndPDFlow.25.data.text',
    },
    bottomButtons: [
      {
        text: 'evalAndPDFlow.25.bottomButtons.0.text',
        type: 'feedback',
      },
      {
        text: 'evalAndPDFlow.25.bottomButtons.1.text',
        type: 'replayGame',
      },
    ],
  },
];
