<template>
  <div :id="id" class="document-source-button-wrapper" :class="buttonClasses" @click="onClickButton">
    <div v-if="isLocked" class="locked-element">
      <div class="locked-element-image-wrapper">
        <NoDragImage :src="getImageSrc('large_lock_shadow')" />
      </div>
    </div>
    <div class="document-source-button-image-wrapper">
      <NoDragImage :src="imgSrc" />
    </div>

    <div class="document-source-button-text-wrapper">
      <p>{{ $t(name) }}</p>
    </div>

    <div v-if="isFolderButton" class="folder-container" :class="folderButtonClasses">
      <div class="shadow-element" />
      <div v-for="(folder, i) in folders" :key="i">
        <AppButton color="light_grey" :disabled="isSourceLocked(folder?.requiredTutorialKey)" size="fill" @click="onClickFolderButton(folder.type, folder.documentId)">
          {{ $t(folder.name) }}
        </AppButton>
      </div>
    </div>
  </div>
</template>
<script>

import NoDragImage from '@/components/common/NoDragImage';
import { SOURCE_FOLDER } from '@/enum/SourceTypes';
import AppButton from '@/components/common/AppButton';
import { mapState } from 'vuex';

export default {
  name: 'DocumentSourceButton',
  components: {
    AppButton,
    NoDragImage,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    pictureId: {
      type: String,
      default: null,
    },
    openPictureId: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    dataId: {
      type: String,
      required: true,
    },
    folders: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['openSource'],
  data() {
    return {
      areFoldersOpen: false,
    };
  },
  computed: {
    ...mapState([
      'vca4dDocumentsOrdered',
    ]),
    imgSrc() {
      const images = require.context('../../assets/img/ui');
      let imgSrc;
      imgSrc = images(`./${this.pictureId}.png`);
      if (this.isFolderButton && this.areFoldersOpen) {
        imgSrc = images(`./${this.openPictureId}.png`);
      }
      return imgSrc;
    },
    isFolderButton() {
      return this.type === SOURCE_FOLDER;
    },
    backgroundColorClass() {
      return `background-${this.backgroundColor}`;
    },
    folderButtonClasses() {
      const buttonClasses = [
        this.backgroundColorClass,
      ];

      if (this.areFoldersOpen) {
        buttonClasses.push('open');
      } else {
        buttonClasses.push('closed');
      }

      return buttonClasses;
    },
    buttonClasses() {
      const buttonClasses = [
        this.backgroundColorClass,
      ];

      if (this.isLocked) {
        buttonClasses.push('locked');
      }

      return buttonClasses;
    },
  },
  methods: {
    getImageSrc(img) {
      const images = require.context('../../assets/img/ui');
      const imgSrc = images(`./${img}.png`);
      return imgSrc;
    },
    onClickButton() {
      if (this.disabled || this.isLocked) {
        return;
      }

      if (this.type === SOURCE_FOLDER) {
        this.areFoldersOpen = !this.areFoldersOpen;
        return;
      }

      this.$emit('openSource', this.type, this.dataId);
    },
    onClickFolderButton(type, dataId) {
      this.areFoldersOpen = false;
      this.$emit('openSource', type, dataId);
    },
    isSourceLocked(requiredTutorialKey) {
      if (!requiredTutorialKey) {
        return false;
      }
      if (requiredTutorialKey === 'vca4dDocumentsOrdered') {
        return !this.vca4dDocumentsOrdered;
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
  .document-source-button-wrapper {
    margin: 5px 0;
    margin-bottom: 20px;
    text-align: center;
    cursor: pointer;
    height: calc(33vh - 130px);
    max-height: 192px;
    // height: 192px;
    width: 192px;
    border-radius: 12px 0 0 12px;

    position: relative;

    .locked-element {
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.59);
      top: 0;
      right: 0;
      z-index: 9999;
      border-radius: 12px;

      .locked-element-image-wrapper {
        height: 107px;
        width: 87px;
        position: absolute;
        left: 53%;
        top: 68%;
        transform: translate(-50%, -50%);

        img{
          opacity: 1;
        }
      }
    }

    .folder-container {
      position: absolute;
      bottom: 0;
      left: calc(-30vh - 20px);
      border-radius: 12px;
      height: fit-content;
      padding: 15px;
      width: 30vh;
      box-shadow: -2px 15px 20px 0px rgb(0, 0, 22, 70%);

      transition-property: all;
      transition-duration: 450ms;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);

      &.open {
        transform: rotateY(0);
        transform-origin: right;
        opacity: 1;
      }

      &.closed  {
        pointer-events: none;
        transform: rotateY(-100deg);
        transform-origin: right;
        opacity: 0;
      }

    }

    .shadow-element {
      box-shadow: 1px -75px 76px -26px rgba(68,68,68,1) inset;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 12px;
      top: 0;
      left: 0;
    }

    .document-source-button-image-wrapper {
      overflow: hidden;
      position: absolute;
      bottom: 35%;
      transform: translateY(50%);
      height: 70%;

      img {
        width: 100%;
        margin-top: -8px;
      }
    }

    .document-source-button-text-wrapper {
      font-weight: 700;
      position: absolute;
      top: 0px;
      height: 48px;
      width: 100%;
      padding: 10px;
      border-radius: 12px 0 0 0;
    }
  }

  .locked {
    background-color: rgba(66,150,53, 0.4) !important;
    box-shadow: none !important;
    p {
      opacity: 0.7;
    }

    .document-source-button-text-wrapper {
      opacity: 0.7;
      background-color: rgba(66,150,53, 0.4) !important;
    }

    cursor: default;
    img {
      opacity: 0.7;
      width: 80% !important;
    }
  }
  .locked::after {
    // background: rgb(36,118,146);
    // background: radial-gradient(circle, rgba(36,118,146,0.61) 15%, rgba(71,95,102,0.69) 76%) !important;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #0000aa;

  }

  .background-orange {
    background-color: rgba(215,116,0, 0.7);
    box-shadow: 0 6px 0 0 #483420, 4px 10px 10px 0px rgb(0, 0, 22, 70%);

    .document-source-button-text-wrapper {
      background-color: #D77400;
    }
  }
  .background-blue {
    background-color: rgba(28,112,155, 0.7);
    box-shadow: 0 6px 0 0 #1C3341, 4px 10px 10px 0px rgb(0, 0, 22, 70%);
    .document-source-button-text-wrapper {
      background-color: #1C709B;
    }
  }
  .background-green {
    background-color: rgba(66,150,53, 0.7);
    box-shadow: 0 6px 0 0 #223E22, 4px 10px 10px 0px rgb(0, 0, 22, 70%);
    .document-source-button-text-wrapper {
      background-color: #429635;
    }
  }
  .background-dark-green {
    background-color: #005344;
  }
</style>
