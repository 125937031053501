<template>
  <div class="save-button-wrapper">
    <button :id="'save-source-button-' + dataKey" class="save-button" :class="canAddArgument ? '' : 'argument-saved'" :disabled="disabled" @click="onClickButton" />
    <no-drag-image class="save-button-arrow" :class="canAddArgument ? '' : 'argument-saved'" src="../../assets/img/ui/download-arrow.png" />
    <div class="save-button-saved-text" :class="canAddArgument ? '' : 'argument-saved'">
      {{ $t('misc.harvested') }}
      </div>
  </div>
</template>
<script>

import NoDragImage from '@/components/common/NoDragImage';

export default {
  name: 'SaveSourceButton',
  components: { NoDragImage },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    canAddArgument: {
      type: Boolean,
      default: true,
    },
    dataKey: {
      type: String,
      default: '',
    },
  },
  emits: ['argument-added'],
  methods: {
    onClickButton() {
      if (!this.canAddArgument || this.disabled) {
        return;
      }

      this.$emit('argument-added');
    },
  },
};
</script>
<style lang="scss" scoped>

$saveButtonSize: 40px;
$boxShadowOffset: -3px;

.save-button-wrapper {
  position: relative;
  width: 50px;
  height: $saveButtonSize;
  text-align: center;

  .save-button {
    border: none;
    height: $saveButtonSize;
    width: $saveButtonSize;
    border-radius: 50%;
    background-color: #737373;
    -webkit-box-shadow: inset 0px $boxShadowOffset 0 #4d4d4d;
    box-shadow: inset 0 $boxShadowOffset 0 #4d4d4d;
    background-repeat: no-repeat;
    background-size: calc($saveButtonSize / 2) calc($saveButtonSize / 2);
    background-position: center;
    background-image: url("./../../assets/img/ui/download.png");
    position: relative;
    cursor: pointer;
    z-index: 89;
    //transform: translate(-50%, 0);

    &:hover {
      background-color: #535353;
      box-shadow: inset 0 $boxShadowOffset 0 #2d2d2d;
    }

    &:active {
      background-color: #434343;
      box-shadow: inset 0 0 0 #4d4d4d;
    }

    &:disabled {
      opacity: .6;
      cursor: not-allowed;
      box-shadow: none !important;
      background-color: #737373 !important;
      background-image: url("./../../assets/img/ui/download.png");
    }

    &.argument-saved {
      background-color: #4BAFBC;
      box-shadow: inset 0 0 0 #4d4d4d;
      cursor: default;
      background-image: url("./../../assets/img/ui/check.png");
      animation-name: save;
      animation-duration: 1s;
      animation-timing-function: ease-out;
    }

    @keyframes save {
      0% {
        background-color: #737373;
        opacity: 0.8;
      }
      25% {
        opacity: 1;
      }
      100% {
        background-color: #4BAFBC;
      }
    }
  }

  .save-button-arrow {
    height: calc($saveButtonSize * 0.6);
    z-index: 90;
    position: absolute;
    opacity: 0;
    bottom: calc(-#{$saveButtonSize} / 2);
    left: calc(#{$saveButtonSize} / 3.3);
    pointer-events: none;

    &.argument-saved {
      animation-name: showSavedArrow;
      animation-duration: 0.3s;
      animation-timing-function: ease-in-out;
    }

    @keyframes showSavedArrow {
      0%   {
        opacity: 0;
        bottom: 0;
      }
      35%  {opacity: 1;}
      40%  {opacity: 1;}
      100% {
        opacity: 0;
        bottom: calc($saveButtonSize * -1.3);
      }
    }
  }

  .save-button-saved-text {
    width: calc($saveButtonSize * 3.7);
    z-index: 90;
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%, 100%);
    opacity: 0;
    pointer-events: none;
    font-family: 'Fira Sans Condensed', 'Fira Sans', sans-serif;
    font-size: 12px;
    color: #3C6A77;

    &.argument-saved {
      animation-name: showSaved;
      opacity: 1;
      animation-duration: 1s;
      animation-timing-function: ease-out;
    }

    @keyframes showSaved {
      from {opacity: 0}
      to {opacity: 1}
    }
  }
}

</style>
