<template>
  <h1>StoryDialogueDev</h1>

  <div class="row">
    <story
      :story-source="story"
      :dilemma-index="dilemmaIndex"

      :show-history="false"
      :typing-duration="0"
      :add-chosen-choices-to-history="false"
      :show-debug-info="true"
    />
  </div>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4" />

  <div class="row">
    <strong>Active value:</strong> {{ currentValue }}
  </div>
</template>

<script>
import EventTicks from "@/components/DevComponent/EventTicks";
import EventTicksComponentMixin from "@/components/DevComponent/EventTicksComponentMixin";
import DevComponentContainer from "@/components/DevComponent/DevComponentContainer";
import Story from "../Story";

export default {
  name: 'ChoiceButtonDev',
  mixins: [EventTicksComponentMixin],

  components: {EventTicks, Story },
  extends: DevComponentContainer,
  watch: {
    currentValue() {
      this.triggerEvent('click');
    },
  },
  data() {
    return {
      cost: 10,

      story: require('@/data/dilemma1/conversation-person-1.json'),
      dilemmaIndex: 0,

      events: ['click'],
    };
  },
};
</script>
