import { createRouter, createWebHashHistory } from 'vue-router';

import DevView from '@/views/000-Dev.vue';
import HomeView from '@/views/010-Home.vue';
import IntroView from '@/views/001-Intro.vue';
import DilemmaView from '@/views/020-DilemmaView.vue';
import DialogueView from '@/views/030-DialogueView.vue';
import InformalPDView from '@/views/070-InformalPD';
import EvalAndPD from '@/views/080-EvalAndPD';
import DilemmaPolicyChoiceView from '@/views/021-DilemmaPolicyChoiceView';
import TutorialOutroView from '@/views/022-TutorialOutroView';
import DilemmaIntroView from '@/views/023-DilemmaIntroView';

// printing pages
import PrintVocabulary from '@/views/printViews/000-PrintVocabularyView';
import PrintResults from '@/views/printViews/010-PrintResultsView';
import store from '@/store';

import {
  VIEW_OVERVIEW,
  VIEW_DILEMMA,
  VIEW_DIALOGUE,
  VIEW_INTRO,
  VIEW_INFORMAL_PD,
  VIEW_EVAL_AND_PD,
  VIEW_DILEMMA_POLICY_CHOICE,
  VIEW_TUTORIAL_OUTRO, VIEW_DILEMMA_INTRO, VIEW_PRINT_VOCABULARY, VIEW_PRINT_RESULTS,
} from '@/enum/Views.js';
import { SET_ROUTER_CURRENT_PAGE } from '../store/mutation-types';

const isDev = true;

const routes = [
  {
    path: '/',
    name: VIEW_INTRO,
    component: IntroView,
  },
  {
    path: '/overview',
    name: VIEW_OVERVIEW,
    component: HomeView,
  },
  {
    path: '/dilemma',
    name: VIEW_DILEMMA,
    component: DilemmaView,
  },
  {
    path: '/choose-policy',
    name: VIEW_DILEMMA_POLICY_CHOICE,
    component: DilemmaPolicyChoiceView,
  },
  {
    path: '/dilemma-intro',
    name: VIEW_DILEMMA_INTRO,
    component: DilemmaIntroView,
  },
  {
    path: '/tutorial-outro',
    name: VIEW_TUTORIAL_OUTRO,
    component: TutorialOutroView,
  },
  {
    path: '/dialogue',
    name: VIEW_DIALOGUE,
    component: DialogueView,
  },
  {
    path: '/informalPD',
    name: VIEW_INFORMAL_PD,
    component: InformalPDView,
  },
  {
    path: '/finalPD',
    name: VIEW_EVAL_AND_PD,
    component: EvalAndPD,
  },
  {
    path: '/print/c8df5e0fcb76f0046cfb928ee9551eb6',
    name: VIEW_PRINT_VOCABULARY,
    component: PrintVocabulary,
  },
  {
    path: '/print/707e56923a0f9b8ea5cb42b7c17e6db9',
    name: VIEW_PRINT_RESULTS,
    component: PrintResults,
  },
];

if (isDev) {
  routes.push({
    path: '/dev',
    name: 'DevMain',
    component: DevView,
    children: [
      {
        path: ':component',
        name: 'DevMain',
        component: DevView,
      },
    ],
  });
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

let isInitialLoad = true; // flag to track the initial load

router.beforeEach((to, from, next) => {
  const lastRouteName = localStorage.getItem('currentRouterPage');

  console.log('lastRouteName', {
    lastRouteName,
    isInitialLoad,
  });

  if (isInitialLoad && !lastRouteName) {
    isInitialLoad = false; // Reset the flag
    next({ name: VIEW_INTRO }); // Redirect to the intro view
  } else if (isInitialLoad && lastRouteName && to.name !== lastRouteName) {
    isInitialLoad = false; // Reset the flag
    next({ name: lastRouteName }); // Redirect to the stored route
  } else {
    if (!isInitialLoad && to.name) {
      store.commit(SET_ROUTER_CURRENT_PAGE, to.name);
    }

    next();
  }
});

export default router;
