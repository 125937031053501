<template>
  <div class="data-break-module-container">
    <div :id="'data-break-module-' + dataKey" class="data-wrapper" :class="showShadow ? 'show-shadow' : ''">
      <div class="data-title-container" :class="dataTitleClasses" @click="toggleAccordion">
        <p>{{dataName}}</p>
      </div>
      <accordion :open="isAccordionOpen" :ref="'accordian'">
        <div class="data-content-container">
          <div class="data-type-container" :class="dataTypeClasses" @click="toggleAccordion">
            {{dataType}}
          </div>
          <div v-html="dataTextToHtml(dataPassage)">

          </div>

          <div :id="'save-source-button-icon-' + dataKey" class="save-source-wrapper"
               ref="saveSourceButtonWrapper">
            <SaveSourceButton :can-add-argument="canAddArgument"
                              :data-key="dataKey"
                              ref="saveSourceButton"
                              @argument-added="onSaveArgument"/>
          </div>
          <div v-if="hasTechnicalInsights || mainVocabularyKey !== ''" class="technical-insights-title">
            {{ $t('document.titles.technicalInsights') }}
          </div>
          <div v-if="mainVocabularyKey !== ''">
            <AppButton v-if="mainVocabularyKey" size="fill" :color="mainVocabularyKeyButtonColor" @click="openVocabularyModule(mainVocabularyKey)">
              {{ mainVocabularyKey }}
            </AppButton>
          </div>
          <div :id="'data-break-vocabulary-buttons-' + dataKey" class="vocabulary-button-wrapper">
            <div v-for="(vocabularyKey, j) in vocabularyKeys" :key="j">
              <AppButton size="fill" color="light_grey" @click="openVocabularyModule(vocabularyKey)">
                {{ vocabularyKey }}
              </AppButton>
            </div>
          </div>
        </div>
      </accordion>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import textToHtml from '@/utils/textToHtml';
import SaveSourceButton from '@/components/SaveSourceButton/SaveSourceButton';
import { CONVERSATIONS } from '@/enum/Conversations';
import AppButton from '@/components/common/AppButton';
import { ADD_DATA_ID_KV } from '@/store/mutation-types';
import {
  DATA_TYPE_INCLUSIVENESS,
  DATA_TYPE_ECONOMIC_GROWTH,
  DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY,
  DATA_TYPE_FUNCTIONAL_ANALYSIS,
  DATA_TYPE_SOCIAL_SUSTAINABILITY,
} from '@/enum/DataTypes';
import Accordion from '../common/Accordion';

export default {
  name: 'DataBreakModule',
  props: {
    dataKey: {
      type: String,
      required: true,
    },
    dataName: {
      type: String,
      required: true,
    },
    dataPassage: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
    mainVocabularyKey: {
      type: String,
      required: true,
    },
    vocabularyKeys: {
      type: Array,
      default: () => [],
    },
    keepOpen: {
      type: Boolean,
      default: false,
    },
    dataOpen: {
      type: Boolean,
      default: false,
    },
    showShadow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openVocabulary'],
  data() {
    return {
      accordionOpen: false,
      accordionCanBeOpened: false,
    };
  },
  components: {
    Accordion,
    AppButton,
    SaveSourceButton,
  },
  computed: {
    ...mapState([
      'knowledgeVaultSavedIds',
    ]),
    canAddArgument() {
      return !this.knowledgeVaultSavedIds.includes(this.dataKey);
    },
    isAccordionOpen() {
      if (!this.accordionCanBeOpened) {
        return false;
      }
      return this?.dataOpen || this.accordionOpen;
    },
    hasTechnicalInsights() {
      return this.vocabularyKeys.length > 0;
    },
    mainVocabularyKeyButtonColor() {
      let buttonColor = 'light_grey';

      if (this.mainVocabularyKey === '') {
        return buttonColor;
      }

      if (this.dataType === DATA_TYPE_SOCIAL_SUSTAINABILITY) {
        buttonColor = 'data-orange';
      } else if (this.dataType === DATA_TYPE_ECONOMIC_GROWTH) {
        buttonColor = 'data-light-blue';
      } else if (this.dataType === DATA_TYPE_FUNCTIONAL_ANALYSIS) {
        buttonColor = 'data-blue';
      } else if (this.dataType === DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY) {
        buttonColor = 'data-green';
      } else if (this.dataType === DATA_TYPE_INCLUSIVENESS) {
        buttonColor = 'data-yellow';
      }

      return buttonColor;
    },
    dataTitleClasses() {
      const classes = [];

      if (!this.isAccordionOpen) {
        classes.push('button-look');
      }

      if (this.dataType === DATA_TYPE_SOCIAL_SUSTAINABILITY) {
        classes.push('data-type-social-sustain');
      } else if (this.dataType === DATA_TYPE_ECONOMIC_GROWTH) {
        classes.push('data-type-economic-growth');
      } else if (this.dataType === DATA_TYPE_FUNCTIONAL_ANALYSIS) {
        classes.push('data-type-functional-analysis');
      } else if (this.dataType === DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY) {
        classes.push('data-type-environ-sustain');
      } else if (this.dataType === DATA_TYPE_INCLUSIVENESS) {
        classes.push('data-type-inclusiveness');
      } else {
        classes.push('data-type-none');
      }

      return classes;
    },
    dataTypeClasses() {
      const classes = [];

      if (this.dataType === DATA_TYPE_SOCIAL_SUSTAINABILITY) {
        classes.push('data-type-social-sustain');
      } else if (this.dataType === DATA_TYPE_ECONOMIC_GROWTH) {
        classes.push('data-type-economic-growth');
      } else if (this.dataType === DATA_TYPE_FUNCTIONAL_ANALYSIS) {
        classes.push('data-type-functional-analysis');
      } else if (this.dataType === DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY) {
        classes.push('data-type-environ-sustain');
      } else if (this.dataType === DATA_TYPE_INCLUSIVENESS) {
        classes.push('data-type-inclusiveness');
      } else {
        classes.push('data-type-none');
      }

      return classes;
    },
  },
  watch: {
    dataOpen() {
      this.accordionCanBeOpened = false;

      requestAnimationFrame(() => {
        this.accordionCanBeOpened = true;
      });
    },
  },
  mounted() {
    this.vocabularyData = CONVERSATIONS.glossaryData;
    requestAnimationFrame(() => {
      this.accordionCanBeOpened = true;
    });
  },
  methods: {
    dataTextToHtml(text) {
      return textToHtml(text, this.$t);
    },
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen;
    },
    openVocabularyModule(vocabularyKey) {
      this.$emit('openVocabulary', vocabularyKey);
    },
    onSaveArgument() {
      this.$store.commit(ADD_DATA_ID_KV, this.dataKey);
    },
    getSaveSourceButtonYPosition() {
      const saveButtonRef = this.$refs.saveSourceButtonWrapper;
      const saveButtonRefY = saveButtonRef.getBoundingClientRect().y;
      return saveButtonRefY;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

.data-break-module-container {
  width: 100%;

  .data-wrapper {

    &.show-shadow {
      border-radius: 7px 7px 7px 7px;
      box-shadow: -5px 13px 53px 0px rgba(0,0,0,0.75);
    }

    .data-title-container {
      color: #fff;
      // background-color: #263250;
      border-radius: 7px 7px 0 0;
      font-size: 16px;
      line-height: 1.2;
      width: 100%;
      margin-top: 15px;
      padding: 10px;
      text-align: center;
      font-family: 'Fira Sans Condensed','Fira Sans', sans-serif;

      &.data-type-social-sustain {
        background-color: $colorDataTypeSocSus;
      }
      &.data-type-economic-growth {
        background-color: $colorDataTypeEcoGrow;
      }
      &.data-type-functional-analysis {
        background-color: $colorDataTypeFuncAna;
      }
      &.data-type-environ-sustain {
        background-color: $colorDataTypeEnvSus;
      }
      &.data-type-inclusiveness {
        background-color: $colorDataTypeInclu;
      }
      &.data-type-none {
        background-color: #fff;
      }

      &.button-look {
        border-radius: 7px !important;
        box-shadow: -2px 5px 0px 0px rgba(0,0,22,0.3);
        cursor: pointer;
      }
      &.button-look:hover {

        &.data-type-social-sustain {
          background-color: darken($colorDataTypeSocSus, 5%);
        }
        &.data-type-economic-growth {
          background-color: darken($colorDataTypeEcoGrow, 5%);
        }
        &.data-type-functional-analysis {
          background-color: darken($colorDataTypeFuncAna, 5%);
        }
        &.data-type-environ-sustain {
          background-color: darken($colorDataTypeEnvSus, 5%);
        }
        &.data-type-inclusiveness {
          background-color: darken($colorDataTypeInclu, 5%);
        }
      }

      p {
        margin: 0;
      }
    }

    .data-content-container {
      color: #5B5B5B;
      position: relative;
      background-color: #F8F8F8;
      padding: 12px 10px 25px 10px;
      border-radius: 0 0 7px 7px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2;

      .data-type-container {
        text-align: center;
        font-style: italic;
        font-size: 16px !important;
        padding: 4px 7px;
        background-color: #E0E1E6;
        margin-bottom: 9px;
        border-radius: 4px;

        &.data-type-social-sustain {
          color: $colorDarkDataTypeSocSus;
        }
        &.data-type-economic-growth {
          color: $colorDarkDataTypeEcoGrow;
        }
        &.data-type-functional-analysis {
          color: $colorDarkDataTypeFuncAna;
        }
        &.data-type-environ-sustain {
          color: $colorDarkDataTypeEnvSus;
        }
        &.data-type-inclusiveness {
          color: $colorDarkDataTypeInclu;
        }
      }
    }

    .vocabulary-button-wrapper {
      margin: 10px 0 10px 0;
    }

    .save-source-wrapper {
      //position: relative;
      height: 40px;
      text-align: center;
      width: 50px;
      margin: 10px auto 7px auto;
    }
  }

  .technical-insights-title {
    padding-top: 25px;
    margin-bottom: 5px;
    color: #263250;
    text-align: center;

    font-size: 22px;
    font-weight: 500;
    font-family: 'Fira Sans Condensed', 'Fira Sans', sans-serif;
  }

  .vocabulary-module-wrapper {
    position: absolute;
    width: 90%;
    left: -100%;
  }
}
</style>
