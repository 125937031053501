import {
  FINISH_TUTORIAL,
  REPLAY_TUTORIAL, UPDATE_TUTORIAL_ASSISTANT_STATUS,
} from '../mutation-types';

export default {
  [REPLAY_TUTORIAL]: (state) => {
    state.currentDilemmaId = 'INTRO2';
    state.tutorialStepIndex = 0;
    state.tutorialAssistantMessagesShown.onBoardingExplanation = false;
    state.tutorialAssistantMessagesShown.dilemmaViewExplanation = false;
    state.tutorialAssistantMessagesShown.dataIconExplanation = false;
    state.tutorialAssistantMessagesShown.technicalInsightExplanation = false;
    state.tutorialAssistantMessagesShown.sixPagerIconExplainer = false;

    localStorage.setItem('currentDilemmaId', state.currentDilemmaId);
    localStorage.setItem('tutorialStepIndex', '0');
    localStorage.setItem('tutorialAssistantMessagesShown', JSON.stringify(state.tutorialAssistantMessagesShown));
  },
  [FINISH_TUTORIAL]: (state) => {
    state.tutorialCompleted = true;

    state.tutorialAssistantMessagesShown.onBoardingExplanation = true;
    state.tutorialAssistantMessagesShown.dilemmaViewExplanation = true;
    state.tutorialAssistantMessagesShown.dataIconExplanation = true;
    state.tutorialAssistantMessagesShown.technicalInsightExplanation = true;
    state.tutorialAssistantMessagesShown.sixPagerIconExplainer = true;

    localStorage.setItem('tutorialCompleted', JSON.stringify(state.tutorialCompleted));
    localStorage.setItem('tutorialAssistantMessagesShown', JSON.stringify(state.tutorialAssistantMessagesShown));
  },
  [UPDATE_TUTORIAL_ASSISTANT_STATUS]: (state, tutorialKey) => {
    if (!state.tutorialAssistantMessagesShown.hasOwnProperty(tutorialKey)) {
      console.error(`The given tutorial key: ${tutorialKey} is not found in state message element: ${state.tutorialAssistantMessagesShown}`);
    }

    state.tutorialAssistantMessagesShown[tutorialKey] = true;
    localStorage.setItem('tutorialAssistantMessagesShown', JSON.stringify(state.tutorialAssistantMessagesShown));
  },
};
