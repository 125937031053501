<template>
  <div class="black-transition">
  </div>
</template>

<script>

export default {
  name: 'InfoView',
  props: {
  },
  emits: ['transitionCompleted'],
  mounted() {
    setTimeout(() => {
      this.$emit('transitionCompleted');
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.black-transition {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>
