<template>
  <DataBreakPanel :data-break-data-keys="currentDataBreakData" :data-break-window-open="dataBreakWindowOpen" @toggle="toggleDataBreakWindow()"/>
  <div class="row mt-4">
    <h3>Dev buttons</h3>
    <div class="col">
      <button class="btn btn-light me-4" @click="toggleDataBreakWindow()">
        Toggle data break window
      </button>
    </div>
    <h3><br>Change data</h3>
    <div class="col">
      <button class="btn btn-light me-4" @click="setDataBreakData1()">
        Toggle data 1
      </button>
      <button class="btn btn-light me-4" @click="setDataBreakData2()">
        Toggle data 2
      </button>
      <button class="btn btn-light me-4" @click="setDataBreakData3()">
        Toggle data 3 (no data)
      </button>
    </div>
  </div>
</template>
<script>

import DataBreakPanel from './DataBreakPanel';
import { CONVERSATIONS } from '@/enum/Conversations';

export default {
  name: 'DataBreakDev',
  components: {
    DataBreakPanel,
  },
  emits: ['click'],
  data() {
    return {
      dataBreakWindowOpen: true,
      dataBreakData: {},
      vocabularyData: {},
      currentDataBreakData: [],
    };
  },
  mounted() {
    this.dataBreakData = CONVERSATIONS.dataBreakData;
    this.vocabularyData = CONVERSATIONS.glossaryData;
    this.setDataBreakData1();
  },
  methods: {
    toggleDataBreakWindow() {
      this.dataBreakWindowOpen = !this.dataBreakWindowOpen;
    },
    setDataBreakData1() {
      this.currentDataBreakData = [
        '1',
        '2',
        '4',
        '5',
        '9',
      ];
    },
    setDataBreakData2() {
      this.currentDataBreakData = [
        '7',
        '9',
        '8',
        '10',
      ];
    },
    setDataBreakData3() {
      this.currentDataBreakData = [
      ];
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
