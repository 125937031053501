<template>
  <div class="side-menu-wrapper">
    <div class="tab-menu-wrapper d-flex">
      <div v-for="(tab, j) in tabs" :key="j"
           class="menu-tab d-flex flex-fill"
           @click="selectTab(j)"
           :class="studyBriefMenuTabIndex === j ? 'selected' : ''">
        <div class="tab-image" :class="tab.tabClass"/>
        <div class="tab-title">
          {{$t(tab.tabTitle)}}
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div v-if="studyBriefMenuTabIndex === 0" class="content-expert-comments">
        <div v-if="noExpertDataAvailable" class="cursive">
          {{ $t('misc.noExpertComments') }}
        </div>
        <div v-if="!noExpertDataAvailable">
          <h5 class="fat-text" v-html="expertDataTitleHtml"/>
          <div v-html="expertDataTextHtml"/>
        </div>
      </div>
      <div v-if="studyBriefMenuTabIndex === 1" class="content-data">
        <p class="fat-text">
          {{ $t('misc.dataAvailableTitle') }}
        </p>
        <ul>
          <li v-for="(dataItem, i) in sortedDataIds6Pager" :key="i" @click="clickDataPoint({dataKey: dataItem.dataKey, dataId: dataItem.id})">
            {{dataItem.name}}
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>
<script>

import { CONVERSATIONS } from '@/enum/Conversations';
import textToHtml from '@/utils/textToHtml';
import { mapState } from 'vuex';
import { SET_STUDY_BRIEF_TAB_INDEX } from '@/store/mutation-types';

export default {
  name: 'SideMenu6Pager',
  emits: ['dataPointClicked'],
  data() {
    return {
      // tabSelected: this.studyBriefMenuTabIndex,
      tabs: [
        {
          tabTitle: 'misc.experts',
          tabClass: 'tab-expert',
        },
        {
          tabTitle: 'misc.data',
          tabClass: 'tab-data',
        },
      ],
    };
  },
  computed: {
    ...mapState([
      'studyBriefMenuTabIndex',
    ]),
    filteredDataIds6Pager() {
      const pageFilteredList = this.dataIds6Pager.filter((dataId) => dataId.page === this.currentPageIndex);

      const duplicateFreeList = Array.from(new Set(pageFilteredList.map((item) => item.dataKey)))
        .map((dataKey) => pageFilteredList.find((item) => item.dataKey === dataKey));

      for (const dataPoint of duplicateFreeList) {
        dataPoint.name = this.getDataItemName(dataPoint.dataKey);
      }

      return duplicateFreeList;
    },
    sortedDataIds6Pager() {
      const sortedDataIds6Pager = this.filteredDataIds6Pager.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // convert name to uppercase to make the comparison case-insensitive
        const nameB = b.name.toUpperCase(); // convert name to uppercase for case-insensitive comparison

        if (nameA < nameB) {
          return -1; // nameA comes first
        }
        if (nameA > nameB) {
          return 1; // nameB comes first
        }
        return 0; // names are equal
      });
      return sortedDataIds6Pager;
    },
    expertDataTitleHtml() {
      return this.expertDataTitle === '' ? '' : textToHtml(this.$t(this.expertDataTitle), this.$t);
    },
    expertDataTextHtml() {
      return this.expertDataText === '' ? '' : textToHtml(this.$t(this.expertDataText), this.$t);
    },
    noExpertDataAvailable() {
      return this.expertDataText === '';
    },
  },
  mounted() {
    this.dataBreakData = CONVERSATIONS.dataBreakData;
  },
  props: {
    expertDataTitle: {
      type: String,
      default: '',
    },
    expertDataText: {
      type: String,
      default: '',
    },
    dataIds6Pager: {
      type: Array,
      default: () => [],
    },
    currentPageIndex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    selectTab(i) {
      // this.tabSelected = i;
      this.$store.commit(SET_STUDY_BRIEF_TAB_INDEX, i);
    },
    getDataItem(dataKey) {
      return this.dataBreakData?.[dataKey];
    },
    getDataItemName(dataKey) {
      return this.getDataItem(dataKey)?.name;
    },
    clickDataPoint(dataKey) {
      this.$emit('dataPointClicked', dataKey);
    },
  },
};
</script>
<style lang="scss" scoped>
  .side-menu-wrapper {
    height: 100%;
    width: 100%;
    color: #0A282F;
    min-width: 260px;

    .tab-menu-wrapper {
      transform: translateY(0.3px);

      height: 40px;
      .menu-tab {
        background-color: #2B5156;
        color: #fff;
        padding: 5px 10px;
        border-radius: 10px 10px 0 0;
        cursor: pointer;

        .tab-image {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 5px;

          &.tab-expert {
            background-image: url("./../../assets/img/ui/tab-icon-expert-data-white.png");

            margin-top: 5px;
            height: 20px;
            width: 20px;
          }

          &.tab-data {
            background-image: url("./../../assets/img/ui/tab-icon-globe-white.png");
            height: 25px;
            width: 25px;
          }
        }

        &.selected {
          background-color: #EDF1F4;
          color: #0A282F;

          .tab-expert {
            background-image: url("./../../assets/img/ui/tab-icon-expert-data.png");
          }

          .tab-data {
            background-image: url("./../../assets/img/ui/tab-icon-globe.png");
          }
        }
      }
    }

    .content-wrapper {
      padding: 20px 15px 45px;
      background-color: #EDF1F4;
      width: 100%;
      height: calc(100% - 40px);
      overflow-y: auto;

      .content-data {
        li {
          cursor: pointer;
        }

        li:hover {
          color: #4A686F;
          text-decoration: underline;
        }
      }
    }
  }

</style>
