<template>
  <div class="phone-view-container">
    <div class="content-wrapper" :class="revealClass">
      <DialogueGroup ref="dialogueGroup" :is-boss-fight="true" style="display: block;" :storyDataKey="conversationId"
                     @conversationDone="dialogueCompleted"/>
    </div>
  </div>
</template>

<script>

import DialogueGroup from '@/components/DialogGroup/DialogueGroup';
import { mapState } from 'vuex';
import { clearTimeoutPromise } from '@/utils/promiseUtil';
import {
  SET_DYNAMIC_STORE_VALUE,
} from '@/store/mutation-types';
import NoDragImage from '../../components/common/NoDragImage';

export default {
  name: 'BossFightView',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    NoDragImage,
    DialogueGroup,
  },
  emits: ['pageCompleted'],
  data() {
    return {
      text: '',
      revealStarted: false,
      revealed: false,
    };
  },
  computed: {
    ...mapState([
      'playerName',
    ]),
    title() {
      return this.data?.title;
    },
    phoneBackground() {
      return this.data?.phoneBackground;
    },
    getImageSrc() {
      const images = require.context('../../assets/img/bg');
      const imgSrc = images(`./${this.phoneBackground}`);
      return imgSrc;
    },
    conversationId() {
      return this.data?.conversationId.toString();
    },
    revealClass() {
      return this.revealStarted ? '' : 'hide-left';
    },
  },
  mounted() {
    // allow skipping conversation pauses on click
    document.addEventListener('click', this.onDocumentClick);

    setTimeout(() => {
      this.revealStarted = true;
    }, 50);

    setTimeout(() => {
      this.$refs.dialogueGroup.showChoiceUI();
    }, 3000);
  },
  unmounted() {
    document.removeEventListener('click', this.onDocumentClick);
  },
  methods: {
    inputValueChanged() {
      const newStoreData = {
        key: this.inputStoreId,
        value: this.text,
      };
      this.$store.commit(SET_DYNAMIC_STORE_VALUE, newStoreData);
    },
    dialogueCompleted() {
      this.$emit('pageCompleted');
    },
    onDocumentClick() {
      clearTimeoutPromise(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-view-container {
  color: #ffffff;
  font-weight: 700;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  .hide-left {
    transform: translateX(-100%);
  }

  .content-wrapper {
    position: absolute;
    transition: transform 1.5s cubic-bezier(0.68, -0.25, 0.265, 1.15);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;

    .phone-background-wrapper {
      height: 100%;
      left: 0;
      position: absolute;
      img {
        height: 100%;
      }

    }
  }

}

</style>
