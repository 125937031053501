<template>
  <div class="info-view-container">
    <div class="content-height-wrapper">
      <div ref="textWrapper" class="content-wrapper scroll-content">
        <div v-if="numberOfContentPages > 1" id="progress-bar-wrapper" class="progress-bar-wrapper d-flex">
          <div class="arrow-wrapper left" :class="numberOfContentPages > 1 && !isFirstPage ? '' : 'invisible'" @click="onArrowClicked(-1)">
            <i class="arrow"/>
          </div>
          <div v-for="i in numberOfContentPages" :key="i"
               class="progress-dot"
               :class="i - 1 === currentPageIndex ? 'active' : ''"
               @click="setChildPageIndex(i -1)"
          />
          <div class="arrow-wrapper right" :class="numberOfContentPages > 1 && !isLastPage ? '' : 'invisible'" @click="onArrowClicked(1)">
            <i class="arrow" />
          </div>
        </div>
        <div class="title-wrapper" :class="titleWrapperClasses">
          <h5>{{ $t(title) }}</h5>
        </div>
        <div class="text-wrapper" :class="textWrapperClasses">
          <h4 v-html="textHtml" />
        </div>
        <div v-if="icon" class="image-wrapper">
          <no-drag-image :src="getImageSrc(icon)" />
        </div>
      </div>
    </div>
    <div class="background-overlay-image" :class="hideOverlay ? 'hide-overlay' : ''">
      <no-drag-image :src="getBackgroundOverlaySrc(backgroundOverlayImage)" />
    </div>
  </div>
</template>

<script>
import textToHtml from '@/utils/textToHtml';
import { mapState } from 'vuex';
import { SET_BACKGROUND } from '@/store/mutation-types';
import {
  BACKGROUND_PHASE_1, BACKGROUND_PHASE_2,
  BACKGROUND_PHASE_3, BACKGROUND_PHASE_4, BACKGROUND_PHASE_5,
} from '@/enum/Background';
import NoDragImage from '@/components/common/NoDragImage';

export const SCROLL_BACKGROUND_DEFAULT = 'background-default';
export const SCROLL_BACKGROUND_PHASE_1 = 'background-phase-1';
export const SCROLL_BACKGROUND_PHASE_2 = 'background-phase-2';
export const SCROLL_BACKGROUND_PHASE_3 = 'background-phase-3';
export const SCROLL_BACKGROUND_PHASE_4 = 'background-phase-4';
export const SCROLL_BACKGROUND_PHASE_5 = 'background-phase-5';

export default {
  name: 'InfoScrollView',
  components: { NoDragImage },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['pageCompleted'],
  data() {
    return {
      currentTitle: '',
      currentInfo: '',
      currentPageIndex: 0,
      hasClickedStrategy: false,
      selectedStrategy: 0,
      backgroundOverlayImage: 'overlay_phase_1',
      hideOverlay: true,
    };
  },
  watch: {
    background() {
      this.changeBackgroundGraphics();
    },
  },
  computed: {
    ...mapState([
    ]),
    content() {
      return this.data?.content ?? [];
    },
    title() {
      return this.content[this.currentPageIndex]?.title ?? '';
    },
    text() {
      return this.content[this.currentPageIndex]?.text ?? '';
    },
    background() {
      return this.content[this.currentPageIndex]?.background ?? SCROLL_BACKGROUND_DEFAULT;
    },
    icon() {
      return this.content[this.currentPageIndex]?.icon ?? null;
    },
    textHtml() {
      return textToHtml(this.$t(this.text), this.$t);
    },
    numberOfContentPages() {
      return this.content.length;
    },
    isFirstPage() {
      return this.currentPageIndex === 0;
    },
    isLastPage() {
      return this.currentPageIndex === (this.numberOfContentPages - 1);
    },
    titleWrapperClasses() {
      return [];
    },
    textWrapperClasses() {
      return [];
    },
  },
  mounted() {

  },
  methods: {
    getImageSrc(key) {
      const images = require.context('../../assets/img');
      const imgSrc = images(`./${key}.png`);
      return imgSrc;
    },
    getBackgroundOverlaySrc(key) {
      const images = require.context('../../assets/img/bg/phasesOverlays');
      const imgSrc = images(`./${key}.png`);
      return imgSrc;
    },
    onArrowClicked(changeDelta) {
      const newPageIndex = Math.min(Math.max(this.currentPageIndex + changeDelta, 0), this.numberOfContentPages);
      this.currentPageIndex = newPageIndex;
      this.onChangePage();
    },
    setChildPageIndex(i) {
      this.currentPageIndex = i;
      this.onChangePage();
    },
    onChangePage() {
      if (this.numberOfContentPages === this.currentPageIndex + 1) {
        this.$emit('pageCompleted');
      }
      this.scrollToTop();
    },
    scrollToTop() {
      this.$refs.textWrapper.scrollTop = 0;
    },
    changeBackgroundGraphics() {
      switch (this.background) {
        case SCROLL_BACKGROUND_PHASE_1:
          this.$store.commit(SET_BACKGROUND, BACKGROUND_PHASE_1);
          this.backgroundOverlayImage = 'overlay_phase_1';
          this.hideOverlay = false;
          break;
        case SCROLL_BACKGROUND_PHASE_2:
          this.$store.commit(SET_BACKGROUND, BACKGROUND_PHASE_2);
          this.backgroundOverlayImage = 'overlay_phase_2';
          this.hideOverlay = false;
          break;
        case SCROLL_BACKGROUND_PHASE_3:
          this.$store.commit(SET_BACKGROUND, BACKGROUND_PHASE_3);
          this.backgroundOverlayImage = 'overlay_phase_3';
          this.hideOverlay = false;
          break;
        case SCROLL_BACKGROUND_PHASE_4:
          this.$store.commit(SET_BACKGROUND, BACKGROUND_PHASE_4);
          this.backgroundOverlayImage = 'overlay_phase_4';
          this.hideOverlay = false;
          break;
        case SCROLL_BACKGROUND_PHASE_5:
          this.$store.commit(SET_BACKGROUND, BACKGROUND_PHASE_5);
          this.backgroundOverlayImage = 'overlay_phase_5';
          this.hideOverlay = false;
          break;
        case SCROLL_BACKGROUND_DEFAULT:
          this.$store.commit(SET_BACKGROUND, BACKGROUND_PHASE_1);
          this.backgroundOverlayImage = 'overlay_phase_1';
          this.hideOverlay = true;
          break;
        default:
          this.$store.commit(SET_BACKGROUND, BACKGROUND_PHASE_1);
          this.backgroundOverlayImage = 'overlay_phase_1';
          this.hideOverlay = true;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$arrow-indent: 15px;

.info-view-container {
  color: #ffffff;
  font-weight: 700;

  .scroll-content{
    background-color: #2C4A57;
    height: calc(80vh - 210px);
    max-height: calc(80vh - 210px);
    overflow-y: auto;
  }

  .content-height-wrapper {
    padding-bottom: 60px;

    .content-wrapper {
      width: 80%;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5%;
      padding: 20px 55px 30px;

      .title-wrapper {
        text-align: center;
        font-size: 32px;
        margin-top: 25px;
        margin-bottom: 15px;
      }

      .text-wrapper {
        text-align: justify;
        margin-bottom: 30px;

        h4 {
          font-weight: 400;
          font-size: 1.1rem !important;
        }
      }

      .image-wrapper {
        width: 20%;
        margin-top: 25px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 60px;
        img {
          width: 100%;
        }
      }

      .info-content-wrapper {
        background-color: lighten(#2C4A57, 10%);
        margin: 0 2px;
        padding: 15px 15px 25px;
        font-weight: 400;
      }
    }
  }

  .progress-bar-wrapper {
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translateX(-50%);
    .progress-dot {
      border: solid #2B5156 1px;
      height: 15px;
      width: 15px;
      margin: 7px 5px 5px;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      &.active {
        background-color: #2B5156;
      }
    }
  }

  .arrow-wrapper {
    // background-color: #3B6166;
    top: 40%;
    cursor: pointer;
    z-index: 9999999;
    transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);

    &.invisible {
      pointer-events: none;
      cursor: default;
      opacity: 0;
    }
  }

  .arrow-wrapper:hover {
    // background-color: #2B5156;
    transform: scale(1.15);
    .arrow {
      border: solid #1B3136;
      border-width: 0 6px 6px 0;
    }
  }

  .arrow {
    border: solid #2B5156;
    border-width: 0 6px 6px 0;
    display: inline-block;
    padding: 7px;
    margin-top: 5px;
  }

  .arrow:hover {
    border: solid #2B5156;
    border-width: 0 6px 6px 0;
  }

  .right {
    right: 0;
    margin-left: 30px;
    padding: 0 10px 10px 10px;
    .arrow {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      right: $arrow-indent;
    }
  }

  .left {
    left: 0;
    margin-right: 30px;
    padding: 0 10px 10px 10px;
    .arrow {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      left: $arrow-indent;
    }

  }

  .background-overlay-image {
    position: fixed;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: -2;
    transition: bottom 250ms ease-in-out;

    img {
      height: 100%;
      width: 100%;
      bottom: 0;
      right: 0;
    }

    &.hide-overlay {
      bottom: -50%;
    }
  }

}

</style>
