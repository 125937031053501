import extend from 'extend';

export function removeArrayItemByValue(array, item) {
  const index = array.indexOf(item);

  if (index !== -1) {
    array.splice(index, 1);
  }

  return array;
}

export function toggleArrayItemByValue(array, item, forceValue = null) {
  let addValue;

  if (forceValue !== null) {
    addValue = forceValue;
  } else {
    addValue = !array.includes(item);
  }

  const itemIndex = array.indexOf(item);

  if (addValue) {
    if (itemIndex < 0) {
      array.push(item);
    }
  } else {
    if (itemIndex >= 0) {
      removeArrayItemByValue(array, item);
    }
  }
}

export function compareArrays(masterArray, slaveArray, sort = false) {
  const localMasterArray = masterArray ? extend([], masterArray) : [];
  const localSlaveArray = slaveArray ? extend([], slaveArray) : [];

  if (sort) {
    localMasterArray.sort();
    localSlaveArray.sort();
  }

  if (localMasterArray.length === localSlaveArray.length) {
    if (JSON.stringify(localMasterArray) === JSON.stringify(localSlaveArray)) {
      return true;
    }
  }

  // They're not identical
  return localMasterArray;
}

export function pickRandom(items) {
  const itemsLength = items.length;

  if (!itemsLength) {
    return null;
  }

  const index = Math.floor(Math.random() * itemsLength);

  return items[index];
}

export function keySelectionAsCommaSeperatedString(items, key) {
  let keyString = '';

  for (let i = 0; i < items.length; i++) {
    let tempKeyString = items[i]?.[key] || '';
    keyString += tempKeyString;

    if (tempKeyString !== '' && i + 1 !== items.length) {
      keyString += ', ';
    }
  }

  return keyString;
}

export function keySelectionAsCommaSeperatedStringInverted(items, key) {
  let keyString = '';

  for (let i = items.length; i >= 0; i--) {
    let tempKeyString = items[i]?.[key] || '';
    keyString += tempKeyString;

    if (tempKeyString !== '' && i !== 0) {
      keyString += ', ';
    }
  }

  return keyString;
}
