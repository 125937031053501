<template>
  <div class="data-break-module-container" :class="hasBeenUsed ? 'has-been-used' : ''">
    <div class="data-wrapper">
      <div class="data-title-container"  :class="typeClass">
        <p>{{dataName}}</p>
      </div>
      <div class="data-content-container">
        <div v-html="dataTextToHtml(dataPassage)">
        </div>
        <div class="argument-button-wrapper">
          <div class="app-icon-button-wrapper">
            <AppButton style="" class="app-icon-button-inner" :size="'fill'" :color="'light_grey'" @click="chooseArgument(dataKey)">
              <div class="d-flex">
                <div style="width: 28px"/>
                {{ $t('misc.chooseArgument') }}
              </div>
            </AppButton>
            <NoDragImage class="app-icon-button-image" :src="getImageSrc('arrow-left')"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import { mapState } from 'vuex';
import textToHtml from '@/utils/textToHtml';
import AppButton from '@/components/common/AppButton';
import NoDragImage from '@/components/common/NoDragImage';
import {
  DATA_TYPE_ECONOMIC_GROWTH, DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY,
  DATA_TYPE_FUNCTIONAL_ANALYSIS, DATA_TYPE_INCLUSIVENESS,
  DATA_TYPE_SOCIAL_SUSTAINABILITY
} from '@/enum/DataTypes';

export default {
  name: 'UseArgumentModule',
  props: {
    dataKey: {
      type: String,
      required: true,
    },
    dataName: {
      type: String,
      required: true,
    },
    dataPassage: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
  },
  emits: ['chooseArgument'],
  data() {
    return {
    };
  },
  components: {
    NoDragImage,
    AppButton,
  },
  computed: {
    ...mapState([
      'argumentsUsedInBossFight',
    ]),
    hasBeenUsed() {
      return Object.values(this.argumentsUsedInBossFight).includes(this.dataKey);
    },
    typeClass() {
      let buttonClass = '';

      if (this.dataType === DATA_TYPE_SOCIAL_SUSTAINABILITY) {
        buttonClass = 'data-type-social-sustain';
      } else if (this.dataType === DATA_TYPE_ECONOMIC_GROWTH) {
        buttonClass = 'data-type-economic-growth';
      } else if (this.dataType === DATA_TYPE_FUNCTIONAL_ANALYSIS) {
        buttonClass = 'data-type-functional-analysis';
      } else if (this.dataType === DATA_TYPE_ENVIRONMENTAL_SUSTAINABILITY) {
        buttonClass = 'data-type-environ-sustain';
      } else if (this.dataType === DATA_TYPE_INCLUSIVENESS) {
        buttonClass = 'data-type-inclusiveness';
      } else {
        buttonClass = 'data-type-none';
      }

      return buttonClass;
    },
  },
  mounted() {
  },
  methods: {
    chooseArgument(dataKey) {
      this.$emit('chooseArgument', dataKey);
    },
    dataTextToHtml(text) {
      return textToHtml(text, this.$t);
    },
    getImageSrc(key) {
      const images = require.context('../../assets/img/ui');
      const imgSrc = images('./' + key + '.png');
      return imgSrc;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

.data-break-module-container {
  position: relative;
  width: 100%;

  .data-wrapper {
    .data-title-container {
      color: #fff;
      background-color: #263250;
      border-radius: 7px 7px 0 0;
      font-size: 16px;
      line-height: 1.2;
      width: 100%;
      margin-top: 15px;
      padding: 10px;
      text-align: center;
      font-family: 'Fira Sans Condensed','Fira Sans', sans-serif;

      p {
        margin: 0;
      }

      &.data-type-social-sustain {
        background-color: $colorDataTypeSocSus;
      }
      &.data-type-economic-growth {
        background-color: $colorDataTypeEcoGrow;
      }
      &.data-type-functional-analysis {
        background-color: $colorDataTypeFuncAna;
      }
      &.data-type-environ-sustain {
        background-color: $colorDataTypeEnvSus;
      }
      &.data-type-inclusiveness {
        background-color: $colorDataTypeInclu;
      }
      &.data-type-none {
        background-color: #fff;
      }
    }

    .data-content-container {
      color: #5B5B5B;
      background-color: #F8F8F8;
      padding: 12px 10px 17px 10px;
      border-radius: 0 0 7px 7px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2;

      &.data-type-social-sustain {
        color: $colorDarkDataTypeSocSus;
      }
      &.data-type-economic-growth {
        color: $colorDarkDataTypeEcoGrow;
      }
      &.data-type-functional-analysis {
        color: $colorDarkDataTypeFuncAna;
      }
      &.data-type-environ-sustain {
        color: $colorDarkDataTypeEnvSus;
      }
      &.data-type-inclusiveness {
        color: $colorDarkDataTypeInclu;
      }
    }

    .argument-button-wrapper {
      margin: 20px 0 0px 0;

      .app-icon-button-wrapper {
        position: relative;
        height: fit-content;
        width: 100%;

        .app-icon-button-wrapper-inner {
          padding: 5px 10px 5px 20px !important
        }

        .app-icon-button-image {
          position: absolute;
          z-index: 100;
          top: 50%;
          left: 5px;
          transform: translate(0, -68%);
          width: 39px;
        }
      }
    }
  }
}
</style>
