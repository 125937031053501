<template>
  <div class="policy-button-wrapper" :class="stateClass" @click="onClickButton">
    <p v-if="isLocked">?</p>
    <p v-if="!isLocked"><slot/></p>
  </div>
</template>
<script>

import {STATUS_LOCKED, STATUS_SELECTED, STATUS_UNLOCKED} from '@/enum/UnlockedStatus.js';

export default {
  name: 'PolicyButton',
  props: {
    state: {
      type: Number,
      default: STATUS_LOCKED,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
  computed: {
    isLocked() {
      return this.state === STATUS_LOCKED;
    },
    stateClass() {
      const stateClasses = [];
      let stateClass = '';
      switch (this.state) {
        case STATUS_LOCKED:
          stateClass = 'locked';
          break;
        case STATUS_SELECTED:
          stateClass = 'selected';
          break;
        case STATUS_UNLOCKED:
          stateClass = 'unlocked';
          break;
        default:
          console.log('state did not match known states');
          stateClass = 'unlocked';
      }
      stateClasses.push(stateClass);
      if (!this.isClickable) {
        stateClasses.push('non-clickable');
      }
      return stateClasses;
    },
  },
  methods: {
    onClickButton() {
      if (this.isLocked) {
        return;
      }

      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
  $padding: 5px;

  .policy-button-wrapper {
    width: 100%;
    height: fit-content;
    cursor: pointer;
    padding: $padding;
    margin: 9px 0;
    border-radius: 10px;

    color: #E4EDF2;
    text-align: center;

    p {
      font-weight: bold;
      margin: 0;
      font-family: "Fira Sans";
      font-size: 14px;
    }

    &.unlocked {
      color: #E4EDF2;
      background-color: #657980;
    }

    &.selected {
      color: #4C6066;
      background-color: #E4EDF2;
    }

    &.locked {
      background-color: #CBD7D8;
      pointer-events: none;
      color: #4C6066;
      padding: calc($padding + 2px) 0;
    }
  }

</style>
