import { SeriousAnalytics } from "serious-analytics";

export default class {
  seriousAnalytics;

  permissionCallbackResolve;

  constructor(options = {}) {
    const apiKey = options.apiKey || null;
    const enableDebugging = options.enableDebugging || false;
    const baseUrl = options.baseUrl || null;

    this.seriousAnalytics = new SeriousAnalytics({
      apiKey,
      enableDebugging,
      baseUrl,
      permissionCallback: () => {
        // Use the event SeriousAnalyticsEvent.PermissionRequested

        return new Promise((resolve) => {
          console.log('set permission callback!!!!');
          this.permissionCallbackResolve = resolve;
        });
      },
    });

    const mixins = this.mixins();

    for (const mixinGroup in mixins) {
      if (Object.prototype.hasOwnProperty.call(mixins, mixinGroup)) {
        const mixin = mixins[mixinGroup];

        if (typeof (mixin) === 'function') {
          // It's a standalone call without a group
          this[mixinGroup] = mixin.bind(this);
        } else {
          // mixin is actually a group of mixins, let's merge the entire group¨¨
          const groupMixins = { ...this[mixinGroup], ...mixin };

          this[mixinGroup] = {};

          for (const name in groupMixins) {
            if (Object.prototype.hasOwnProperty.call(groupMixins, name)) {
              this[mixinGroup][name] = groupMixins[name].bind(this);
            }
          }
        }
      }
    }
  }

  grantPermission() {
    this.permissionCallbackResolve(true);
  }

  rejectPermission() {
    this.permissionCallbackResolve(false);
  }

  // eslint-disable-next-line class-methods-use-this
  mixins() {
    return [];
  }

  createSession() {
    return this.seriousAnalytics.createSession();
  }

  postEvent(eventName, eventData = null) {
    return this.seriousAnalytics.postEvent(eventName, eventData);
  }

  startTimer(timerName) {
    return this.seriousAnalytics.startTimer(timerName);
  }

  stopTimer(timerName, timerData = null)  {
    return this.seriousAnalytics.stopTimer(timerName, timerData);
  }

  clearPermissionGiven()  {
    return this.seriousAnalytics.clearPermissionGiven();
  }

  // To emulate eventemitter3
  on(eventName, callback, context = null) {
    return this.seriousAnalytics.on(eventName, callback, context);
  }

  once(eventName, callback, context = null) {
    return this.seriousAnalytics.once(eventName, callback, context);
  }

  off(eventName, callback, context = null) {
    return this.removeEventListener(eventName, callback, context);
  }

  removeEventListener(eventName, callback, context = null) {
    return this.seriousAnalytics.removeEventListener(eventName, callback, context);
  }
}
