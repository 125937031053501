import {
  ADD_ARGUMENT, ADD_DATA_ID_KV, ADD_EVIDENCE_HARVEST_POINTS,
  REMOVE_ARGUMENT,
  SET_ARGUMENT_PER_POLICY_OBJECT, SET_IS_KNOWLEDGE_VAULT_OPEN,
  SET_KNOWLEDGE_VAULT_SAVED_IDS_OBJECT,
  USE_ARGUMENT,
} from '../mutation-types';

export default {
  [SET_IS_KNOWLEDGE_VAULT_OPEN]: (state, newState) => {
    state.isKnowledgeVaultOpen = newState;
    localStorage.setItem('isKnowledgeVaultOpen', newState.toString());
  },
  [ADD_DATA_ID_KV]: (state, newId) => {
    if (state.knowledgeVaultSavedIds.includes(newId)) {
      return;
    }
    state.knowledgeVaultSavedIds.push(newId);
    localStorage.setItem('knowledgeVaultSavedIds', JSON.stringify(state.knowledgeVaultSavedIds));

    state.evidencePointsHarvestData = Math.min(state.evidencePointsHarvestData + 1, 15);
    localStorage.setItem('evidencePointsHarvestData', state.evidencePointsHarvestData.toString());
    // this.$store.commit(ADD_EVIDENCE_HARVEST_POINTS, 1);
  },
  [SET_KNOWLEDGE_VAULT_SAVED_IDS_OBJECT]: (state, newKvObject) => {
    state.knowledgeVaultSavedIds = newKvObject;
    localStorage.setItem('knowledgeVaultSavedIds', JSON.stringify(newKvObject));
  },
  [SET_ARGUMENT_PER_POLICY_OBJECT]: (state, newArgumentObject) => {
    state.argumentIndexesPerPolicyIndex = newArgumentObject;
    localStorage.setItem('argumentIndexesPerPolicyIndex', JSON.stringify(newArgumentObject));
  },
  [REMOVE_ARGUMENT]: (state, knowledgeBankItemId) => {

    // find which policy id it represents
    let policyId = null;
    let indexOfArgumentId = -1;
    for (const argumentArrayKey in state.argumentIndexesPerPolicyIndex) {
      if (state.argumentIndexesPerPolicyIndex.hasOwnProperty(argumentArrayKey)) {
        indexOfArgumentId = state.argumentIndexesPerPolicyIndex[argumentArrayKey].indexOf(knowledgeBankItemId);
        if (indexOfArgumentId !== -1) {
          policyId = argumentArrayKey;
          break;
        }
      }
    }

    if (policyId) {
      // remove object from argument list
      state.argumentIndexesPerPolicyIndex[policyId].splice(indexOfArgumentId, 1);
      localStorage.setItem('argumentIndexesPerPolicyIndex', JSON.stringify(state.argumentIndexesPerPolicyIndex));

      // add to normal knowledgeVault
      state.knowledgeVaultSavedIds.push(knowledgeBankItemId);
      localStorage.setItem('knowledgeVaultSavedIds', JSON.stringify(state.knowledgeVaultSavedIds));
    } else {
      console.log('could not find a policyId for knowledgeBank item Id: ', knowledgeBankItemId);
    }
  },
  [ADD_ARGUMENT]: (state, knowledgeBankItemIndex) => {

  },
  [USE_ARGUMENT]: (state, argumentData) => {
    const dataKey = argumentData?.dataKey;
    const argumentBossFightId = argumentData?.argumentBossFightId;

    if (dataKey && argumentBossFightId) {
      state.argumentsUsedInBossFight[argumentBossFightId] = dataKey;
      localStorage.setItem('argumentsUsedInBossFight', JSON.stringify(state.argumentsUsedInBossFight));
    } else {
      console.error('argument data must include a data key and an argumentBossFightId');
    }
  },
};
