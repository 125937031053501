import extend from 'extend';

import cheatWindow from '@/store/mutations/cheat-window';
import misc from './misc';
import knowledgeVault from '@/store/mutations/knowledge-vault';
import tutorial from '@/store/mutations/tutorial';

export default extend(
  misc,
  knowledgeVault,
  cheatWindow,
  tutorial,
);
