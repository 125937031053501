<template>
  <transition :name="transitionName" mode="out-in">
    <div class="intro-view-wrapper" v-bind:key="currentInformalPDPageIndex">
      <div class="intro-view-content">
        <div class="reset-button">
        </div>
        <div class="intro-content-style">
          <VideoModal v-if="videoModalOpen" :videoUrl="currentVideoUrl" @closeModal="closeVideoModal()"/>
          <DocumentModal v-if="documentModalOpen" :documentId="currentDocumentId" @closeModal="closeDocumentModal()"/>
          <BlackTransitionView v-if="currentPageType === 'blackTransitionView'" @transitionCompleted="progressFlow()"/>
          <StartView v-if="currentPageType === 'startView'"/>
          <InfoView v-if="currentPageType === 'infoView'" :data="currentPageData"/>
          <SituationView v-else-if="currentPageType === 'situationView'" :data="currentPageData"/>
          <EmailView v-else-if="currentPageType === 'emailView'" :data="currentPageData" @openDocument="openDocumentModal" @pageCompleted="setPageEmittedComplete"/>
          <TextInputView v-else-if="currentPageType === 'textInputView'" :data="currentPageData"/>
          <PhoneView v-else-if="currentPageType === 'phoneConversationView'" :data="currentPageData" @pageCompleted="setPageEmittedComplete"/>
        </div>
        <div class="button-wrapper d-flex">
          <div v-for="(button, i) in buttons" :key="i">
            <AppButton :color="'light_grey'" @click="buttonClick(button)" :disabled="continueButtonDisabled(button.type)"
                       v-if="isButtonHidden(button)" :key="pageEmittedComplete">
              {{ $t(button.text) }}
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import { mapState } from 'vuex';
import InformalPDFlow from '@/data/informalPDFlow.json';
import BlackTransitionView from '@/views/introViews/000-BlackTransitionView';
import StartView from '@/views/introViews/010-StartView';
import InfoView from '@/views/introViews/020-InfoView';
import SituationView from '@/views/introViews/030-SituationView';
import EmailView from '@/views/introViews/040-EmailView';
import TextInputView from '@/views/introViews/050-TextInputView';
import PhoneView from '@/views/introViews/060-PhoneView';
import AppButton from '@/components/common/AppButton';
import { VIEW_OVERVIEW } from '@/enum/Views.js';
import { PAGE_TYPE_EMAIL, PAGE_TYPE_PHONE } from '@/enum/PageTypes.js';
import {
  STATUS_AVAILABLE,
} from '@/enum/UnlockedStatus.js';
import {
  SET_CURRENT_INTRO_PAGE_INDEX,
  SET_BACKGROUND,
  SET_INFORMAL_PD_PAGE_INDEX, SET_DILEMMA_UNLOCKED_STATUS,
} from '@/store/mutation-types';
import { BUTTON_TYPE_DOCUMENT, BUTTON_TYPE_PROGRESS, BUTTON_TYPE_VIDEO } from '@/enum/ButtonType';
import VideoModal from '@/components/common/VideoModal';
import DocumentModal from '@/components/DocumentModal/DocumentModal';
import { ASSISTANT_TRIGGER_ON_PAGE_COMPLETE } from '@/enum/AssistantTriggers';
import {
  INFORMAL_PD_KEY_LARS,
  INFORMAL_PD_KEY_RURAL_DG,
  INFORMAL_PD_KEY_SECRETARY,
} from '@/enum/InformalPDKeys';

export default {
  name: 'InformalPD',
  components: {
    VideoModal,
    InfoView,
    SituationView,
    EmailView,
    PhoneView,
    TextInputView,
    StartView,
    AppButton,
    DocumentModal,
    BlackTransitionView,
  },
  data() {
    return {
      videoModalOpen: false,
      documentModalOpen: false,
      pageRequirementMet: true,
      pageEmittedComplete: false,
      currentVideoUrl: null,
      currentDocumentId: '',
      assistantMessageQueue: [],
      currentIPDFlowKey: '',
    };
  },
  watch: {
    background() {
      this.$store.commit(SET_BACKGROUND, this.background);
    },
  },
  computed: {
    ...mapState([
      'dilemmas',
      'dilemmaUnlockedStatus',
      'currentInformalPDPageIndex',
      'playerName',
      'playerSurname',
      'playerPosition',
      'playerLocation',
      'idpOpportunityAccepted',
    ]),
    currentPageType() {
      return this.currentIPDFlow?.[this.currentInformalPDPageIndex]?.type;
    },
    currentIPDFlow() {
      return InformalPDFlow[this.currentIPDFlowKey];
    },
    currentPageData() {
      return this.currentIPDFlow?.[this.currentInformalPDPageIndex]?.data;
    },
    buttons() {
      return this.currentIPDFlow?.[this.currentInformalPDPageIndex]?.bottomButtons ? this.currentIPDFlow[this.currentInformalPDPageIndex].bottomButtons : [];
    },
    assistantData() {
      return this.currentIPDFlow?.[this.currentInformalPDPageIndex]?.assistantData ? this.currentIPDFlow[this.currentInformalPDPageIndex].assistantData : [];
    },
    transitionName() {
      return this.currentIPDFlow?.[this.currentInformalPDPageIndex]?.transition ? this.currentIPDFlow[this.currentInformalPDPageIndex].transition : 'short';
    },
    background() {
      return this.currentIPDFlow?.[this.currentInformalPDPageIndex]?.background;
    },
    conditionalContinue() {
      return this.currentIPDFlow?.[this.currentInformalPDPageIndex]?.conditionalContinue ? this.currentIPDFlow[this.currentInformalPDPageIndex].conditionalContinue : false;
    },
    isModalOpen() {
      return this.videoModalOpen || this.documentModalOpen;
    },
    pageCompleted() {
      if (!this.conditionalContinue) {
        return true;
      }
      if (this.currentPageType === PAGE_TYPE_EMAIL || this.currentPageType === PAGE_TYPE_PHONE) {
        return this.pageEmittedComplete;
      }
      return false;
    },
    canGoBack() {
      return this.currentInformalPDPageIndex !== 0;
    },
  },
  mounted() {
    this.$store.commit(SET_BACKGROUND, this.background);
    this.assistantMessageData = require('@/data/assistant/assistantData.json');

    this.currentIPDFlowKey = INFORMAL_PD_KEY_LARS;
  },
  methods: {
    buttonClick(button) {
      const buttonType = button.type;
      const buttonData = button?.data;
      if (buttonType === BUTTON_TYPE_PROGRESS) {
        this.progressFlow();
      } else if (buttonType === BUTTON_TYPE_VIDEO) {
        this.videoModalOpen = true;
        if (buttonData) {
          this.currentVideoUrl = buttonData;
        }
      } else if (buttonType === BUTTON_TYPE_DOCUMENT) {
        this.documentModalOpen = true;
        if (buttonData) {
          this.currentDocumentId = buttonData;
        }
      }
    },
    continueButtonDisabled(buttonType) {
      if (buttonType === BUTTON_TYPE_PROGRESS) {
        if (!this.conditionalContinue) {
          return false;
        }
        if (!this.pageCompleted) {
          return true;
        }
      }
      return false;
    },
    isButtonHidden(button) {
      const isHidden = button?.isHidden ? button.isHidden : false;
      const type = button?.type ? button.type : '';

      const continueButtonDisabled = this.continueButtonDisabled(type);

      return !isHidden || !continueButtonDisabled;
    },
    progressFlow() {
      // see if we can progress
      const currentIPDFlowLength = this.currentIPDFlow.length;
      const newIpdPageIndex = this.currentInformalPDPageIndex + 1;

      // Is the flow ending
      if (currentIPDFlowLength <= newIpdPageIndex) {
        // should jump to subflow
        if (this.currentIPDFlowKey === INFORMAL_PD_KEY_LARS) {
          if (this.idpOpportunityAccepted) {
            this.currentIPDFlowKey = INFORMAL_PD_KEY_RURAL_DG;
          } else {
            this.currentIPDFlowKey = INFORMAL_PD_KEY_SECRETARY;
          }
          this.$store.commit(SET_INFORMAL_PD_PAGE_INDEX, 0);
          this.loadNewPage();
        } else {
          // if not jumping to subflow we should go to overview and unlock dilemma 3
          const dilemmaId = '3';
          const data = {
            dilemmaId,
            newStatus: STATUS_AVAILABLE,
          };
          this.$store.commit(SET_DILEMMA_UNLOCKED_STATUS, data);
          this.$router.push({ name: VIEW_OVERVIEW });
        }
      } else {
        // progress flow and set background
        this.$store.commit(SET_INFORMAL_PD_PAGE_INDEX, newIpdPageIndex);
        this.loadNewPage();
      }
    },
    onClickBackButton() {
      // progress flow and set background
      if (this.currentInformalPDPageIndex === 0) {
        return;
      }
      this.$store.commit(SET_CURRENT_INTRO_PAGE_INDEX, this.currentInformalPDPageIndex - 1);
      this.loadNewPage();
    },
    loadNewPage() {
      this.pageEmittedComplete = false;
      this.closeVideoModal();
      this.closeDocumentModal();
    },
    closeVideoModal() {
      this.videoModalOpen = false;
      if (this.assistantMessageQueue.length > 0) {
        for (const message of this.assistantMessageQueue) {
          this.$root.sendAssistantMessages(this.assistantMessageData[message]);
        }
      }
      this.assistantMessageQueue = [];
    },
    closeDocumentModal() {
      this.documentModalOpen = false;
      if (this.assistantMessageQueue.length > 0) {
        for (const message of this.assistantMessageQueue) {
          this.$root.sendAssistantMessages(this.assistantMessageData[message]);
        }
      }
      this.assistantMessageQueue = [];
    },
    setPageEmittedComplete() {
      this.pageEmittedComplete = true;

      if (this.assistantData.length > 0) {
        for (const assistantData of this.assistantData) {
          const trigger = assistantData?.trigger;
          const assistantDataId = assistantData?.assistantDataId;

          if (!trigger || !assistantDataId) {
            continue;
          }
          if (trigger === ASSISTANT_TRIGGER_ON_PAGE_COMPLETE) {
            if (!this.assistantMessageData.hasOwnProperty(assistantDataId)) {
              console.log('assistant data does not contain the assistant ID', assistantDataId);
              continue;
            }
            if (this.isModalOpen) {
              this.assistantMessageQueue.push(assistantDataId);
            } else {
              this.$root.sendAssistantMessages(this.assistantMessageData[assistantDataId]);
            }
          }
        }
      }
      // const instance = getCurrentInstance();
      // instance.proxy.forceUpdate();
    },
    openDocumentModal(attachmentID) {
      this.currentDocumentId = attachmentID;
      this.documentModalOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-view-wrapper {
  color: #ffffff;
  padding: 70px;
  height: 100%;
  width: 100%;

  .intro-content-style  {
    z-index: 1;
  }

  .back-button {
    position: absolute;
    left: 25px;
    top: 15px;
    z-index: 200;
  }

  .reset-button {
    position: absolute;
    right: 165px;
    top: 15px;
    z-index: 200;
  }

  .button-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    z-index: 200;
  }

}

</style>
